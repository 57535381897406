import { SearchInput } from '@patternfly/react-core'

type FilterUserProps = {
  filterUserValue: string
  setFilterUserValue: React.Dispatch<React.SetStateAction<string>>
  activeTabKey: number
  handleChangeFilterUsers: (filterValue: string) => void
}

const FilterUser = ({
  filterUserValue,
  setFilterUserValue,
  activeTabKey,
  handleChangeFilterUsers,
}: FilterUserProps) => {
  if (activeTabKey === 0) {
    return (
      <div className='pf-v5-u-p-sm'>
        <SearchInput
          placeholder='Filtrar usuário'
          value={filterUserValue}
          onChange={(_event, value) => handleChangeFilterUsers(value)}
          onClear={() => setFilterUserValue('')}
        />
      </div>
    )
  } else {
    return null
  }
}

export default FilterUser
