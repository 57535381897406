import { SearchInput } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { index } from 'd3-array'
import { useContext, useEffect, useMemo, useState } from 'react'
import { SettingsAPI, SettingsTurbineMap, Turbines } from '../../client'
import SitesContext from '../../contexts/SitesContext'
import { queryClient } from '../../services/api'
import ColorScale from '../../utils/colorScale'
import PageFrame from '../shared/Page'
import SelectSite from '../shared/selects/SelectSite'
import TableTurbs from './TableSettingsTurbs'

const queryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 60 * 24,
}

interface ITurbId {
  turb_id: number
}

const SettingsTurbsPage = () => {
  const [turbsMap, setSettingTurbsMap] = useState(new Map<number, Turbines>())
  const [settingTurb, setSettingTurb] = useState<SettingsTurbineMap>()
  const [inputValue, setInputValue] = useState('')
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)

  const { site } = useContext(SitesContext)

  const { isLoading: isLoadingSettings, data: settings } = useQuery(
    ['settingsTurbs', site.site_id],
    async () => {
      if (!site) return []

      const settings = await SettingsAPI.getSiteTurbines({ siteId: site.site_id })
      return settings.sort((a, b) => a.turb_id - b.turb_id)
    },
    queryOptions
  )

  const refetchSettingTurbs = async () => await queryClient.invalidateQueries(['settingsTurbs'])

  const { turbs } = useContext(SitesContext)

  useEffect(() => {
    const _siteTurbs = turbs?.sort((a, b) => a.turb_id - b.turb_id)
    if (_siteTurbs) setSettingTurbsMap(index(_siteTurbs, (t: ITurbId) => t.turb_id))
  }, [turbs])

  useEffect(() => {
    refetchSettingTurbs()
  }, [site])

  const updateTurb = async (data: SettingsTurbineMap) => {
    await SettingsAPI.updateTurbine({ siteId: site.site_id, requestBody: data })
    refetchSettingTurbs()
  }

  const filteredSettings = useMemo(() => {
    if (!inputValue) return settings
    return settings?.filter(e => {
      const turb = turbsMap.get(e.turb_id)
      if (!turb) return false

      return turb.name.toLowerCase().includes(inputValue.toLowerCase())
    })
  }, [settings, inputValue])

  const isLoading = isLoadingSettings && !!site

  return (
    <PageFrame
      pageName='Configurações Mapa'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      siteName={'site_name'}
      filters={[
        <SelectSite key='select_sites' enableUrlParams />,
        <SearchInput
          key='turb-search'
          className='pf-v5-u-m-sm'
          style={{ background: ColorScale.branco, maxWidth: '13rem' }}
          placeholder='Filtrar Aerogerador'
          value={inputValue}
          onChange={(_, value) => setInputValue(value)}
          onClear={() => setInputValue('')}
        />,
      ]}
    >
      <TableTurbs
        settingTurb={settingTurb}
        updateTurb={updateTurb}
        turbsMap={turbsMap}
        isLoading={isLoading}
        setSettingTurb={setSettingTurb}
        filteredSettings={filteredSettings}
        inputValue={inputValue}
        setInputValue={setInputValue}
        site={site}
      />
    </PageFrame>
  )
}

export default SettingsTurbsPage
