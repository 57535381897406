import {
  Bullseye,
  Button,
  Divider,
  EmptyState,
  EmptyStateBody,
  EmptyStateFooter,
  EmptyStateHeader,
  EmptyStateIcon,
  EmptyStateVariant,
  Flex,
  SearchInput,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
} from '@patternfly/react-core'
import { CogIcon, EditIcon, Remove2Icon, SearchIcon, TimesIcon } from '@patternfly/react-icons'
import {
  ExpandableRowContent,
  InnerScrollContainer,
  OuterScrollContainer,
  Table,
  TableText,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { useContext, useEffect, useMemo, useState } from 'react'
import {
  DowntimeAPI,
  DowntimeBop,
  DowntimeComponentes,
  DowntimeSetor,
  DowntimeTipos,
} from '../../client'
import { ActionState } from '../../components/actionState'
import ModalConfirmationWarning from '../../components/ModalConfirmationWarning'
import SitesContext from '../../contexts/SitesContext'
import useMobile from '../../hooks/useMobile'
import { queryClient } from '../../services/api'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import ColorScale from '../../utils/colorScale'
import { formatDateTimeBrl } from '../../utils/formatDate'
import { sortByKeyPosition } from '../../utils/sort'
import {
  BOTTOM_BUTTON_HEIGHT,
  BOTTOM_BUTTON_MARGIN,
  CACHE_KEY_BOP,
  GRID_SIZE,
  PAGE_GRID_SIZE,
  TABLE_HEADER_HEIGHT,
  TABLE_MIN_HEIGHT,
  TAB_HEADER_HEIGHT,
  TOOLBAR_HEIGHT,
} from './constants'
import ModalBOP from './ModalBOP'
import ResizeButtons from './ResizeButtons'
import { keyFromDate } from './useData'

const TABLE_HEIGHT_STYLE = {
  height: calcVerticalHeight({
    pgHasFilter: true,
    gridSize: PAGE_GRID_SIZE,
    elementSize: 1,
    customOffset:
      TAB_HEADER_HEIGHT + TABLE_HEADER_HEIGHT + BOTTOM_BUTTON_HEIGHT + BOTTOM_BUTTON_MARGIN + 5,
  }),
  minHeight: TABLE_MIN_HEIGHT,
}

const TableBop = ({
  selectedDate,
  downtimeBop,
  tipos,
  componentes,
  setores,
  resizeMode,
  setResizeMode,
}: {
  selectedDate: Date
  downtimeBop: DowntimeBop[]
  tipos: DowntimeTipos[]
  componentes: DowntimeComponentes[]
  setores: DowntimeSetor[]
  resizeMode: number
  setResizeMode: (m: number) => void
}) => {
  const { turbs, site } = useContext(SitesContext)

  const [searchInputValue, setSearchInputValue] = useState<string>('')
  const [isModalConfirmationWarningOpen, setModalConfirmationWarningOpen] = useState(false)
  const [
    isModalConfirmationWarningSelectedDatasOpen,
    setModalConfirmationWarningSelectedDatasOpen,
  ] = useState(false)
  const [dataToDelete, setDataToDelete] = useState<string[]>([])
  const [dataToUpdate, setDataToUpdate] = useState<DowntimeBop | null>(null)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [selectedData, setSelectedData] = useState<DowntimeBop[]>([])
  const [recentSelectedRowIndex, setRecentSelectedRowIndex] = useState<number | null>(null)
  const [shifting, setShifting] = useState(false)

  const [actionState, setActionState] = useState<ActionState>('idle')
  const [activeSortIndex, setActiveSortIndex] = useState(0)
  const [activeSortDirection, setActiveSortDirection] = useState<'asc' | 'desc'>('asc')
  const [expandedAero, setExpandedAero] = useState<any>([])

  type GetFind = number | null

  function getName(id: GetFind) {
    const found = turbs.find(a => a.turb_id === id)
    return found ? found.name : ''
  }

  const bopDowntimeData = useMemo(() => {
    if (!downtimeBop) return []
    setSelectedData([])

    function getSector(id: GetFind) {
      const found = setores.find(s => s.id === id)
      return found ? found.setor : ''
    }

    function getCategory(id: GetFind) {
      const found = tipos.find(t => t.id === id)
      return found ? `${found.categoria}` : ''
    }

    const _data = downtimeBop
      .map(el => ({
        ...el,
        id: el.id ?? '',
        aerosAfetados: el.turbs,
        setor: getSector(el.setor_id),
        categoria: getCategory(el.tipo_id),
        // TODO: Para verificar se realmente pode ser removido ou é necessário
        // A propriedade 'material_em_estoque' não existe no tipo 'DowntimeBop'
        // materialEmEstoque: el.material_em_estoque,
        descricao: el.descricao,
        usuario: el.username,
        inicio: formatDateTimeBrl(new Date(el.ts_in)),
        fim: formatDateTimeBrl(new Date(el.ts_fin)),
      }))
      .filter(el => el.setor.toLowerCase().includes(searchInputValue.toLowerCase()))

    return sortByKeyPosition(_data, activeSortIndex, activeSortDirection)
  }, [downtimeBop, componentes, tipos, searchInputValue, activeSortIndex, activeSortDirection])

  const deleteDowntimeBop = async (downtimeIds: string[]) => {
    setActionState('pending')
    try {
      await Promise.all(
        downtimeIds.map(async data => {
          await DowntimeAPI.deleteDowntimeBop({ siteId: site.site_id, downtimeId: data })
        })
      )
    } catch (error) {
      console.log(error)
      setActionState('failure')
      return
    }
    setActionState('success')
    setSelectedData([])
    onCloseModal()
    await queryClient.invalidateQueries([CACHE_KEY_BOP, site.site_id, keyFromDate(selectedDate, 1)])
  }

  const onCloseModal = () => {
    setDataToUpdate(null)
    setIsModalOpen(false)
  }

  const handleModalUpdateOpen = (item: DowntimeBop) => {
    if (!isModalOpen && !!downtimeBop) {
      const found = downtimeBop.find(el => el.id === item.id)
      if (found) setDataToUpdate(found)
    }

    setIsModalOpen(!isModalOpen)
  }

  const handleClickCreateModal = () => {
    setIsModalOpen(true)
    setDataToUpdate(null)
  }

  const handleModalConfirmationWarningToggle = () => {
    setActionState('idle')
    setModalConfirmationWarningOpen(!isModalConfirmationWarningOpen)
  }

  const handleDeleteClick = (item: string) => {
    setDataToDelete([item])
    handleModalConfirmationWarningToggle()
  }

  const handleDeleteManyClick = (item: string[]) => {
    setDataToDelete(item)
    handleModalConfirmationWarningSelectedDatasToggle()
  }

  const handleModalConfirmationWarningSelectedDatasToggle = () => {
    setActionState('idle')
    setModalConfirmationWarningSelectedDatasOpen(!isModalConfirmationWarningSelectedDatasOpen)
  }

  const setAeroExpanded = (aero: { id: string }, isExpanding = true) =>
    setExpandedAero((prevExpanded: any) => {
      const otherExpandedAeroNames = prevExpanded.filter((a: string) => a !== aero.id)
      return isExpanding ? [...otherExpandedAeroNames, aero.id] : otherExpandedAeroNames
    })

  const isAeroExpanded = (aero: { id: string }) => expandedAero.includes(aero.id)

  const getSortParams = (columnIndex: number) => ({
    sortBy: {
      index: activeSortIndex,
      direction: activeSortDirection,
    },
    onSort: (_event: any, index: number, direction: 'asc' | 'desc') => {
      setActiveSortIndex(index)
      setActiveSortDirection(direction)
    },
    columnIndex,
  })

  const selectableRepos = bopDowntimeData.filter(id => id)
  const setRepoSelected = (repo: DowntimeBop, isSelecting = true) =>
    setSelectedData(prevSelected => {
      const otherSelectedData = prevSelected.filter(r => r !== repo)
      return isSelecting ? [...otherSelectedData, repo] : otherSelectedData
    })
  const selectAllRepos = (isSelecting = true) =>
    setSelectedData(isSelecting ? selectableRepos.map(r => r) : [])
  const areAllReposSelected = selectedData.length === bopDowntimeData.length
  const isRepoSelected = (repo: DowntimeBop) => selectedData.includes(repo)

  const onSelectRepo = (repo: DowntimeBop, rowIndex: number, isSelecting: boolean) => {
    // If the user is shift + selecting the checkboxes, then all intermediate checkboxes should be selected
    if (shifting && recentSelectedRowIndex !== null) {
      const numberSelected = rowIndex - recentSelectedRowIndex
      const intermediateIndexes =
        numberSelected > 0
          ? Array.from(new Array(numberSelected + 1), (_x, i) => i + recentSelectedRowIndex)
          : Array.from(new Array(Math.abs(numberSelected) + 1), (_x, i) => i + rowIndex)
      intermediateIndexes.forEach(index => setRepoSelected(bopDowntimeData[index], isSelecting))
    } else {
      setRepoSelected(repo, isSelecting)
    }
    setRecentSelectedRowIndex(rowIndex)
  }

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setShifting(true)
      }
    }
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setShifting(false)
      }
    }

    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('keyup', onKeyUp)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [])

  const isMobile = useMobile()
  const [toggleFilters, setToggleFilters] = useState<boolean>(false)

  const searchInputItem = (
    <>
      <ToolbarItem
        style={{
          ...(isMobile ? { padding: '4px 0', paddingBottom: '6px' } : { padding: '4px 0.35rem' }),
          margin: 0,
        }}
      >
        <SearchInput
          placeholder='Filtrar por setor'
          value={searchInputValue}
          onChange={(_: any, value: string) => setSearchInputValue(value)}
          onClear={() => setSearchInputValue('')}
          style={{
            height: '36px',
            backgroundColor: 'white',
            width: isMobile ? '89vw' : '100%',
          }}
        />
      </ToolbarItem>
    </>
  )

  const buttonGroupItems = (
    <>
      <ToolbarItem
        style={{
          ...(isMobile ? { padding: '4px 0', paddingTop: '6px' } : { padding: '4px 0.35rem' }),
          margin: 0,
        }}
      >
        <Button
          style={{
            height: '36px',
            margin: 0,
            ...(isMobile && { width: '89vw' }),
          }}
          onClick={handleClickCreateModal}
        >
          Adicionar
        </Button>
      </ToolbarItem>
      <ToolbarItem style={{ padding: `4px ${isMobile ? 0 : '0.35rem'}`, margin: 0 }}>
        <Button
          variant='danger'
          onClick={() => handleDeleteManyClick(selectedData.map(i => i.id as string))}
          style={{
            height: '36px',
            margin: 0,
            ...(isMobile && { width: '89vw' }),
          }}
          isDisabled={!selectedData[0]}
        >
          Remover
        </Button>
      </ToolbarItem>
      <Divider
        orientation={{
          default: isMobile ? 'horizontal' : 'vertical',
        }}
        style={{
          ...(isMobile ? { width: '89vw' } : { height: '3vh', alignSelf: 'center' }),
          margin: 0,
        }}
      />
      <ResizeButtons setResizeMode={setResizeMode} resizeMode={resizeMode} />
    </>
  )

  const items = (
    <div
      style={{
        display: 'flex',
        ...(isMobile && { flexDirection: 'column' }),
        width: '100%',
      }}
    >
      <ToolbarGroup
        style={{
          margin: 0,
          ...(isMobile && { display: 'flex', flexDirection: 'column' }),
          width: 'fit-content',
        }}
      >
        {searchInputItem}
      </ToolbarGroup>

      <Divider
        orientation={{
          default: isMobile ? 'horizontal' : 'vertical',
        }}
        style={{
          ...(isMobile ? { width: '89vw' } : { height: '3vh', alignSelf: 'center' }),
          margin: 0,
        }}
      />
      <ToolbarGroup
        variant='button-group'
        style={{
          ...(isMobile && { display: 'flex', flexDirection: 'column' }),
          width: 'fit-content',
          margin: 0,
        }}
      >
        {buttonGroupItems}
      </ToolbarGroup>
    </div>
  )

  const tableAndContainerHeight = useMemo(() => {
    const h = calcVerticalHeight({
      elementSize: GRID_SIZE / 2 - resizeMode,
      gridSize: GRID_SIZE,
    })
    // Offset tem que ser fora, calcVerticalHeight distribui igualmente o offset entre os elementos
    const customOffset = TAB_HEADER_HEIGHT + TOOLBAR_HEIGHT
    const s = `calc(${h} - ${customOffset}rem)`

    return s
  }, [resizeMode])

  const heightBottom = useMemo(() => {
    const h = calcVerticalHeight({ elementSize: GRID_SIZE / 2 - resizeMode, gridSize: GRID_SIZE })
    // Offset tem que ser fora, calcVerticalHeight distribui igualmente o offset entre os elementos
    const customOffset = TABLE_HEADER_HEIGHT
    const s = `calc(${h} - ${customOffset}rem)`

    return s
  }, [resizeMode])

  return (
    <>
      <div style={{ height: heightBottom, backgroundColor: 'orange' }}>
        {isMobile && (
          <Flex
            style={{ justifyContent: 'flex-start', backgroundColor: 'white', position: 'relative' }}
          >
            <Button
              style={{
                backgroundColor: 'transparent',
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                justifyContent: 'center',
                height: '35px',
                color: 'black',
              }}
              onClick={() => setToggleFilters(!toggleFilters)}
            >
              {!toggleFilters ? (
                <>
                  Ações
                  <EmptyStateIcon icon={CogIcon} />
                </>
              ) : (
                <EmptyStateIcon icon={TimesIcon} />
              )}
            </Button>
          </Flex>
        )}

        {!isMobile || toggleFilters ? (
          <Flex
            style={{
              backgroundColor: 'white',
              position: 'relative',
            }}
          >
            <Toolbar
              id='toolbar-group-types'
              style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}
            >
              <ToolbarContent style={{ width: '100%' }}>{items}</ToolbarContent>
            </Toolbar>
          </Flex>
        ) : null}

        {/* OuterScrollContainer não deve ser incluído */}
        <InnerScrollContainer style={{ height: tableAndContainerHeight, backgroundColor: 'pink' }}>
          <Table
            aria-label='Table Paradas BOP'
            variant='compact'
            isStriped
            isExpandable
            isStickyHeader
            style={{ height: tableAndContainerHeight, backgroundColor: ColorScale.branco_clear }}
          >
            <Thead>
              <Tr style={{ height: `${TABLE_HEADER_HEIGHT}rem` }}>
                {bopDowntimeData.length > 0 && (
                  <Th
                    select={{
                      onSelect: (_event, isSelecting) => selectAllRepos(isSelecting),
                      isSelected: areAllReposSelected,
                    }}
                  />
                )}
                <Th />
                <Th sort={getSortParams(0)}>Id</Th>
                <Th style={{ minWidth: '100px' }}>WTG</Th>
                <Th sort={getSortParams(1)} style={{ minWidth: '100px' }}>
                  Setor
                </Th>
                <Th tooltip={null} className='pf-m-wrap'>
                  Categoria
                </Th>
                <Th tooltip={null} style={{ minWidth: '90px' }}>
                  Descrição
                </Th>
                <Th>Usuário</Th>
                <Th tooltip={null} sort={getSortParams(2)}>
                  Início
                </Th>
                <Th tooltip={null} sort={getSortParams(3)}>
                  Fim
                </Th>
                <Th tooltip={null} style={{ minWidth: '5rem' }}>
                  Responsabilidade
                </Th>
                <Th tooltip={null} style={{ minWidth: '3.6rem' }}>
                  Ações
                </Th>
              </Tr>
            </Thead>

            {bopDowntimeData.length === 0 && (
              <Tbody>
                <Tr>
                  <Td colSpan={12}>
                    <Bullseye>
                      <EmptyState variant={EmptyStateVariant.lg}>
                        <EmptyStateHeader
                          titleText='Nenhum registro encontrado.'
                          icon={<EmptyStateIcon icon={SearchIcon} />}
                          headingLevel='h3'
                        />
                        <EmptyStateFooter>
                          {searchInputValue && (
                            <>
                              <EmptyStateBody>Limpe o filtro e tente novamente.</EmptyStateBody>
                              <Button variant='link' onClick={() => setSearchInputValue('')}>
                                Limpar filtro
                              </Button>
                            </>
                          )}
                        </EmptyStateFooter>
                      </EmptyState>
                    </Bullseye>
                  </Td>
                </Tr>
              </Tbody>
            )}

            {bopDowntimeData.length > 0 &&
              bopDowntimeData.map((el, rowIndex) => {
                return (
                  <Tbody key={el.id} isExpanded={isAeroExpanded(el)}>
                    <Tr>
                      <Td
                        select={{
                          rowIndex,
                          onSelect: (_event, isSelecting) =>
                            onSelectRepo(el, rowIndex, isSelecting),
                          isSelected: isRepoSelected(el),
                        }}
                      />
                      <Td
                        expand={
                          el.aerosAfetados.length > 1
                            ? {
                                rowIndex,
                                isExpanded: isAeroExpanded(el),
                                onToggle: () => setAeroExpanded(el, !isAeroExpanded(el)),
                              }
                            : undefined
                        }
                        style={{ paddingLeft: el.aerosAfetados.length > 1 ? '0px' : '10px' }}
                      />
                      <Td dataLabel='Id'>{el.id.substring(0, 8)}</Td>
                      <Td dataLabel='Aero Afetado'>
                        {el.aerosAfetados.length > 1 && (
                          <span onClick={() => setAeroExpanded(el, !isAeroExpanded(el))}>
                            {el.aerosAfetados.length} Aeros
                          </span>
                        )}
                        {el.aerosAfetados.length === 1 && (
                          <span>{getName(el.aerosAfetados[0].turb_id)}</span>
                        )}
                      </Td>
                      <Td dataLabel='Setor'>{el.setor}</Td>
                      <Td dataLabel='Categoria'>{el.categoria}</Td>
                      <Td tooltip={null} dataLabel='Descrição'>
                        <TableText
                          tooltipProps={{ className: 'pf-v5-u-display-none' }}
                          wrapModifier='truncate'
                        >
                          {el.descricao}
                        </TableText>
                      </Td>
                      <Td dataLabel='Usuário'>{el.usuario}</Td>
                      <Td dataLabel='Início'>{el.inicio}</Td>
                      <Td dataLabel='Fim'>{el.fim}</Td>
                      <Td dataLabel='Responsabilidade'>
                        {el.responsabilidade_re ? 'RioEnergy' : 'Fabricante'}
                      </Td>
                      <Td dataLabel='Ações'>
                        <div>
                          <EditIcon
                            onClick={() => handleModalUpdateOpen(el)}
                            style={{ cursor: 'pointer' }}
                          />
                          <Remove2Icon
                            onClick={() => handleDeleteClick(el.id)}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </Td>
                    </Tr>

                    {el.aerosAfetados ? (
                      <Tr isExpanded={isAeroExpanded(el)}>
                        <Td>{null}</Td>
                        <Td>{null}</Td>
                        <Td>{null}</Td>
                        <Td
                          dataLabel={`Id ${el.id} - Aeros Afetados`}
                          noPadding
                          className='pf-v5-u-m-sm'
                          colSpan={2}
                        >
                          {el.aerosAfetados.map((t, i) => {
                            return (
                              <ExpandableRowContent key={i}>
                                {getName(t.turb_id)}
                              </ExpandableRowContent>
                            )
                          })}
                        </Td>
                        <Td>{null}</Td>
                        <Td>{null}</Td>
                        <Td>{null}</Td>
                        <Td>{null}</Td>
                        <Td>{null}</Td>
                        <Td>{null}</Td>
                        <Td>{null}</Td>
                      </Tr>
                    ) : null}
                  </Tbody>
                )
              })}
          </Table>
        </InnerScrollContainer>
      </div>

      <ModalBOP
        isOpen={isModalOpen}
        onClose={onCloseModal}
        dataToUpdate={dataToUpdate}
        selectedDate={selectedDate}
        handleDeleteClick={handleDeleteClick}
        modalConfirmation={
          <ModalConfirmationWarning<string>
            isOpen={isModalConfirmationWarningOpen}
            handleModalToggle={handleModalConfirmationWarningToggle}
            handleSubmit={id => deleteDowntimeBop([id])}
            title='Remover parada do Aerogerador'
            element={dataToDelete[0]}
            actionState={actionState}
          />
        }
      />
      <ModalConfirmationWarning<string>
        isOpen={isModalConfirmationWarningOpen}
        handleModalToggle={handleModalConfirmationWarningToggle}
        handleSubmit={id => deleteDowntimeBop([id])}
        title='Remover parada do Aerogerador'
        element={dataToDelete[0]}
        actionState={actionState}
      />

      <ModalConfirmationWarning<DowntimeBop[]>
        isOpen={isModalConfirmationWarningSelectedDatasOpen}
        handleModalToggle={handleModalConfirmationWarningSelectedDatasToggle}
        handleSubmit={data => deleteDowntimeBop(data.map(i => i.id as string))}
        title='Remover paradas dos Aerogeradores'
        element={selectedData}
        actionState={actionState}
        alertMessage='Tem certeza que deseja remover estes itens? Essa ação não poderá ser desfeita.'
        additionalInformation={
          selectedData.length > 0 && (
            <div>
              <OuterScrollContainer
                style={{ ...TABLE_HEIGHT_STYLE, borderBottom: '1px solid #d2d2d2' }}
              >
                <InnerScrollContainer>
                  <Table
                    aria-label='Table Paradas BOP Delete'
                    variant='compact'
                    isStriped
                    isExpandable
                    isStickyHeader
                  >
                    <Thead>
                      <Tr style={{ height: `${TABLE_HEADER_HEIGHT}rem` }}>
                        <Th />
                        <Th>Id</Th>
                        <Th style={{ minWidth: '100px' }}>WTG</Th>
                        <Th>Usuário</Th>
                        <Th tooltip={null}>Início</Th>
                        <Th tooltip={null}>Fim</Th>
                      </Tr>
                    </Thead>
                    {selectedData.map((el, rowIndex) => {
                      function getName(id?: number) {
                        const found = turbs.find(a => a.turb_id === id)
                        return found ? found.name : ''
                      }
                      return (
                        <Tbody key={el.id} isExpanded={isAeroExpanded({ id: el.id as string })}>
                          <Tr>
                            <Td
                              expand={
                                el.turbs.length > 1
                                  ? {
                                      rowIndex,
                                      isExpanded: isAeroExpanded({ id: el.id as string }),
                                      onToggle: () =>
                                        setAeroExpanded(
                                          { id: el.id as string },
                                          !isAeroExpanded({ id: el.id as string })
                                        ),
                                    }
                                  : undefined
                              }
                              style={{ paddingLeft: el.turbs.length > 1 ? '0px' : '10px' }}
                            />
                            <Td dataLabel='Id'>{el.id?.substring(0, 8)}</Td>
                            <Td dataLabel='Aero Afetado'>
                              {el.turbs.length > 1 && (
                                <span
                                  onClick={() =>
                                    setAeroExpanded(
                                      { id: el.id as string },
                                      !isAeroExpanded({ id: el.id as string })
                                    )
                                  }
                                >
                                  {el.turbs.length} Aeros
                                </span>
                              )}
                              {el.turbs.length === 1 && <span>{getName(el.turbs[0].turb_id)}</span>}
                            </Td>
                            <Td dataLabel='Usuário'>{el.username}</Td>
                            <Td dataLabel='Início'>{formatDateTimeBrl(new Date(el.ts_in))}</Td>
                            <Td dataLabel='Fim'>{formatDateTimeBrl(new Date(el.ts_fin))}</Td>
                          </Tr>

                          {el.turbs ? (
                            <Tr isExpanded={isAeroExpanded({ id: el.id as string })}>
                              <Td>{null}</Td>
                              <Td>{null}</Td>
                              <Td
                                dataLabel={`Id ${el.id} - Aeros Afetados`}
                                noPadding
                                className='pf-v5-u-m-sm'
                                colSpan={2}
                              >
                                {el.turbs.map((t, i) => {
                                  return (
                                    <ExpandableRowContent key={i}>
                                      {getName(t.turb_id)}
                                    </ExpandableRowContent>
                                  )
                                })}
                              </Td>
                              <Td>{null}</Td>
                              <Td>{null}</Td>
                              <Td>{null}</Td>
                              <Td>{null}</Td>
                            </Tr>
                          ) : null}
                        </Tbody>
                      )
                    })}
                  </Table>
                </InnerScrollContainer>
              </OuterScrollContainer>
            </div>
          )
        }
      />
    </>
  )
}

export default TableBop
