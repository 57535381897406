import { Button } from '@patternfly/react-core'
import { UseMutateFunction } from '@tanstack/react-query/build/lib/types'
import { AxiosResponse } from 'axios'
import { useContext, useState } from 'react'
import { GroupIn, GroupOut, UserIn } from '../../client'
import PermissionContext from '../../contexts/PermissionContext'
import SitesContext from '../../contexts/SitesContext'
import ModalCreateGroup from './ModalCreateGroup'
import ModalCreateUser from './ModalCreateUser'

type DefaultOptions = {
  availableOptions: string[]
  chosenOptions: string[]
}

type BarUsersGroupsProps = {
  setPermissionTypeSelected: React.Dispatch<React.SetStateAction<string | undefined>>
  permissionTypeSelected: string | undefined
  createUser: UseMutateFunction<AxiosResponse<any, any>, unknown, UserIn, unknown>
  createGroup: UseMutateFunction<AxiosResponse<any, any>, unknown, GroupIn, unknown>
}

const BarUsersGroups = ({
  setPermissionTypeSelected,
  permissionTypeSelected,
  createUser,
  createGroup,
}: BarUsersGroupsProps) => {
  const { users, groups, pages, permissionTypes, isLoading } = useContext(PermissionContext)
  const { sites } = useContext(SitesContext)

  const [isUserModalOpen, setUserModalOpen] = useState(false)
  const [isGroupModalOpen, setGroupModalOpen] = useState(false)

  const [nameValue, setNameValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [passwordValue, setPasswordValue] = useState('')

  const [groupNameValue, setGroupNameValue] = useState('')
  const [groupDescriptionValue, setGroupDescriptionValue] = useState('')

  const [groupsOptions, setGroupsOptions] = useState<DefaultOptions>({
    availableOptions: [],
    chosenOptions: [],
  })
  const [sitesOptions, setSitesOptions] = useState<DefaultOptions>({
    availableOptions: [],
    chosenOptions: [],
  })

  const [membersOptions, setMembersOptions] = useState<DefaultOptions>({
    availableOptions: [],
    chosenOptions: [],
  })

  const [pagesOptions, setPagesOptions] = useState<DefaultOptions>({
    availableOptions: [],
    chosenOptions: [],
  })

  const handleCreateUserModalToggle = () => {
    setGroupsListOptions()
    setSitesListOptions()
    setUserModalOpen(!isUserModalOpen)
  }

  const handleCreateGroupModalToggle = () => {
    setMembersListOptions()
    setPagesListOptions()
    setGroupModalOpen(!isGroupModalOpen)
  }

  const handleNameInputChange = (value: string) => {
    setNameValue(value)
  }

  const handleEmailInputChange = (value: string) => {
    setEmailValue(value)
  }

  const handlePasswordInputChange = (value: string) => {
    setPasswordValue(value)
  }

  const handleGroupNameInputChange = (value: string) => {
    setGroupNameValue(value)
  }

  const handleGroupDescriptionInputChange = (value: string) => {
    setGroupDescriptionValue(value)
  }

  const onGroupsListChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) => {
    setGroupsOptions({
      availableOptions: newAvailableOptions.sort(),
      chosenOptions: newChosenOptions.sort(),
    })
  }
  const onSitesListChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) => {
    setSitesOptions({
      availableOptions: newAvailableOptions.sort(),
      chosenOptions: newChosenOptions.sort(),
    })
  }

  const onMembersListChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) => {
    setMembersOptions({
      availableOptions: newAvailableOptions.sort(),
      chosenOptions: newChosenOptions.sort(),
    })
  }

  const onPagesListChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) => {
    setPagesOptions({
      availableOptions: newAvailableOptions.sort(),
      chosenOptions: newChosenOptions.sort(),
    })
  }

  const setMembersListOptions = () => {
    const list = users.map(u => u.username)
    setMembersOptions({
      availableOptions: list,
      chosenOptions: [],
    })
  }

  const setGroupsListOptions = () => {
    const list = groups.map(g => g.name)
    setGroupsOptions({
      availableOptions: list,
      chosenOptions: [],
    })
  }

  const setSitesListOptions = () => {
    const list = sites.map(g => g.site_name)
    setSitesOptions({
      availableOptions: list,
      chosenOptions: [],
    })
  }

  const setPagesListOptions = () => {
    const list = pages.map(p => p.name)
    setPagesOptions({
      availableOptions: list,
      chosenOptions: [],
    })
  }

  const setMemberIdList = (chosenOptions: string[]) => {
    const membersIdList: string[] = []

    users.forEach(u => {
      chosenOptions.forEach(el => {
        if (el === u.username) membersIdList.push(u.id)
      })
    })

    return membersIdList
  }

  const setPageIdList = (chosenOptions: string[]) => {
    const pagesIdList: string[] = []

    pages.forEach(p => {
      chosenOptions.forEach(el => {
        if (el === p.name && p.page_id) pagesIdList.push(p.page_id)
      })
    })

    return pagesIdList
  }

  const setGroupIdList = (chosenOptions: string[]) => {
    const groupsIdList: string[] = []

    groups.forEach(g => {
      chosenOptions.forEach(el => {
        if (el === g.name) groupsIdList.push(g.id)
      })
    })

    return groupsIdList
  }
  const setSiteIdsList = (chosenOptions: string[]) => {
    const sitesIdList: number[] = []

    sites.forEach(g => {
      chosenOptions.forEach(el => {
        if (el === g.site_name && g.site_id) sitesIdList.push(g.site_id)
      })
    })

    return sitesIdList
  }

  const dataValidator = (data: any) => {
    if (typeof data === 'object') {
      for (const value of Object.entries(data)) {
        if ((value[1] as any[]).length === 0) {
          return false
        }
      }
      return true
    }
  }

  async function handleGroupSubmit() {
    const body: GroupIn = {
      name: groupNameValue,
      description: groupDescriptionValue,
      user_ids: setMemberIdList(membersOptions.chosenOptions),
      page_ids: setPageIdList(pagesOptions.chosenOptions),
      perm_type_id: permissionTypeSelected as string,
    }

    if (
      dataValidator({
        name: groupNameValue,
        perm_type_id: permissionTypeSelected,
      })
    ) {
      createGroup(body)
    } else {
      console.log(`[handleGroupSubmit] Dados inválidos ${JSON.stringify(body)}`)
    }

    setGroupModalOpen(!isGroupModalOpen)
    setGroupNameValue('')
    setPermissionTypeSelected(isLoading ? '' : permissionTypes[0].perm_type_id)
    setGroupDescriptionValue('')
    setMembersOptions({
      availableOptions: users.map(item => item.username),
      chosenOptions: [],
    })
    setPagesOptions({
      availableOptions: pages.map(item => item.name),
      chosenOptions: [],
    })
  }

  async function handleUserSubmit() {
    console.log(groups)
    const body: UserIn = {
      username: nameValue,
      email: emailValue,
      password: passwordValue,
      group_ids: setGroupIdList(groupsOptions.chosenOptions),
      site_ids: setSiteIdsList(sitesOptions.chosenOptions),
    }

    if (dataValidator(body)) {
      createUser(body)
    } else {
      console.log(`[handleUserSubmit] Dados inválidos ${JSON.stringify(body)}`)
    }

    handleCreateUserModalToggle()

    setNameValue('')
    setPasswordValue('')
    setEmailValue('')
    setGroupsOptions({
      availableOptions: groups.map(item => item.name),
      chosenOptions: [],
    })
  }

  return (
    <>
      <Button
        key='create-user'
        variant='control'
        className='pf-v5-u-m-sm'
        onClick={handleCreateUserModalToggle}
      >
        Criar Usuário
      </Button>
      <Button
        key='create-group'
        variant='control'
        className='pf-v5-u-m-sm'
        onClick={handleCreateGroupModalToggle}
      >
        Criar Grupo
      </Button>
      <ModalCreateGroup
        modalTitle='Criar grupo'
        key='modal-create-group'
        isModalOpen={isGroupModalOpen}
        handleModalToggle={handleCreateGroupModalToggle}
        handleSubmit={handleGroupSubmit}
        groupNameValue={groupNameValue}
        handleGroupDescriptionInputChange={handleGroupDescriptionInputChange}
        handleGroupNameInputChange={handleGroupNameInputChange}
        groupDescriptionValue={groupDescriptionValue}
        availableMembersOptions={membersOptions.availableOptions}
        chosenMembersOptions={membersOptions.chosenOptions}
        onMembersListChange={onMembersListChange}
        availablePagesOptions={pagesOptions.availableOptions}
        chosenPagesOptions={pagesOptions.chosenOptions}
        onPagesListChange={onPagesListChange}
        setPermissionTypeSelected={setPermissionTypeSelected}
        permissionTypeSelected={permissionTypeSelected}
      />
      <ModalCreateUser
        key='modal-create-user'
        isModalOpen={isUserModalOpen}
        handleModalToggle={handleCreateUserModalToggle}
        handleSubmit={handleUserSubmit}
        nameValue={nameValue}
        handleNameInputChange={handleNameInputChange}
        passwordValue={passwordValue}
        handlePasswordInputChange={handlePasswordInputChange}
        emailValue={emailValue}
        handleEmailInputChange={handleEmailInputChange}
        availableGroupsOptions={groupsOptions.availableOptions}
        chosenGroupsOptions={groupsOptions.chosenOptions}
        availableSitesOptions={sitesOptions.availableOptions}
        chosenSitesOptions={sitesOptions.chosenOptions}
        onGroupsListChange={onGroupsListChange}
        onSitesListChange={onSitesListChange}
      />
    </>
  )
}

export default BarUsersGroups
