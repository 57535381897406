import {
  Bullseye,
  Button,
  EmptyState,
  EmptyStateBody,
  EmptyStateFooter,
  EmptyStateHeader,
  EmptyStateIcon,
  EmptyStateVariant,
  SearchInput,
} from '@patternfly/react-core'
import { EditIcon, Remove2Icon, SearchIcon } from '@patternfly/react-icons'
import {
  InnerScrollContainer,
  SortByDirection,
  Tbody,
  Td,
  Th,
  Thead,
  ThProps,
  Tr,
} from '@patternfly/react-table'
import { Table } from '@patternfly/react-table/deprecated'
import { useMemo, useRef, useState } from 'react'
import { CivilOcorrencia, Site } from '../../client'
import api, { queryClient } from '../../services/api'
import ModalConfirmationWarning from '../shared/DeprecatedModalConfirmationWarning'

interface ITableListOcorrenciasProps {
  styled: React.CSSProperties
  ocorrencias: CivilOcorrencia[] | undefined
  setOcorrencia: React.Dispatch<React.SetStateAction<CivilOcorrencia | null>>
  setIsOpenModalEditarOcorrencia: React.Dispatch<React.SetStateAction<boolean>>
  canEdit: boolean
  site: Site
}

export const TableListOcorrencias = (props: ITableListOcorrenciasProps) => {
  const { styled, ocorrencias, setIsOpenModalEditarOcorrencia, setOcorrencia, canEdit, site } =
    props

  const [activeSortIndex, setActiveSortIndex] = useState<number | null>(null)
  const [activeSortDirection, setActiveSortDirection] = useState<SortByDirection | null>(null)
  const [inputValue, setInputValue] = useState('')

  const [isModalConfirmationWarningOpen, setModalConfirmationWarningOpen] = useState(false)
  const [dataToDelete, setDataToDelete] = useState({})
  const [deleteSuccessOcorrencia, setDeleteSuccessOcorrencia] = useState(false)

  const sortedOcorrenciasDataRef = useRef<CivilOcorrencia[]>([])

  const getSortParams = (columnIndex: number): ThProps['sort'] => ({
    sortBy: {
      index: activeSortIndex || undefined,
      direction: activeSortDirection || undefined,
    },
    onSort: (event: React.MouseEvent, index: number, direction: SortByDirection) => {
      setActiveSortIndex(index)
      setActiveSortDirection(direction)
    },
    columnIndex,
  })

  const ocorrenciaData = useMemo(() => {
    const data: CivilOcorrencia[] = []

    ocorrencias?.forEach(ocorrencia => {
      if (inputValue.length > 0) {
        const descOcorrencia = ocorrencia.descricao_ocorrencia || ''
        if (descOcorrencia.toLowerCase().includes(inputValue.toLowerCase())) {
          data.push(ocorrencia)
        }
      } else {
        data.push(ocorrencia)
      }
    })

    return data
  }, [ocorrencias, inputValue])

  const getSortableRowValues = (ocorrenciaData: CivilOcorrencia) => {
    const { item_id, descricao_ocorrencia, natureza_origem, data_origem, situacao } = ocorrenciaData
    return [item_id, descricao_ocorrencia, natureza_origem, data_origem, situacao]
  }

  if (activeSortIndex !== null) {
    sortedOcorrenciasDataRef.current = ocorrenciaData.sort((a, b) => {
      const aValue = getSortableRowValues(a)[activeSortIndex]
      const bValue = getSortableRowValues(b)[activeSortIndex]
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        if (activeSortDirection === 'asc') {
          return aValue - bValue
        }
        return bValue - aValue
      } else {
        if (activeSortDirection === 'asc') {
          return String(aValue).localeCompare(String(bValue))
        }

        return String(bValue).localeCompare(String(aValue))
      }
    })
  } else {
    sortedOcorrenciasDataRef.current = ocorrenciaData.sort((a, b) => a.item_id - b.item_id)
  }

  const handleSelectEditOcorrencia = (ocorrencia: CivilOcorrencia) => {
    setOcorrencia(ocorrencia)
    setIsOpenModalEditarOcorrencia(true)
  }

  const handleDeleteSubmit = async (ocorrencia: CivilOcorrencia) => {
    try {
      await api.delete(`/sites/occurrences/${ocorrencia.id}`)
      setDeleteSuccessOcorrencia(true)
      await queryClient.invalidateQueries(['occurrences', site.site_id])
    } catch (err) {
      console.error(err)
    }
  }

  const handleModalConfirmationWarningToggle = () => {
    setDeleteSuccessOcorrencia(false)
    setModalConfirmationWarningOpen(!isModalConfirmationWarningOpen)
  }

  function handleDeleteClick(item: CivilOcorrencia) {
    setDataToDelete(item)
    handleModalConfirmationWarningToggle()
  }

  return (
    <div className='container-aero-tab pf-v5-u-box-shadow-sm'>
      <SearchInput
        className='search-input-table-aero'
        placeholder='Filtrar por descrição'
        value={inputValue}
        onChange={(_, value) => setInputValue(value)}
        onClear={() => setInputValue('')}
      />
      <div className='container-table'>
        <InnerScrollContainer style={styled}>
          <Table
            aria-label='Expandable table'
            variant='compact'
            isStriped
            isExpandable
            isStickyHeader
          >
            <Thead className='table-head-aero'>
              <Tr>
                <Th modifier='wrap' sort={getSortParams(0)}>
                  Item
                </Th>
                <Th modifier='wrap' sort={getSortParams(2)}>
                  Natureza
                </Th>
                <Th modifier='wrap' sort={getSortParams(3)}>
                  Criticidade
                </Th>
                <Th>Descrição</Th>
                <Th>Data</Th>
                <Th sort={getSortParams(4)}>Status</Th>
                {canEdit && <Th modifier='wrap'>Ações</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {ocorrencias?.length === 0 && (
                <Tr>
                  <Td colSpan={12}>
                    <Bullseye>
                      <EmptyState variant={EmptyStateVariant.sm}>
                        <EmptyStateHeader
                          titleText='Nenhuma ocorrência encontrada.'
                          icon={<EmptyStateIcon icon={SearchIcon} />}
                          headingLevel='h3'
                        />
                        <EmptyStateFooter>
                          {inputValue && (
                            <>
                              <EmptyStateBody>Limpe o filtro e tente novamente.</EmptyStateBody>
                              <Button variant='link' onClick={() => setInputValue('')}>
                                Limpar filtro
                              </Button>
                            </>
                          )}
                        </EmptyStateFooter>
                      </EmptyState>
                    </Bullseye>
                  </Td>
                </Tr>
              )}
              {Number(ocorrencias?.length) > 0 &&
                sortedOcorrenciasDataRef.current.map(ocorrencia => (
                  <Tr key={`${ocorrencia.id}_${ocorrencia.item_id}`}>
                    <Td dataLabel='Item'>{ocorrencia?.item_id}</Td>
                    <Td dataLabel='Natureza'>{ocorrencia?.natureza_origem}</Td>
                    <Td dataLabel='Criticidade'>{ocorrencia?.criticidade}</Td>
                    <Td dataLabel='Descrição'>{ocorrencia?.descricao_ocorrencia}</Td>
                    <Td dataLabel='Data'>{ocorrencia?.data_origem}</Td>
                    <Td dataLabel='Status'>{ocorrencia?.situacao}</Td>
                    {canEdit && (
                      <Td dataLabel='Ações'>
                        <div>
                          <EditIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSelectEditOcorrencia(ocorrencia)}
                          />
                          <Remove2Icon
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDeleteClick(ocorrencia)}
                          />
                        </div>
                      </Td>
                    )}
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </InnerScrollContainer>
      </div>
      <ModalConfirmationWarning
        isModalOpen={isModalConfirmationWarningOpen}
        handleModalToggle={handleModalConfirmationWarningToggle}
        handleSubmit={handleDeleteSubmit}
        title='Remover ocorrência'
        element={dataToDelete}
        deleteSuccess={deleteSuccessOcorrencia}
      />
    </div>
  )
}
