import {
  Bullseye,
  Button,
  Divider,
  EmptyState,
  EmptyStateBody,
  EmptyStateFooter,
  EmptyStateHeader,
  EmptyStateIcon,
  EmptyStateVariant,
  Flex,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
  Tooltip,
} from '@patternfly/react-core'
import {
  CogIcon,
  EditIcon,
  FilterIcon,
  Remove2Icon,
  SearchIcon,
  TimesIcon,
} from '@patternfly/react-icons'
import {
  InnerScrollContainer,
  OuterScrollContainer,
  Table,
  TableText,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { useQuery } from '@tanstack/react-query'
import { Eraser } from 'lucide-react'
import { useContext, useEffect, useMemo, useState } from 'react'
import {
  Alarms,
  AlarmsAPI,
  AlarmView,
  DowntimeAero,
  DowntimeAPI,
  DowntimeComponentes,
  DowntimeTipos,
  Turbines,
} from '../../client'
import { ActionState } from '../../components/actionState'
import ModalConfirmationWarning from '../../components/ModalConfirmationWarning'
import Select from '../../components/selects/Select'
import SitesContext from '../../contexts/SitesContext'
import useMobile from '../../hooks/useMobile'
import { queryClient } from '../../services/api'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import ColorScale from '../../utils/colorScale'
import { formatDateTimeBrl, formatDateWithoutTime } from '../../utils/formatDate'
import { sortByKeyPosition } from '../../utils/sort'
import {
  BOTTOM_BUTTON_HEIGHT,
  BOTTOM_BUTTON_MARGIN,
  CACHE_KEY_ALARMS,
  CACHE_KEY_WTG,
  DEFAULT_DATA,
  GRID_SIZE,
  PAGE_GRID_SIZE,
  QUERY_OPTIONS,
  Responsabilidade,
  TABLE_HEADER_HEIGHT,
  TABLE_MIN_HEIGHT,
  TAB_HEADER_HEIGHT,
  TOOLBAR_HEIGHT,
} from './constants'
import ModalAero from './ModalAero'
import ModalConsolidar from './ModalConsolidar'
import ResizeButtons from './ResizeButtons'
import { keyFromDate } from './useData'

export interface EventAlarm {
  alarm: Alarms | null
  event: DowntimeAero | null
}

export type DowntimeUser = {
  id: string
  username: string
}

export interface IEvent extends DowntimeAero {
  aero: string
  categoria: string
  usuario: string
  inicio: string
  fim: string
  componente: string
}

const TABLE_HEIGHT_STYLE = {
  height: calcVerticalHeight({
    pgHasFilter: true,
    gridSize: PAGE_GRID_SIZE,
    elementSize: 1,
    customOffset:
      TAB_HEADER_HEIGHT + TABLE_HEADER_HEIGHT + BOTTOM_BUTTON_HEIGHT + BOTTOM_BUTTON_MARGIN + 5,
  }),
  minHeight: TABLE_MIN_HEIGHT,
}

const DEFAULT_SEARCH_INPUT: Turbines = {
  circuit: '',
  commissioning_date: '',
  hh: 0,
  name: 'WTG',
  rated_pwr: 0,
  rotor_d: 0,
  site_id: 0,
  trafo: '',
  turb_class: '',
  turb_id: 0,
  turb_model: '',
  utmzone: '',
  wf_id: 0,
  x: 0,
  y: 0,
  y_pos: 0,
  cov_model: null,
  gbx_model: null,
  gen_model: null,
  lat: null,
  lon: null,
  pop: null,
  wind_sector_management: null,
}
const DEFAULT_SEARCH_CATEGORY: DowntimeTipos = {
  id: 0,
  categoria: 'Categoria',
  tipo: 'Categoria',
  description: null,
}
const DEFAULT_SEARCH_COMPONENT: DowntimeComponentes = {
  id: 0,
  component: 'Componente',
}
const DEFAULT_SEARCH_RESP: Responsabilidade = { id: false, nome: 'Responsabilidade' }
const DEFAULT_SEARCH_USER: DowntimeUser = { id: '', username: 'Usuário' }

export const formatAlarmDate = (date: string) => {
  const _dt = new Date(date)
  _dt.setMilliseconds(0)
  return formatDateTimeBrl(_dt)
}

const TableAero = ({
  selectedDate,
  downtimeAero,
  componentes,
  tipos,
  resizeMode,
  setResizeMode,
}: {
  selectedDate: Date
  downtimeAero: DowntimeAero[]
  componentes: DowntimeComponentes[]
  tipos: DowntimeTipos[]
  resizeMode: number
  setResizeMode: (m: number) => void
}) => {
  const { turbs, site } = useContext(SitesContext)

  const [searchInputAero, setSearchInputAero] = useState<Turbines>(DEFAULT_SEARCH_INPUT)
  const [searchInputCategory, setSearchInputCategory] =
    useState<DowntimeTipos>(DEFAULT_SEARCH_CATEGORY)
  const [searchInputComponent, setSearchInputComponent] =
    useState<DowntimeComponentes>(DEFAULT_SEARCH_COMPONENT)
  const [searchInputResponsibility, setSearchInputResponsibility] =
    useState<Responsabilidade>(DEFAULT_SEARCH_RESP)
  const [searchInputUser, setSearchInputUser] = useState<DowntimeUser>(DEFAULT_SEARCH_USER)
  const [isModalWarningOpen, setModalWarningOpen] = useState(false)
  const [isModalConfirmSelectedDatasOpen, setModalConfirmSelectedDatasOpen] = useState(false)
  const [isModalConsolidarOpen, setModalConsolidarOpen] = useState(false)
  const [dataToDelete, setDataToDelete] = useState<string[]>([])

  const [selectedData, setSelectedData] = useState<DowntimeAero[]>([])
  const [recentSelectedRowIndex, setRecentSelectedRowIndex] = useState<number | null>(null)
  const [shifting, setShifting] = useState(false)

  const [actionState, setActionState] = useState<ActionState>('idle')
  const [activeFilter, setActiveFilter] = useState(true)
  const [activeSortIndex, setActiveSortIndex] = useState(0)
  const [activeSortDirection, setActiveSortDirection] = useState<'asc' | 'desc'>('asc')

  const [isModalOpen, setModalOpen] = useState(false)
  const [eventSelected, setEventSelected] = useState<IEvent>()
  const [turbAlarmesSelected, setTurbAlarmesSelected] = useState<Alarms[]>()

  const handleOpenModalEnergyExpected = (event: IEvent, turbAlarmes: Alarms[]) => {
    setEventSelected(event)
    setTurbAlarmesSelected(turbAlarmes)
    setModalOpen(true)
  }

  const tsIn = useMemo(
    () => formatDateWithoutTime(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)),
    [selectedDate]
  )
  const tsFin = useMemo(
    () =>
      formatDateWithoutTime(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0)),
    [selectedDate]
  )

  const { isLoading: alarmsIsLoading, data: alarmView } = useQuery<AlarmView>(
    [CACHE_KEY_ALARMS, site?.site_id, tsIn, tsFin, 0],
    async () =>
      AlarmsAPI.getAll({
        siteId: site.site_id,
        tsIn,
        tsFin,
        turbId: 0,
        limit: 5000,
        offset: 0,
      }),
    {
      ...QUERY_OPTIONS,
      enabled: !!site?.site_id,
      placeholderData: DEFAULT_DATA.alarms,
    }
  )

  const alarms = useMemo(() => {
    if (alarmsIsLoading) return []

    const alarms = alarmView?.alarms ?? []

    return [...alarms]
  }, [alarmsIsLoading, alarmView])

  const deleteDowntimeAero = async (downtimeIds: string[]) => {
    setActionState('pending')
    try {
      await Promise.all(
        downtimeIds.map(async data => {
          await DowntimeAPI.deleteDowntimeAero({ siteId: site.site_id, downtimeId: data })
        })
      )
    } catch (error) {
      console.log(error)
      setActionState('failure')
      return
    }
    setActionState('success')
    setModalOpen(false)
    setSelectedData([])
    await queryClient.invalidateQueries([CACHE_KEY_WTG, site.site_id, keyFromDate(selectedDate, 1)])
  }

  const handleClickCreateModal = () => {
    setEventSelected(undefined)
    setModalOpen(true)
  }

  const handleClickModalConsolidar = () => {
    setModalConsolidarOpen(true)
  }

  const handleModalConfirmationWarningToggle = () => {
    setActionState('idle')
    setModalWarningOpen(!isModalWarningOpen)
  }

  const handleModalConsolidarToggle = () => {
    setActionState('idle')
    setModalConsolidarOpen(!isModalConsolidarOpen)
  }

  const handleDeleteClick = (item: string | null) => {
    if (item) {
      setDataToDelete([item])
      handleModalConfirmationWarningToggle()
    }
  }

  const handleDeleteManyClick = (item: string[]) => {
    setDataToDelete(item)
    handleModalConfirmSelectedDatasToggle()
  }

  const handleModalConfirmSelectedDatasToggle = () => {
    setActionState('idle')
    setModalConfirmSelectedDatasOpen(!isModalConfirmSelectedDatasOpen)
  }

  function getName(id: number | null) {
    const found = turbs.find(a => a.turb_id === id)
    return found ? found.name : ''
  }

  function getCategory(id: number | null) {
    const found = tipos.find(t => t.id === id)
    return found ? `${found.categoria}` : ''
  }

  function getComponent(id: number | null) {
    const found = componentes.find(c => c.id === id)
    return found ? found.component : ''
  }

  const aeroDowntimeData = useMemo(() => {
    if (!downtimeAero) return []
    setSelectedData([])

    const _data = downtimeAero
      .map(el => ({
        id: el.id ?? '',
        aero: getName(el.turb_id) ?? '',
        inicio: el.ts_in ?? '',
        fim: el.ts_fin ?? '',
        componente: getComponent(el.component_id),
        usuario: el.username ?? '',
        categoria: getCategory(el.tipo_id),
        ...el,
      }))
      .filter(
        el =>
          el.aero.toLowerCase().includes(getName(searchInputAero.turb_id).toLowerCase()) &&
          (searchInputCategory.id !== 0
            ? el.categoria
                ?.toLowerCase()
                .includes((searchInputCategory.categoria as string).toLowerCase())
            : true) &&
          (searchInputComponent.id !== 0
            ? el.componente.toLowerCase().includes(searchInputComponent.component.toLowerCase())
            : true) &&
          (searchInputResponsibility.nome !== 'Responsabilidade'
            ? el.responsabilidade_re === searchInputResponsibility.id
            : true) &&
          (searchInputUser.username !== 'Usuário' ? el.username === searchInputUser.username : true)
      )

    return sortByKeyPosition(_data, activeSortIndex, activeSortDirection)
  }, [
    downtimeAero,
    componentes,
    tipos,
    searchInputAero,
    activeSortIndex,
    activeSortDirection,
    searchInputCategory,
    searchInputComponent,
    searchInputResponsibility,
    searchInputUser,
  ])

  const getSortParams = (columnIndex: number) => ({
    sortBy: {
      index: activeSortIndex,
      direction: activeSortDirection,
    },
    onSort: (_event: any, index: number, direction: 'asc' | 'desc') => {
      setActiveSortIndex(index)
      setActiveSortDirection(direction)
    },
    columnIndex,
  })

  const setSelectedRow = (row: DowntimeAero, isSelecting = true) =>
    setSelectedData(prevSelected => {
      const otherSelectedData = prevSelected.filter(r => r !== row)
      return isSelecting ? [...otherSelectedData, row] : otherSelectedData
    })

  const selectAllRows = (isSelecting = true) =>
    setSelectedData(isSelecting ? [...aeroDowntimeData] : [])

  const onSelectRow = (row: DowntimeAero, rowIndex: number, isSelecting: boolean) => {
    // If the user is shift + selecting the checkboxes, then all intermediate checkboxes should be selected
    if (shifting && recentSelectedRowIndex !== null) {
      const numberSelected = rowIndex - recentSelectedRowIndex
      const intermediateIndexes =
        numberSelected > 0
          ? Array.from(new Array(numberSelected + 1), (_x, i) => i + recentSelectedRowIndex)
          : Array.from(new Array(Math.abs(numberSelected) + 1), (_x, i) => i + rowIndex)
      intermediateIndexes.forEach(index => setSelectedRow(aeroDowntimeData[index], isSelecting))
    } else {
      setSelectedRow(row, isSelecting)
    }
    setRecentSelectedRowIndex(rowIndex)
  }

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setShifting(true)
      }
    }
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setShifting(false)
      }
    }

    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('keyup', onKeyUp)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [])

  const handleConsolidar = async (data: DowntimeAero[]) => {
    setActionState('pending')
    const isInvalid = data.some(el => data[0].turb_id !== el.turb_id)
    const requestBody = data.map(d => d.id ?? '')

    try {
      if (isInvalid) throw Error('Aerogeradores diferentes')
      await DowntimeAPI.mergeDowntimeAero({ siteId: site.site_id, requestBody })
    } catch (error: any) {
      console.log(error.status)
      setActionState('failure')
      return
    }
    setActionState('success')

    queryClient.invalidateQueries([CACHE_KEY_WTG, site.site_id, keyFromDate(selectedDate, 1)])
  }

  const resetFilters = () => {
    setSearchInputAero(DEFAULT_SEARCH_INPUT)
    setSearchInputCategory(DEFAULT_SEARCH_CATEGORY)
    setSearchInputComponent(DEFAULT_SEARCH_COMPONENT)
    setSearchInputResponsibility(DEFAULT_SEARCH_RESP)
    setSearchInputUser(DEFAULT_SEARCH_USER)
  }

  useEffect(() => {
    if (
      searchInputAero.name === 'WTG' &&
      searchInputCategory.categoria === 'Categoria' &&
      searchInputComponent.component === 'Componente' &&
      searchInputResponsibility.nome === 'Responsabilidade' &&
      searchInputUser.username === 'Usuário'
    ) {
      setActiveFilter(true)
    } else {
      setActiveFilter(false)
    }
  }, [
    searchInputAero,
    searchInputCategory,
    searchInputComponent,
    searchInputResponsibility,
    searchInputUser,
  ])

  useEffect(() => {
    resetFilters()
  }, [tsFin, tsFin, site.site_id])

  const isMobile = useMobile()
  const [toggleFilters, setToggleFilters] = useState<boolean>(false)

  const filterGroupItems = (
    <>
      <ToolbarItem style={{ padding: 0 }}>
        <Select<Turbines>
          items={[{ id: 0, name: 'WTG' }, ...turbs]}
          itemValueName={'name'}
          itemKeyName={'turb_id'}
          selected={searchInputAero}
          onChange={i => setSearchInputAero(i)}
          selectProps={{
            menuAppendTo: 'parent',
            maxHeight: '320px',
            width: `${isMobile ? '89vw' : '120px'}`,
          }}
          enableFilter
          className='pf-v5-u-p-none'
        />
      </ToolbarItem>
      <ToolbarItem style={{ padding: 0 }}>
        <Select<DowntimeTipos>
          items={[
            { id: 0, categoria: 'Categoria', tipo: 'Categoria' },
            ...aeroDowntimeData.filter((valor, index, self) => {
              return self.findIndex(element => element.categoria === valor.categoria) === index
            }),
          ]}
          itemValueName={'categoria'}
          itemKeyName={'id'}
          selected={searchInputCategory}
          onChange={i => setSearchInputCategory(i)}
          selectProps={{ menuAppendTo: 'parent', ...(isMobile && { width: '89vw' }) }}
          className='pf-v5-u-p-none'
        />
      </ToolbarItem>
      <ToolbarItem style={{ padding: 0 }}>
        <Select<DowntimeComponentes>
          items={[
            { id: 0, component: 'Componente' },
            ...componentes.filter(i => {
              const found = aeroDowntimeData.find(c => c.component_id === i.id)
              return found ? { id: found.id, component: found.componente } : null
            }),
          ]}
          itemValueName={'component'}
          itemKeyName={'id'}
          selected={searchInputComponent}
          onChange={i => setSearchInputComponent(i)}
          selectProps={{ menuAppendTo: 'parent', ...(isMobile && { width: '89vw' }) }}
          className='pf-v5-u-p-none'
        />
      </ToolbarItem>
      <ToolbarItem style={{ padding: 0 }}>
        <Select<Responsabilidade>
          items={[
            { id: true, nome: 'Responsabilidade' },
            { id: true, nome: 'Rio Energy' },
            { id: false, nome: 'Fabricante' },
          ]}
          itemValueName={'nome'}
          itemKeyName={'id'}
          selected={searchInputResponsibility}
          onChange={i => setSearchInputResponsibility(i)}
          selectProps={{ menuAppendTo: 'parent', ...(isMobile && { width: '89vw' }) }}
          className='pf-v5-u-p-none'
        />
      </ToolbarItem>
      <ToolbarItem style={{ padding: 0 }}>
        <Select<DowntimeUser>
          items={[
            { id: '', username: 'Usuário', tipo: 'Usuário' },
            ...aeroDowntimeData.filter((valor, index, self) => {
              return self.findIndex(element => element.username === valor.username) === index
            }),
          ]}
          itemValueName='username'
          itemKeyName='id'
          selected={searchInputUser}
          onChange={i => setSearchInputUser(i)}
          selectProps={{ menuAppendTo: 'parent', ...(isMobile && { width: '89vw' }) }}
          className='pf-v5-u-p-none'
        />
      </ToolbarItem>
      <ToolbarItem
        style={{
          ...(isMobile
            ? { padding: '4px 0', paddingBottom: '6px' }
            : { padding: '4px 0.35rem 4px 0' }),
        }}
      >
        <Button
          variant='control'
          style={{
            ...(isMobile && { width: '89vw' }),
            display: 'flex',
            alignItems: 'center',
            gap: '2px',
          }}
          onClick={resetFilters}
          isDisabled={activeFilter}
        >
          <Eraser size={'16px'} />
          Limpar
        </Button>
      </ToolbarItem>
    </>
  )

  const buttonGroupItems = (
    <>
      <ToolbarItem
        style={{
          ...(isMobile ? { padding: '4px 0', paddingTop: '6px' } : { padding: '4px 0.35rem' }),
          margin: 0,
        }}
      >
        <Button
          onClick={handleClickCreateModal}
          style={{
            height: '36px',
            margin: 0,
            ...(isMobile && { width: '89vw' }),
          }}
        >
          Adicionar
        </Button>
      </ToolbarItem>
      <ToolbarItem style={{ padding: `4px ${isMobile ? 0 : '0.35rem'}`, margin: 0 }}>
        {selectedData.length < 2 || searchInputAero.name === 'WTG' ? (
          <Tooltip content='Utilize o filtro WTG e selecione ao menos 2 eventos'>
            <Flex>
              <Button
                style={{
                  height: '36px',
                  margin: 0,
                  ...(isMobile && { width: '89vw' }),
                }}
                onClick={handleClickModalConsolidar}
                isDisabled={selectedData.length < 2 || searchInputAero.name === 'WTG'}
                variant='secondary'
              >
                Consolidar
              </Button>
            </Flex>
          </Tooltip>
        ) : (
          <Button
            style={{
              height: '36px',
              margin: 0,
              ...(isMobile && { width: '89vw' }),
            }}
            onClick={handleClickModalConsolidar}
            isDisabled={selectedData.length < 2 || searchInputAero.name === 'WTG'}
            variant='secondary'
          >
            Consolidar
          </Button>
        )}
      </ToolbarItem>
      <ToolbarItem style={{ padding: `4px ${isMobile ? 0 : '0.35rem'}`, margin: 0 }}>
        <Button
          style={{
            height: '36px',
            margin: 0,
            ...(isMobile && { width: '89vw' }),
          }}
          variant='danger'
          onClick={() => handleDeleteManyClick(selectedData.map(i => i.id as string))}
          isDisabled={!selectedData[0]}
        >
          Remover
        </Button>
      </ToolbarItem>
      <Divider
        orientation={{
          default: isMobile ? 'horizontal' : 'vertical',
        }}
        style={{
          ...(isMobile ? { width: '89vw' } : { height: '3vh', alignSelf: 'center' }),
          margin: 0,
        }}
      />
      <ResizeButtons setResizeMode={setResizeMode} resizeMode={resizeMode} />
    </>
  )

  const toolbarItems = (
    <div
      style={{
        display: 'flex',
        ...(isMobile && { flexDirection: 'column' }),
        width: '100%',
      }}
    >
      <ToolbarGroup
        variant='filter-group'
        style={{
          margin: 0,
          ...(isMobile && { display: 'flex', flexDirection: 'column' }),
          width: 'fit-content',
        }}
      >
        {filterGroupItems}
      </ToolbarGroup>

      <Divider
        orientation={{
          default: isMobile ? 'horizontal' : 'vertical',
        }}
        style={{
          ...(isMobile ? { width: '89vw' } : { height: '3vh', alignSelf: 'center' }),
          margin: 0,
        }}
      />

      <ToolbarGroup
        variant='button-group'
        style={{
          ...(isMobile && { display: 'flex', flexDirection: 'column' }),
          width: 'fit-content',
          margin: 0,
        }}
      >
        {buttonGroupItems}
      </ToolbarGroup>
    </div>
  )

  const tableAndContainerHeight = useMemo(() => {
    const h = calcVerticalHeight({
      elementSize: GRID_SIZE / 2 - resizeMode,
      gridSize: GRID_SIZE,
    })
    // Offset tem que ser fora, calcVerticalHeight distribui igualmente o offset entre os elementos
    const customOffset = TAB_HEADER_HEIGHT + TOOLBAR_HEIGHT
    const s = `calc(${h} - ${customOffset}rem)`

    return s
  }, [resizeMode])

  const heightBottom = useMemo(() => {
    const h = calcVerticalHeight({ elementSize: GRID_SIZE / 2 - resizeMode, gridSize: GRID_SIZE })
    // Offset tem que ser fora, calcVerticalHeight distribui igualmente o offset entre os elementos
    const customOffset = TABLE_HEADER_HEIGHT
    const s = `calc(${h} - ${customOffset}rem)`

    return s
  }, [resizeMode])

  return (
    <div style={{ height: heightBottom }}>
      {isMobile && (
        <Flex
          style={{ justifyContent: 'flex-start', backgroundColor: 'white', position: 'relative' }}
        >
          <Button
            style={{
              backgroundColor: 'transparent',
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
              justifyContent: 'center',
              height: '35px',
              color: 'black',
            }}
            onClick={() => setToggleFilters(!toggleFilters)}
          >
            {!toggleFilters ? (
              <>
                Ações
                <EmptyStateIcon icon={CogIcon} />
              </>
            ) : (
              <EmptyStateIcon icon={TimesIcon} />
            )}
          </Button>
        </Flex>
      )}

      {!isMobile || toggleFilters ? (
        <Flex
          style={{
            backgroundColor: 'white',
            position: 'relative',
          }}
        >
          <Toolbar
            id='toolbar-group-types'
            style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}
          >
            <ToolbarContent style={{ width: '100%' }}>{toolbarItems}</ToolbarContent>
          </Toolbar>
        </Flex>
      ) : null}

      <InnerScrollContainer style={{ height: tableAndContainerHeight, backgroundColor: '#f0f0f0' }}>
        <Table
          aria-label='Table Paradas Aero'
          variant='compact'
          isStriped
          isExpandable
          isStickyHeader
          key={site?.site_id}
          style={{ maxHeight: tableAndContainerHeight, backgroundColor: ColorScale.branco_clear }}
        >
          <Thead>
            <Tr style={{ height: `${TABLE_HEADER_HEIGHT}rem` }}>
              {aeroDowntimeData.length > 0 && (
                <Th
                  select={{
                    onSelect: (_event, isSelecting) => selectAllRows(isSelecting),
                    isSelected: selectedData.length === aeroDowntimeData.length,
                  }}
                />
              )}
              <Th sort={getSortParams(0)} style={{ width: '2rem' }}>
                Id
              </Th>
              <Th sort={getSortParams(1)}>WTG</Th>
              <Th>Categoria</Th>
              <Th sort={getSortParams(5)}>Usuário</Th>
              <Th sort={getSortParams(2)}>Início</Th>
              <Th sort={getSortParams(3)}>Fim</Th>
              <Th sort={getSortParams(4)} style={{ minWidth: '5rem' }}>
                Componente
              </Th>
              <Th style={{ minWidth: '6rem' }}>Responsabilidade</Th>
              <Th style={{ minWidth: '7rem' }}>Descrição</Th>
              <Th style={{ minWidth: '2.5rem' }}>Ações</Th>
            </Tr>
          </Thead>

          {aeroDowntimeData.length === 0 && (
            <Tbody>
              <Tr>
                <Td colSpan={11}>
                  <Bullseye
                    style={{
                      // TODO: Create another calc for empty STATE
                      height: heightBottom,
                    }}
                  >
                    <EmptyState variant={EmptyStateVariant.lg}>
                      <EmptyStateHeader
                        titleText='Nenhum registro encontrado.'
                        icon={<EmptyStateIcon icon={SearchIcon} />}
                        headingLevel='h3'
                      />
                      <EmptyStateFooter>
                        {searchInputAero && (
                          <>
                            <EmptyStateBody>Limpe o filtro e tente novamente.</EmptyStateBody>
                            <Button variant='link' onClick={resetFilters}>
                              Limpar filtro
                            </Button>
                          </>
                        )}
                      </EmptyStateFooter>
                    </EmptyState>
                  </Bullseye>
                </Td>
              </Tr>
            </Tbody>
          )}
          {aeroDowntimeData.length > 0 &&
            aeroDowntimeData.map((evnt, _) => {
              const turbAlarmes = alarms.filter(
                a =>
                  a.turb_id === evnt.turb_id &&
                  evnt?.ts_in &&
                  evnt?.ts_fin &&
                  a.ts_in >= evnt.ts_in &&
                  a.ts_fin <= evnt.ts_fin
              )
              return (
                <Tbody key={`${evnt.id}_${site.site_id}`}>
                  <Tr>
                    <Td
                      select={{
                        rowIndex: _,
                        onSelect: (_event, isSelecting) => onSelectRow(evnt, _, isSelecting),
                        isSelected: selectedData.includes(evnt),
                      }}
                    />
                    <Td dataLabel='Id'>{evnt.id?.substring(0, 8)}</Td>
                    <Td dataLabel='Aero'>
                      {evnt.aero}{' '}
                      <FilterIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          setSearchInputAero(
                            turbs.find(turb => turb.turb_id === evnt.turb_id) as Turbines
                          )
                        }
                      />
                    </Td>
                    <Td dataLabel='Categoria'>{evnt.categoria}</Td>
                    <Td dataLabel='Usuário'>{evnt.usuario}</Td>
                    <Td dataLabel='Início'>{formatDateTimeBrl(new Date(evnt.inicio))}</Td>
                    <Td dataLabel='Fim'>{formatDateTimeBrl(new Date(evnt.fim))}</Td>
                    <Td dataLabel='Componente'>{evnt.componente}</Td>
                    <Td dataLabel='Responsabilidade'>
                      {evnt.responsabilidade_re ? 'RioEnergy' : 'Fabricante'}
                    </Td>

                    <Td dataLabel='Descrição'>
                      <TableText
                        tooltipProps={{ className: 'pf-v5-u-display-none' }}
                        wrapModifier='truncate'
                      >
                        {evnt.descricao}
                      </TableText>
                    </Td>
                    <Td dataLabel='Ações'>
                      <div>
                        <Remove2Icon
                          onClick={() => {
                            handleDeleteClick(evnt.id)
                          }}
                          style={{ cursor: 'pointer' }}
                        />{' '}
                        <EditIcon
                          title='Editar downtime'
                          onClick={() => handleOpenModalEnergyExpected(evnt, turbAlarmes)}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </Td>
                  </Tr>
                </Tbody>
              )
            })}
        </Table>
      </InnerScrollContainer>

      <ModalConsolidar<DowntimeAero[]>
        isOpen={isModalConsolidarOpen}
        handleModalToggle={handleModalConsolidarToggle}
        handleSubmit={handleConsolidar}
        title='Consolidar eventos'
        element={selectedData}
        actionState={actionState}
        additionalInformation={
          selectedData.length > 1 && (
            <div>
              <OuterScrollContainer
                style={{ ...TABLE_HEIGHT_STYLE, borderBottom: '1px solid #d2d2d2' }}
              >
                <InnerScrollContainer>
                  <Table
                    aria-label='Table Paradas Aero Consolidar'
                    variant='compact'
                    isStriped
                    isExpandable
                    isStickyHeader
                    key={site?.site_id}
                  >
                    <Thead>
                      <Tr style={{ height: `${TABLE_HEADER_HEIGHT}rem` }}>
                        <Th style={{ width: '2rem' }}>Id</Th>
                        <Th>WTG</Th>
                        <Th>Categoria</Th>
                        <Th>Usuário</Th>
                        <Th>Início</Th>
                        <Th>Fim</Th>
                        <Th style={{ minWidth: '5rem' }}>Componente</Th>
                        <Th style={{ minWidth: '6rem' }}>Responsabilidade</Th>
                        <Th style={{ minWidth: '7rem' }}>Descrição</Th>
                      </Tr>
                    </Thead>
                    {selectedData.map((data, _) => {
                      return (
                        <Tbody key={`${data.id}_${site.site_id}`}>
                          <Tr>
                            <Td dataLabel='Id'>{data.id?.substring(0, 8)}</Td>
                            <Td dataLabel='Aero'>{getName(data.turb_id)}</Td>
                            <Td dataLabel='Categoria'>{getCategory(data.tipo_id)}</Td>
                            <Td dataLabel='Usuário'>{data.username}</Td>
                            <Td dataLabel='Início'>
                              {formatDateTimeBrl(new Date(data.ts_in as string))}
                            </Td>
                            <Td dataLabel='Fim'>
                              {formatDateTimeBrl(new Date(data.ts_fin as string))}
                            </Td>
                            <Td dataLabel='Componente'>{getComponent(data.component_id)}</Td>
                            <Td dataLabel='Responsabilidade'>
                              {data.responsabilidade_re ? 'RioEnergy' : 'Fabricante'}
                            </Td>
                            <Td dataLabel='Descrição'>
                              <TableText
                                tooltipProps={{ className: 'pf-v5-u-display-none' }}
                                wrapModifier='truncate'
                              >
                                {data.descricao}
                              </TableText>
                            </Td>
                          </Tr>
                        </Tbody>
                      )
                    })}
                  </Table>
                </InnerScrollContainer>
              </OuterScrollContainer>
            </div>
          )
        }
      />
      <ModalConfirmationWarning<string>
        isOpen={isModalWarningOpen}
        handleModalToggle={handleModalConfirmationWarningToggle}
        handleSubmit={id => deleteDowntimeAero([id])}
        title='Remover parada do Aerogerador'
        element={dataToDelete[0]}
        actionState={actionState}
      />
      <ModalConfirmationWarning<DowntimeAero[]>
        isOpen={isModalConfirmSelectedDatasOpen}
        handleModalToggle={handleModalConfirmSelectedDatasToggle}
        handleSubmit={data => deleteDowntimeAero(data.map(i => i.id as string))}
        title='Remover paradas dos Aerogeradores'
        element={selectedData}
        actionState={actionState}
        alertMessage='Tem certeza que deseja remover estes itens? Essa ação não poderá ser desfeita.'
        additionalInformation={
          selectedData.length > 0 && (
            <div>
              <OuterScrollContainer
                style={{ ...TABLE_HEIGHT_STYLE, borderBottom: '1px solid #d2d2d2' }}
              >
                <InnerScrollContainer>
                  <Table
                    aria-label='Table Paradas Aero Delete'
                    variant='compact'
                    isStriped
                    isExpandable
                    isStickyHeader
                    key={site?.site_id}
                  >
                    <Thead>
                      <Tr style={{ height: `${TABLE_HEADER_HEIGHT}rem` }}>
                        <Th style={{ width: '2rem' }}>Id</Th>
                        <Th>WTG</Th>
                        <Th>Usuário</Th>
                        <Th>Início</Th>
                        <Th>Fim</Th>
                      </Tr>
                    </Thead>
                    {selectedData.map((data, _) => {
                      return (
                        <Tbody key={`${data.id}_${site.site_id}`}>
                          <Tr>
                            <Td dataLabel='Id'>{data.id?.substring(0, 8)}</Td>
                            <Td dataLabel='aero' width={15}>
                              {getName(data.turb_id)}
                            </Td>
                            <Td dataLabel='Usuário'>{data.username}</Td>
                            <Td dataLabel='Início'>
                              {formatDateTimeBrl(new Date(data.ts_in as string))}
                            </Td>
                            <Td dataLabel='Fim'>
                              {formatDateTimeBrl(new Date(data.ts_fin as string))}
                            </Td>
                          </Tr>
                        </Tbody>
                      )
                    })}
                  </Table>
                </InnerScrollContainer>
              </OuterScrollContainer>
            </div>
          )
        }
      />

      <ModalAero
        isModalOpen={isModalOpen}
        handleModalToggle={() => setModalOpen(false)}
        handleDeleteClick={handleDeleteClick}
        eventSelected={eventSelected}
        selectedDate={selectedDate}
        alarms={turbAlarmesSelected}
        modalConfirmation={
          <ModalConfirmationWarning<string>
            isOpen={isModalWarningOpen}
            handleModalToggle={handleModalConfirmationWarningToggle}
            handleSubmit={id => deleteDowntimeAero([id])}
            title='Remover parada do Aerogerador'
            element={dataToDelete[0]}
            actionState={actionState}
          />
        }
      />
    </div>
  )
}

export default TableAero
