import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect } from 'react'
import { AvailabilityAPI, DowntimeAPI, DowntimeMetadata } from '../../client'
import SitesContext from '../../contexts/SitesContext'
import { queryClient } from '../../services/api'
import {
  CACHE_KEY_BOP,
  CACHE_KEY_ONS,
  CACHE_KEY_OPT,
  CACHE_KEY_PC,
  CACHE_KEY_WTG,
  DEFAULT_DATA,
  QUERY_OPTIONS,
} from './constants'

export const keyFromDate = (date: Date, mmDelta: number) => {
  return `${date.getFullYear()}_${date.getMonth() + mmDelta}`
}
const prefetchQuery = async (
  cacheKey: string,
  siteId: number,
  date: Date,
  getter: ({ siteId, yyyy, mm }: { siteId: number; yyyy: number; mm: number }) => any
) => {
  await queryClient.prefetchQuery(
    [cacheKey, siteId, keyFromDate(date, 1)],
    () => getter({ siteId, yyyy: date.getFullYear(), mm: date.getMonth() + 1 }),
    QUERY_OPTIONS
  )
  await queryClient.prefetchQuery(
    [cacheKey, siteId, keyFromDate(date, 0)],
    () => getter({ siteId, yyyy: date.getFullYear(), mm: date.getMonth() }),
    QUERY_OPTIONS
  )
}

const useData = (selectedDate: Date) => {
  const { site, sites } = useContext(SitesContext)
  const enabled = !!site?.site_id
  const siteId = site?.site_id ?? 0
  const yyyy = selectedDate.getFullYear()
  const mm = selectedDate.getMonth() + 1
  const requestParams = { siteId, yyyy, mm }

  // Options
  const { data: downtimeMetadata, isLoading: optionsIsLoading } = useQuery<DowntimeMetadata>(
    [CACHE_KEY_OPT, siteId, keyFromDate(selectedDate, 1)],
    () => DowntimeAPI.getOptions(),
    { ...QUERY_OPTIONS, enabled, placeholderData: DEFAULT_DATA.options }
  )

  // Aero
  const { data: downtimeAero, isLoading: aeroIsLoading } = useQuery(
    [CACHE_KEY_WTG, siteId, keyFromDate(selectedDate, 1)],
    () => DowntimeAPI.getDowntimeAero(requestParams),
    { ...QUERY_OPTIONS, enabled, placeholderData: DEFAULT_DATA.downtimeAero }
  )
  useEffect(() => {
    if (aeroIsLoading) return
    sites
      .filter(s => s.site_id !== siteId)
      .forEach(s => {
        prefetchQuery(CACHE_KEY_WTG, s.site_id, selectedDate, DowntimeAPI.getDowntimeAero)
      })
  }, [aeroIsLoading, selectedDate])

  // BOP
  const { data: downtimeBop, isLoading: bopIsLoading } = useQuery(
    [CACHE_KEY_BOP, siteId, keyFromDate(selectedDate, 1)],
    () => DowntimeAPI.getDowntimeBop(requestParams),
    { ...QUERY_OPTIONS, enabled, placeholderData: DEFAULT_DATA.downtimeBop }
  )
  useEffect(() => {
    if (bopIsLoading) return
    sites
      .filter(s => s.site_id !== siteId)
      .forEach(s => {
        prefetchQuery(CACHE_KEY_BOP, s.site_id, selectedDate, DowntimeAPI.getDowntimeBop)
      })
  }, [bopIsLoading, selectedDate])

  // ONS
  const { data: downtimeOns, isLoading: onsIsLoading } = useQuery(
    [CACHE_KEY_ONS, site?.site_id, keyFromDate(selectedDate, 1)],
    () => DowntimeAPI.getDowntimeOns(requestParams),
    { ...QUERY_OPTIONS, enabled, placeholderData: DEFAULT_DATA.downtimeOns }
  )
  useEffect(() => {
    if (onsIsLoading) return
    sites
      .filter(s => s.site_id !== siteId)
      .forEach(s => {
        prefetchQuery(CACHE_KEY_ONS, s.site_id, selectedDate, DowntimeAPI.getDowntimeOns)
      })
  }, [onsIsLoading, selectedDate])

  // Power Curve Reclassification
  const { data: pcReclassification, isLoading: pcReclassificationIsLoading } = useQuery(
    [CACHE_KEY_PC, site?.site_id, keyFromDate(selectedDate, 1)],
    () => AvailabilityAPI.getAllPcReclassification(requestParams),
    { ...QUERY_OPTIONS, enabled, placeholderData: DEFAULT_DATA.pcReclassification }
  )

  const { componentes, setor, tipos } = downtimeMetadata ?? DEFAULT_DATA.options

  return {
    dataIsLoading:
      optionsIsLoading ||
      aeroIsLoading ||
      bopIsLoading ||
      onsIsLoading ||
      pcReclassificationIsLoading,
    downtimeOns: downtimeOns ?? DEFAULT_DATA.downtimeOns,
    downtimeBop: downtimeBop ?? DEFAULT_DATA.downtimeBop,
    downtimeAero: downtimeAero ?? DEFAULT_DATA.downtimeAero,
    pcReclassification: pcReclassification ?? DEFAULT_DATA.pcReclassification,
    componentes,
    setores: setor,
    tipos,
  }
}

export default useData
