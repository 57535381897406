import {
  Button,
  DualListSelector,
  Form,
  FormGroup,
  Modal,
  ModalVariant,
  TextInput,
} from '@patternfly/react-core'

type ModalCreateUserProps = {
  isModalOpen: boolean
  handleModalToggle: () => void
  handleSubmit: () => Promise<void>
  nameValue: string
  handleNameInputChange: (value: string) => void
  passwordValue: string
  handlePasswordInputChange: (value: string) => void
  emailValue: string
  handleEmailInputChange: (value: string) => void
  availableGroupsOptions: string[]
  chosenGroupsOptions: string[]
  availableSitesOptions: string[]
  chosenSitesOptions: string[]
  onGroupsListChange: (
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) => void
  onSitesListChange: (
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) => void
}

function ModalCreateUser({
  isModalOpen,
  handleModalToggle,
  handleSubmit,
  nameValue,
  handleNameInputChange,
  passwordValue,
  handlePasswordInputChange,
  emailValue,
  handleEmailInputChange,
  availableGroupsOptions,
  chosenGroupsOptions,
  onGroupsListChange,
  availableSitesOptions,
  chosenSitesOptions,
  onSitesListChange,
}: ModalCreateUserProps) {
  return (
    <Modal
      variant={ModalVariant.small}
      title='Criar usuário'
      isOpen={isModalOpen}
      onClose={handleModalToggle}
      actions={[
        <Button key='create' variant='primary' form='modal-with-form-form' onClick={handleSubmit}>
          Enviar
        </Button>,
        <Button key='cancelar' variant='secondary' isDanger onClick={handleModalToggle}>
          Cancelar
        </Button>,
      ]}
    >
      <Form id='modal-with-form-form'>
        <FormGroup label='Nome' isRequired fieldId='simple-form-name-01'>
          <TextInput
            isRequired
            type='text'
            id='simple-form-name-01'
            name='simple-form-name-01'
            value={nameValue}
            onChange={(_, value) => handleNameInputChange(value)}
          />
        </FormGroup>
        <FormGroup label='Senha' isRequired fieldId='simple-form-password-01'>
          <TextInput
            isRequired
            type='password'
            id='simple-form-senha-01'
            name='simple-form-senha-01'
            value={passwordValue}
            onChange={(_, value) => handlePasswordInputChange(value)}
          />
        </FormGroup>
        <FormGroup label='Email' isRequired fieldId='simple-form-email-01'>
          <TextInput
            isRequired
            type='email'
            id='simple-form-email-01'
            name='simple-form-email-01'
            value={emailValue}
            onChange={(_, value) => handleEmailInputChange(value)}
          />
        </FormGroup>

        <DualListSelector
          availableOptionsTitle='Grupos'
          availableOptions={availableGroupsOptions}
          chosenOptionsTitle='Grupos selecionados'
          chosenOptions={chosenGroupsOptions}
          onListChange={(evnt, newAvailableOptions, newChosenOptions) =>
            onGroupsListChange(evnt, newAvailableOptions as string[], newChosenOptions as string[])
          }
          id='DualListSelectorGroups'
        />
        <DualListSelector
          availableOptionsTitle='Sites'
          availableOptions={availableSitesOptions}
          chosenOptionsTitle='Sites selecionados'
          chosenOptions={chosenSitesOptions}
          onListChange={(evnt, newAvailableOptions, newChosenOptions) =>
            onSitesListChange(evnt, newAvailableOptions as string[], newChosenOptions as string[])
          }
          id='DualListSelectorSites'
        />
      </Form>
    </Modal>
  )
}

export default ModalCreateUser
