import {
  Button,
  DualListSelector,
  Form,
  FormGroup,
  Modal,
  ModalVariant,
  TextInput,
} from '@patternfly/react-core'
import { FormEvent } from 'react'
import { UserOut } from '../../../client'

type ModalEditUserProps = {
  isModalOpen: boolean
  handleModalToggle: () => void
  user: UserOut | undefined
  handleNameInputChange: React.Dispatch<React.SetStateAction<string>>
  handlePasswordInputChange: React.Dispatch<React.SetStateAction<string>>
  handleEmailInputChange: React.Dispatch<React.SetStateAction<string>>
  availableGroupsOptions: string[]
  chosenGroupsOptions: string[]
  availableSitesOptions: string[]
  chosenSitesOptions: string[]
  onGroupsListChange: (
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) => void
  onSitesListChange: (
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) => void
  handleSubmit: () => Promise<void>
}

function ModalEditUser(props: ModalEditUserProps) {
  return (
    <Modal
      variant={ModalVariant.small}
      title='Editar usuário'
      isOpen={props.isModalOpen}
      onClose={props.handleModalToggle}
      actions={[
        <Button
          type='submit'
          key='create'
          variant='primary'
          form='modal-with-form-form'
          onClick={props.handleSubmit}
        >
          Enviar
        </Button>,
        <Button key='cancelar' variant='secondary' isDanger onClick={props.handleModalToggle}>
          Cancelar
        </Button>,
      ]}
    >
      <Form id='modal-with-form-form'>
        <FormGroup label='Nome' isRequired fieldId='simple-form-name-01'>
          <TextInput
            isRequired
            type='text'
            id='simple-form-name-01'
            name='simple-form-name-01'
            defaultValue={props.user?.username}
            onChange={(_event: FormEvent<HTMLInputElement>, value: string) =>
              props.handleNameInputChange(value)
            }
          />
        </FormGroup>

        <FormGroup label='Senha' isRequired fieldId='simple-form-password-01'>
          <TextInput
            isRequired
            type='password'
            id='simple-form-senha-01'
            name='simple-form-senha-01'
            defaultValue=''
            onChange={(_event: FormEvent<HTMLInputElement>, value: string) =>
              props.handlePasswordInputChange(value)
            }
          />
        </FormGroup>
        <FormGroup label='Email' isRequired fieldId='simple-form-email-01'>
          <TextInput
            isRequired
            type='email'
            id='simple-form-email-01'
            name='simple-form-email-01'
            defaultValue={props.user?.email}
            onChange={(_event: FormEvent<HTMLInputElement>, value: string) =>
              props.handleEmailInputChange(value)
            }
          />
        </FormGroup>
        <DualListSelector
          availableOptionsTitle='Grupos'
          availableOptions={props.availableGroupsOptions}
          chosenOptionsTitle='Grupos selecionados'
          chosenOptions={props.chosenGroupsOptions}
          onListChange={(evnt, newAvailableOptions, chosenGroupsOptions) =>
            props.onGroupsListChange(
              evnt,
              newAvailableOptions as string[],
              chosenGroupsOptions as string[]
            )
          }
          id='basicSelector'
        />
        <DualListSelector
          availableOptionsTitle='Sites'
          availableOptions={props.availableSitesOptions}
          chosenOptionsTitle='Sites selecionados'
          chosenOptions={props.chosenSitesOptions}
          onListChange={(evnt, newAvailableOptions, chosenGroupsOptions) =>
            props.onSitesListChange(
              evnt,
              newAvailableOptions as string[],
              chosenGroupsOptions as string[]
            )
          }
          id='basicSelector-2'
        />
      </Form>
    </Modal>
  )
}

export default ModalEditUser
