import { useQuery } from '@tanstack/react-query'
import { createContext, ReactNode } from 'react'
import { GroupOut, Me, Pages, PermissionTypes, UserOut } from '../client'

import api from '../services/api'
import { getToken, logout } from '../services/auth'

const queryOptions = {
  refetchOnWindowFocus: true,
  retry: false,
  staleTime: 1000 * 60 * 5,
}

interface IPermissionContext {
  users: UserOut[]
  groups: GroupOut[]
  pages: Pages[]
  permissionTypes: PermissionTypes[]
  me: Me
  meIsLoading: boolean
  isLoading: boolean
}

const PermissionContextDefaults: IPermissionContext = {
  users: new Array<UserOut>(),
  groups: new Array<GroupOut>(),
  pages: new Array<Pages>(),
  permissionTypes: new Array<PermissionTypes>(),
  me: {} as Me,
  meIsLoading: true,
  isLoading: true,
}

const PermissionContext = createContext<IPermissionContext>(PermissionContextDefaults)

export const PermissionProvider = ({ children }: { children: ReactNode }) => {
  const token = getToken()

  const { isLoading: meIsLoading, data: me } = useQuery(
    ['me'],
    () =>
      api
        .get('me/', {
          validateStatus: status => {
            if (status === 401) {
              logout()
              return false
            }
            return true
          },
        })
        .then(r => r.data),
    { enabled: !!token, ...queryOptions }
  )
  const { isLoading: pagesIsLoading, data: pages } = useQuery(
    ['pages'],
    () => api.get('/admin/pages').then(r => r.data),
    { enabled: !!me, ...queryOptions }
  )
  const { isLoading: usersIsLoading, data: users } = useQuery(
    ['users'],
    () =>
      api
        .get('/admin/users')
        .then(r => r.data)
        .catch(r => {
          console.log(r)
        }),
    { enabled: !!me, ...queryOptions }
  )
  const { isLoading: groupsIsLoading, data: groups } = useQuery(
    ['groups'],
    () =>
      api
        .get('/admin/groups')
        .then(r => r.data)
        .catch(r => {
          console.log(r)
        }),
    { enabled: !!me, ...queryOptions }
  )
  const { isLoading: permTypesIsLoading, data: permissionTypes } = useQuery(
    ['permission_types'],
    () =>
      api
        .get('/admin/permission_types')
        .then(r => r.data)
        .catch(r => {
          console.log(r)
        }),
    { enabled: !!me, ...queryOptions }
  )

  const isLoading =
    meIsLoading || pagesIsLoading || usersIsLoading || groupsIsLoading || permTypesIsLoading

  const provides = { users, groups, pages, permissionTypes, me, meIsLoading, isLoading }

  return <PermissionContext.Provider value={provides}>{children}</PermissionContext.Provider>
}

export default PermissionContext
