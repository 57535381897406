import {
  Alert,
  Button,
  Flex,
  FlexItem,
  Form,
  FormGroup,
  Grid,
  GridItem,
  Modal,
  ModalVariant,
  TextInput,
} from '@patternfly/react-core'
import FormData from 'form-data'
import { useMemo, useState } from 'react'
import { StatusResponseType } from '.'
import semImagem from '../../assets/img/semimagem.png'
import { CivilOcorrencia, Site } from '../../client'
import api, { queryClient, REACT_APP_API_URL } from '../../services/api'
import EditImage from './EditImage'

interface IModalEditarOcorrenciaProps {
  isOpenModalEditarOcorrencia: boolean
  setIsOpenModalEditarOcorrencia: React.Dispatch<React.SetStateAction<boolean>>
  ocorrencia: CivilOcorrencia
  setOcorrencia: React.Dispatch<React.SetStateAction<CivilOcorrencia | null>>
  site: Site
}

export const ModalEditarOcorrencia = (props: IModalEditarOcorrenciaProps) => {
  const {
    isOpenModalEditarOcorrencia,
    setIsOpenModalEditarOcorrencia,
    ocorrencia,
    setOcorrencia,
    site,
  } = props

  const [data_origem, setData_origem] = useState(
    ocorrencia?.data_origem ? ocorrencia.data_origem : ''
  )
  const [situacao, setSituacao] = useState(ocorrencia?.situacao ? ocorrencia.situacao : '')
  const [lat, setLat] = useState(ocorrencia?.lat ? ocorrencia.lat : '')
  const [lon, setLon] = useState(ocorrencia?.lon ? ocorrencia.lon : '')
  const [natureza_origem, setNatureza_origem] = useState(
    ocorrencia?.natureza_origem ? ocorrencia.natureza_origem : ''
  )
  const [criticidade, setCriticidade] = useState(
    ocorrencia?.criticidade ? ocorrencia.criticidade : ''
  )
  const [data_relatorio, setData_relatorio] = useState(
    ocorrencia?.data_relatorio ? ocorrencia.data_relatorio : ''
  )
  const [descricao_ocorrencia, setDescricao_ocorrencia] = useState(
    ocorrencia?.descricao_ocorrencia ? ocorrencia.descricao_ocorrencia : ''
  )

  const imgPath = ocorrencia?.img_path
    ? `${REACT_APP_API_URL}/static/${ocorrencia.img_path}`
    : semImagem

  const [newImageUrl, setNewImageUrl] = useState('')
  const [fileImage, setFileImage] = useState(null)

  const [isSalvarLoading, setIsSalvarLoading] = useState(false)
  const [statusResponseSave, setStatusResponseSave] = useState<StatusResponseType>('')

  const primaryLoadingProps = useMemo(() => {
    return {
      spinnerAriaValueText: 'Loading',
      spinnerAriaLabelledBy: 'salvar-loading-button',
      isLoading: isSalvarLoading,
    }
  }, [isSalvarLoading])

  const handleCloseModal = () => {
    setIsOpenModalEditarOcorrencia(false)
    setOcorrencia(null)
    setIsSalvarLoading(false)
    setStatusResponseSave('')
  }

  const handleSubmit = async () => {
    setStatusResponseSave('')
    setIsSalvarLoading(true)

    if (
      data_origem === '' ||
      situacao === '' ||
      lat === '' ||
      lon === '' ||
      natureza_origem === '' ||
      criticidade === '' ||
      data_relatorio === '' ||
      descricao_ocorrencia === ''
    ) {
      setIsSalvarLoading(false)
      return
    }

    const dataOcorrenciaUpdate = {
      id: ocorrencia.id,
      data_origem,
      situacao,
      lat,
      lon,
      natureza_origem,
      criticidade,
      data_relatorio,
      descricao_ocorrencia,
    }

    const formData = new FormData()
    formData.append('image', fileImage)

    try {
      await api.put('/sites/occurrences', dataOcorrenciaUpdate)

      await api.put(`sites/occurrences/${ocorrencia.id}/image`, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': `multipart/form-data; boundary=${formData.getBoundary()}`,
        },
      })

      setIsSalvarLoading(false)
      setStatusResponseSave('success')
      await queryClient.invalidateQueries(['occurrences', site.site_id])
    } catch (error) {
      console.error(error)
      setIsSalvarLoading(false)
      setStatusResponseSave('error')
    }
  }

  return (
    <Modal
      isOpen={isOpenModalEditarOcorrencia}
      onClose={handleCloseModal}
      variant={ModalVariant.medium}
      title='Editar ocorrência'
      actions={[
        <Flex key='container-flex-action' style={{ width: '100%' }}>
          <FlexItem flex={{ default: 'flex_1' }} alignSelf={{ default: 'alignSelfCenter' }}>
            {statusResponseSave === 'success' && (
              <Alert
                variant='success'
                isInline
                title='Ocorrência atualizada com sucesso!'
                style={{ padding: 6 }}
              />
            )}

            {statusResponseSave === 'error' && (
              <Alert
                variant='danger'
                isInline
                style={{ padding: 6 }}
                title='Houve um erro ao atualizar ocorrência!'
              />
            )}
          </FlexItem>
          <FlexItem align={{ default: 'alignRight' }}>
            <Button
              id='salvar-loading-button'
              key='create'
              variant='primary'
              form='modal-with-form-form'
              onClick={handleSubmit}
              className='green'
              {...primaryLoadingProps}
              isDisabled={!!isSalvarLoading}
            >
              {isSalvarLoading ? 'Salvando' : 'Salvar'}
            </Button>
          </FlexItem>
          <FlexItem>
            <Button key='cancel' variant='secondary' isDanger onClick={handleCloseModal}>
              Cancelar
            </Button>
          </FlexItem>
        </Flex>,
      ]}
    >
      <Form id='modal-with-form-form'>
        <Grid lg={6} hasGutter>
          <GridItem>
            <FormGroup label='Data origem' isRequired fieldId='simple-form-data-origem'>
              <TextInput
                isRequired
                type='text'
                id='data_origem'
                name='data_origem'
                {...(ocorrencia?.data_origem && { defaultValue: ocorrencia.data_origem })}
                onChange={(_event, val) => setData_origem(val)}
              />
            </FormGroup>
          </GridItem>
          <GridItem>
            <FormGroup label='Situação' isRequired fieldId='simple-form-situacao'>
              <TextInput
                isRequired
                type='text'
                id='situacao'
                name='situacao'
                {...(ocorrencia?.situacao && { defaultValue: ocorrencia.situacao })}
                onChange={(_event, val) => setSituacao(val)}
              />
            </FormGroup>
          </GridItem>
        </Grid>
        <Grid hasGutter>
          <GridItem lg={6}>
            <FormGroup label='Latitude' isRequired fieldId='simple-form-latitude'>
              <TextInput
                isRequired
                type='text'
                id='lat'
                name='lat'
                {...(ocorrencia?.lat && { defaultValue: ocorrencia.lat })}
                onChange={(_event, val) => setLat(val)}
              />
            </FormGroup>
          </GridItem>
          <GridItem lg={6} lgRowSpan={5}>
            <FormGroup label='Foto da ocorrência' isRequired fieldId='simple-form-foto'>
              <EditImage
                imgPath={imgPath}
                setFileImage={setFileImage}
                newImageUrl={newImageUrl}
                setNewImageUrl={setNewImageUrl}
              />
            </FormGroup>
          </GridItem>
          <GridItem lg={6}>
            <FormGroup label='Longitude' isRequired fieldId='simple-form-longitude'>
              <TextInput
                isRequired
                type='text'
                id='lon'
                name='lon'
                {...(ocorrencia?.lon && { defaultValue: ocorrencia.lon })}
                onChange={(_event, val) => setLon(val)}
              />
            </FormGroup>
          </GridItem>
          <GridItem lg={6}>
            <FormGroup label='Natureza de origem' isRequired fieldId='simple-form-natureza-origem'>
              <TextInput
                isRequired
                type='text'
                id='natureza_origem'
                name='natureza_origem'
                {...(ocorrencia?.natureza_origem && { defaultValue: ocorrencia.natureza_origem })}
                onChange={(_event, val) => setNatureza_origem(val)}
              />
            </FormGroup>
          </GridItem>
          <GridItem span={6}>
            <FormGroup label='Criticidade' isRequired fieldId='simple-form-criticidade'>
              <TextInput
                isRequired
                type='text'
                id='criticidade'
                name='criticidade'
                {...(ocorrencia?.criticidade && { defaultValue: ocorrencia.criticidade })}
                onChange={(_event, val) => setCriticidade(val)}
              />
            </FormGroup>
          </GridItem>
          <GridItem span={6}>
            <FormGroup label='Data relatório' isRequired fieldId='simple-form-longitude'>
              <TextInput
                isRequired
                type='text'
                id='data_relatorio'
                name='data_relatorio'
                {...(ocorrencia?.data_relatorio && { defaultValue: ocorrencia.data_relatorio })}
                onChange={(_event, val) => setData_relatorio(val)}
              />
            </FormGroup>
          </GridItem>
        </Grid>

        <Grid lg={6} hasGutter>
          <GridItem span={12}>
            <FormGroup
              label='Descrição da ocorrência'
              isRequired
              fieldId='simple-form-natureza-origem'
            >
              <TextInput
                isRequired
                type='text'
                id='descricao_ocorrencia'
                name='descricao_ocorrencia'
                {...(ocorrencia?.descricao_ocorrencia && {
                  defaultValue: ocorrencia.descricao_ocorrencia,
                })}
                onChange={(_event, val) => setDescricao_ocorrencia(val)}
              />
            </FormGroup>
          </GridItem>
        </Grid>
      </Form>
    </Modal>
  )
}
