import {
  ExpandableSection,
  ExpandableSectionToggle,
  Icon,
  Panel,
  Skeleton,
} from '@patternfly/react-core'
import { ExternalLinkAltIcon, ThIcon } from '@patternfly/react-icons'
import {
  InnerScrollContainer,
  OuterScrollContainer,
  Table /* data-codemods */,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { range } from 'd3-array'
import { useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Turbines, TurbinesHealthEvent, TurbineSystems } from '../../client'
import Empty from '../../components/Empty'
import SitesContext from '../../contexts/SitesContext'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import ColorScale from '../../utils/colorScale'
import { formatDateWithoutTime } from '../../utils/formatDate'
import './eventsTable.style.css'
import { farol } from './TurbineHealth/commons'
import { direction } from './TurbineHealth/HealthTable'

const PADDING =
  'var(--pf-v5-c-expandable-section__toggle--PaddingTop)' +
  'var(--pf-v5-c-expandable-section__toggle--PaddingRight)' +
  'var(--pf-v5-c-expandable-section__toggle--PaddingBottom)' +
  'var(--pf-v5-c-panel__header--PaddingLeft)'

const fifteenDays = (date: Date, operation: 'add' | 'sub') => {
  const newDate = new Date(date)
  if (operation === 'add') {
    newDate.setDate(newDate.getDate() + 15)
  } else {
    newDate.setDate(newDate.getDate() - 15)
  }
  return newDate
}

const rangeParamsUrl = (startDate: string) => {
  const today = new Date()
  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0)
  today.setMilliseconds(0)

  let start = new Date(startDate)
  let end = fifteenDays(start, 'add')

  if (end >= today) {
    end = today
    start = fifteenDays(end, 'sub')
  }

  return `start=${formatDateWithoutTime(start)}&end=${formatDateWithoutTime(end)}`
}

const EventsTable = ({
  title,
  isLoading,
  isExpanded,
  setIsExpanded,
  events,
  turbsMap,
  systemMap,
  showLink = true,
  showTurbine = true,
}: {
  title: string
  isLoading: boolean
  isExpanded: boolean
  setIsExpanded: (isExpanded: boolean) => void
  events?: TurbinesHealthEvent[]
  turbsMap: Map<number, Turbines>
  systemMap?: Map<number, TurbineSystems>
  showLink?: boolean
  showTurbine?: boolean
}) => {
  const [activeSortDirection, setActiveSortDirection] = useState<direction>(null)
  const [activeSortIndex, setActiveSortIndex] = useState<number | null>(null)

  const { site } = useContext(SitesContext)

  const showSkeleton = isLoading || !events || !systemMap
  const skeleton = <Skeleton />

  const getSortParams = (columnIndex: number) => ({
    sortBy:
      typeof activeSortIndex === 'number'
        ? {
            index: activeSortIndex,
            direction: activeSortDirection ?? 'asc',
          }
        : {},
    onSort: (_event: React.MouseEvent, index: number, direction: direction) => {
      setActiveSortIndex(index)
      setActiveSortDirection(direction)
    },
    columnIndex,
  })

  const getSortableRowValues = (turbDataEvents: TurbinesHealthEvent) => {
    const { turb_id, system_id, alarm_level, ts_in, ts_fin } = turbDataEvents

    return [
      turbsMap.get(turb_id)?.name,
      systemMap?.get(system_id)?.system,
      alarm_level,
      ts_in,
      ts_fin,
    ]
  }

  const sortedEvents = useMemo(() => {
    let sortedData: TurbinesHealthEvent[] | undefined = undefined

    if (activeSortIndex !== null) {
      sortedData = events?.sort((a, b) => {
        const aValue = getSortableRowValues(a)[activeSortIndex]
        const bValue = getSortableRowValues(b)[activeSortIndex]

        if (typeof aValue === 'number' && typeof bValue === 'number') {
          if (activeSortDirection === 'asc') {
            return aValue - bValue
          }
          return bValue - aValue
        } else {
          if (activeSortDirection === 'asc') {
            return String(aValue)?.localeCompare(String(bValue))
          }

          return String(bValue)?.localeCompare(String(aValue))
        }
      })
    } else {
      sortedData = events?.sort((a, b) => a?.turb_id - b?.turb_id)
    }

    return sortedData
  }, [events, activeSortDirection, activeSortIndex])

  return (
    <Panel style={{ background: ColorScale.branco }} className='pf-v5-u-box-shadow-md'>
      <ExpandableSectionToggle
        style={{
          padding: PADDING,
          display: 'flex',
          alignItems: 'center',
        }}
        onToggle={() => setIsExpanded(!isExpanded)}
        isExpanded={isExpanded}
      >
        <div>
          <b className='expandable-title'>{title}</b>{' '}
          {/* className prop required because patternfly removes inline style */}
        </div>
      </ExpandableSectionToggle>

      <ExpandableSection
        isDetached={true}
        onToggle={() => setIsExpanded(!isExpanded)}
        isExpanded={isExpanded}
      >
        <div>
          <OuterScrollContainer style={{ maxHeight: calcVerticalHeight({ customOffset: '7rem' }) }}>
            <InnerScrollContainer>
              <Table
                ouiaId='turb-health-active-events-table'
                aria-label='TowerTableActiveEvents'
                isStickyHeader
                variant='compact'
                borders={false}
                gridBreakPoint={'grid-md'}
                className='table-turbine-health'
              >
                <Thead>
                  <Tr>
                    {showTurbine && (
                      <Th sort={getSortParams(0)} textCenter>
                        Turbine
                      </Th>
                    )}
                    <Th sort={getSortParams(1)} textCenter>
                      System
                    </Th>
                    <Th sort={getSortParams(2)} textCenter>
                      Alarm
                    </Th>
                    <Th sort={getSortParams(3)} textCenter>
                      Start
                    </Th>
                    <Th sort={getSortParams(4)} textCenter>
                      End
                    </Th>
                    {showLink && (
                      <Th textCenter>
                        <ThIcon />
                      </Th>
                    )}
                  </Tr>
                </Thead>
                <Tbody style={{ borderTop: '0' }}>
                  {showSkeleton
                    ? range(19).map(i => (
                        <Tr key={i}>
                          {showTurbine && <Td>{skeleton}</Td>}
                          <Td>{skeleton}</Td>
                          <Td>{skeleton}</Td>
                          <Td>{skeleton}</Td>
                          <Td>{skeleton}</Td>
                          {showLink && <Td>{skeleton}</Td>}
                        </Tr>
                      ))
                    : sortedEvents?.map(e => (
                        <Tr key={`${e.turb_id}_${e.ts_in}_${e.ts_fin}_${e.system_id}`}>
                          {showTurbine && (
                            <Td textCenter dataLabel='Turbine'>
                              {turbsMap.get(e.turb_id)?.name}
                            </Td>
                          )}
                          <Td textCenter dataLabel='System'>
                            {systemMap.get(e.system_id)?.system}
                          </Td>
                          <Td textCenter dataLabel='Alarm'>
                            {farol.get(`${e.alarm_level}`)}
                          </Td>
                          <Td textCenter dataLabel='Start'>
                            {e.ts_in ? formatDateWithoutTime(new Date(e.ts_in)) : ''}
                          </Td>
                          <Td textCenter dataLabel='End'>
                            {e.ts_fin ? formatDateWithoutTime(new Date(e.ts_fin)) : ''}
                          </Td>
                          {showLink && (
                            <Td textCenter dataLabel='Detalhes'>
                              <Link
                                target={'_blank'}
                                to={`/turbine-health/heatmap/${site.site.toLocaleLowerCase()}/${
                                  e.turb_id
                                }?system=${systemMap
                                  .get(e.system_id)
                                  ?.system.replace(' ', '+')}&${rangeParamsUrl(e.ts_in)}`}
                              >
                                <Icon title='Event details' color={ColorScale.cinza_medio}>
                                  <ExternalLinkAltIcon />
                                </Icon>
                              </Link>
                            </Td>
                          )}
                        </Tr>
                      ))}
                </Tbody>
              </Table>
              {events?.length === 0 && (
                <Empty title='Nenhum evento encontrado para o período, aerogerador e sistema selecionados.' />
              )}
            </InnerScrollContainer>
          </OuterScrollContainer>
        </div>
      </ExpandableSection>
    </Panel>
  )
}

export default EventsTable
