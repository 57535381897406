import {
  Button,
  DualListSelector,
  Form,
  FormGroup,
  Modal,
  ModalVariant,
  TextArea,
  TextInput,
} from '@patternfly/react-core'

import SelectListPermissions from '../../shared/selects/DeprecatedSelectListPermissions'

type ModalCreateGroupProps = {
  modalTitle: string
  isModalOpen: boolean
  handleModalToggle: () => void
  handleSubmit: () => Promise<void>
  groupNameValue: string
  handleGroupDescriptionInputChange: (value: string) => void
  handleGroupNameInputChange: (value: string) => void
  groupDescriptionValue: string
  availableMembersOptions: string[]
  chosenMembersOptions: string[]
  onMembersListChange: (
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) => void
  availablePagesOptions: string[]
  chosenPagesOptions: string[]
  onPagesListChange: (
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) => void
  setPermissionTypeSelected: React.Dispatch<React.SetStateAction<string | undefined>>
  permissionTypeSelected: string | undefined
}

function ModalCreateGroup(props: ModalCreateGroupProps) {
  const {
    isModalOpen,
    handleModalToggle,
    handleSubmit,
    groupNameValue,
    handleGroupDescriptionInputChange,
    handleGroupNameInputChange,
    groupDescriptionValue,
    availableMembersOptions,
    chosenMembersOptions,
    onMembersListChange,
    availablePagesOptions,
    onPagesListChange,
    chosenPagesOptions,
    permissionTypeSelected,
    setPermissionTypeSelected,
    modalTitle,
  } = props

  return (
    <Modal
      variant={ModalVariant.small}
      title={modalTitle}
      isOpen={isModalOpen}
      onClose={handleModalToggle}
      actions={[
        <Button key='create' variant='primary' form='modal-with-form-form' onClick={handleSubmit}>
          Enviar
        </Button>,
        <Button key='cancelar' variant='secondary' isDanger onClick={handleModalToggle}>
          Cancelar
        </Button>,
      ]}
    >
      <Form id='modal-with-form-form'>
        <div className='container-main'>
          <div className='container-input'>
            <FormGroup label='Grupo' isRequired fieldId='simple-form-group-name-01'>
              <TextInput
                isRequired
                type='text'
                id='simple-form-group-name-01'
                name='simple-form-group-name-01'
                placeholder='Grupo'
                value={groupNameValue}
                onChange={(_, value) => handleGroupNameInputChange(value)}
              />
            </FormGroup>
            <FormGroup label='Permissão' isRequired fieldId='simple-form-group-permission-01'>
              <SelectListPermissions
                permissionTypeSelected={permissionTypeSelected}
                setPermissionTypeSelected={setPermissionTypeSelected}
              />
            </FormGroup>
          </div>
          <FormGroup label='Descrição' fieldId='simple-form-group-description-01'>
            <TextArea
              id='group-description'
              defaultValue={groupDescriptionValue}
              value={groupDescriptionValue}
              onChange={(_, value) => handleGroupDescriptionInputChange(value)}
              aria-label='text area group description'
            />
          </FormGroup>
        </div>

        <DualListSelector
          availableOptionsTitle='Adicionar membros'
          availableOptions={availableMembersOptions}
          chosenOptionsTitle='Membros adicionados'
          chosenOptions={chosenMembersOptions}
          onListChange={(evnt, newAvailableOptions, newChosenOptions) =>
            onMembersListChange(evnt, newAvailableOptions as string[], newChosenOptions as string[])
          }
          id='basicSelector'
        />
        <DualListSelector
          availableOptionsTitle='Vincular páginas'
          availableOptions={availablePagesOptions}
          chosenOptionsTitle='Páginas vinculadas'
          chosenOptions={chosenPagesOptions}
          onListChange={(evnt, newAvailableOptions, newChosenOptions) =>
            onPagesListChange(evnt, newAvailableOptions as string[], newChosenOptions as string[])
          }
          id='basicSelector'
        />
      </Form>
    </Modal>
  )
}

export default ModalCreateGroup
