import { Button } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import { CivilOcorrencia, OccurrencesAPI } from '../../client'
import PermissionContext from '../../contexts/PermissionContext'
import SitesContext from '../../contexts/SitesContext'
import useVertical from '../../hooks/useVertical'
import { queryClient } from '../../services/api'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import PageFrame from '../shared/Page'
import SelectListSites from '../shared/selects/DeprecatedSelectListSites'
import { ModalAdicionarOcorrencias } from './ModalAdicionarOcorrencias'
import { ModalEditarOcorrencia } from './ModalEditarOcorrencia'
import { TableListOcorrencias } from './TableListOcorrencias'

export type StatusResponseType = 'inProgress' | 'success' | 'danger' | 'error' | ''

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 30,
}

const fetchData = async (siteId?: number) => {
  if (!siteId) return
  return OccurrencesAPI.getAll({ siteId })
}

const OcorrenciasPage = () => {
  const isVertical = useVertical()
  const [isNavOpen, setNavOpen] = useState(!isVertical)
  const { site, sites, isLoading: sitesIsLoading } = useContext(SitesContext)
  const { me, isLoading: permIsLoading } = useContext(PermissionContext)
  const [ocorrencia, setOcorrencia] = useState<CivilOcorrencia | null>(null)
  const [isOpenModelAdicionarOcorrencia, setIsOpenModelAdicionarOcorrencia] = useState(false)

  const [isOpenModalEditarOcorrencia, setIsOpenModalEditarOcorrencia] = useState(false)

  const { isLoading: ocorrenciasIsLoading, data: ocorrencias } = useQuery(
    ['occurrences', site?.site_id],
    async () => fetchData(site?.site_id),
    { ...defaultQueryOptions, enabled: !!site }
  )

  const prefetch = async (site_id?: number) => {
    if (!site_id) return

    await queryClient.prefetchQuery(
      ['occurrences', site_id],
      async () => fetchData(site_id),
      defaultQueryOptions
    )
  }

  const isLoading = ocorrenciasIsLoading || permIsLoading || sitesIsLoading

  useEffect(() => {
    if (isLoading || !site?.site_id) return
    sites.filter(s => s.site_id !== site?.site_id).forEach(s => prefetch(s.site_id))
  }, [isLoading])

  const authorized = me?.authorized || []
  const pagesPerms = authorized.map(p => p.page_name)
  const canEdit = pagesPerms.includes('Ocorrências Admin')

  const filters = canEdit
    ? [
        <SelectListSites key='select_sites' />,
        <Button
          key='adicionar-ocorrencia'
          variant='control'
          className='pf-v5-u-m-sm'
          onClick={() => setIsOpenModelAdicionarOcorrencia(true)}
        >
          Adicionar
        </Button>,
      ]
    : [<SelectListSites key='select_sites' />]

  const heightPage = calcVerticalHeight({
    gridSize: 1,
    elementSize: 1,
    pgHasFilter: true,
    customOffset: '2rem',
  })
  const style = { width: '100%', maxHeight: heightPage, overflow: 'auto', minHeight: '10rem' }

  return (
    <PageFrame
      pageName='Ocorrências'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      siteName={site?.site_name}
      siteId={site?.site_id}
      filters={filters}
    >
      {!isLoading && (
        <>
          <TableListOcorrencias
            styled={style}
            ocorrencias={ocorrencias}
            setOcorrencia={setOcorrencia}
            setIsOpenModalEditarOcorrencia={setIsOpenModalEditarOcorrencia}
            canEdit={canEdit}
            site={site}
          />
          <ModalAdicionarOcorrencias
            isOpenModelAdicionarOcorrencia={isOpenModelAdicionarOcorrencia}
            setIsOpenModelAdicionarOcorrencia={setIsOpenModelAdicionarOcorrencia}
            site={site}
          />
          {ocorrencia && (
            <ModalEditarOcorrencia
              isOpenModalEditarOcorrencia={isOpenModalEditarOcorrencia}
              setIsOpenModalEditarOcorrencia={setIsOpenModalEditarOcorrencia}
              ocorrencia={ocorrencia}
              setOcorrencia={setOcorrencia}
              site={site}
            />
          )}
        </>
      )}
    </PageFrame>
  )
}

export default OcorrenciasPage
