import { Alert, FileUpload } from '@patternfly/react-core'
import { useState } from 'react'

import SkeletonImage from '../shared/SkeletonImage'

const EditImage = ({
  imgPath,
  setFileImage,
  newImageUrl,
  setNewImageUrl,
}: {
  imgPath: string
  setFileImage: (file: any) => void
  newImageUrl: string
  setNewImageUrl: (url: string) => void
}) => {
  const [filename, setFilename] = useState('')
  const [isRejectedFile, setIsRejectedFile] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isImgLoading, setIsImgLoading] = useState(true)

  const handleFileInputChange = (_event: any, file: any) => {
    setFilename(file.name)
    setFileImage(file)
    setNewImageUrl(URL.createObjectURL(file))
    setIsRejectedFile(false)
  }

  const handleClear = (_event: any) => {
    setFilename('')
    setNewImageUrl('')
    setIsRejectedFile(false)
  }

  const handleFileReadStarted = () => {
    setIsLoading(true)
  }
  const handleFileReadFinished = () => {
    setIsLoading(false)
  }

  const handleFileRejected = () => {
    setIsRejectedFile(true)
  }

  const styleImg = {
    display: isImgLoading ? 'none' : 'block',
  }

  return (
    <>
      <FileUpload
        id='foto-ocorrencia'
        filename={filename}
        filenamePlaceholder='Arraste e solte a foto ou carregue um'
        onFileInputChange={handleFileInputChange}
        onReadFinished={handleFileReadFinished}
        onReadStarted={handleFileReadStarted}
        onClearClick={handleClear}
        clearButtonText='Desfazer'
        hideDefaultPreview
        isLoading={isLoading}
        browseButtonText='Trocar foto'
        validated={isRejectedFile ? 'error' : 'default'}
        dropzoneProps={{
          accept: { 'image/jpeg': ['.jpg'], 'image/png': ['.png'] },
          onDropRejected: handleFileRejected,
        }}
      />
      <div style={{ width: '100%', height: 300, paddingTop: '15px' }}>
        {isImgLoading && <SkeletonImage />}
        <img
          src={newImageUrl ?? imgPath}
          alt='Foto da Ocorrência'
          width={'100%'}
          height={'100%'}
          style={styleImg}
          onLoad={() => setIsImgLoading(false)}
        />
        {isRejectedFile && <Alert variant='danger' isInline isPlain title='Formato inválido!' />}
      </div>
    </>
  )
}

export default EditImage
