import { Button, Panel, Stack, StackItem, Tab, Tabs, TabTitleText } from '@patternfly/react-core'
import {
  InnerScrollContainer,
  OuterScrollContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { range } from 'd3-array'
import { useContext, useMemo, useState } from 'react'
import { DowntimeAero, DowntimeBop, DowntimeONS, Masts } from '../../client'
import Select from '../../components/selects/Select'
import MastContext, { MastProvider, Sensors } from '../../contexts/MastContext'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import ColorScale, { COLOR_SCALES } from '../../utils/colorScale'
import { formatDateTimeBrl } from '../../utils/formatDate'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import { getFilterParams } from '../../utils/url'
import useData from '../paradas-restricoes/useData'
import Page from '../shared/Page'
import Plot from '../shared/Plot'

const GRID_SIZE = 6
const TABS_HEIGHT = 2
const TOOLBAR_HEIGHT = 2
const TABLE_HEIGHT = 2
const GUTTER_HEIGHT = 2
const FOOTER_HEIGHT = 0.5

const TableSample = ({ mode }: { mode: number }) => {
  const heightOuterScrollContainer = useMemo(() => {
    const h = calcVerticalHeight({
      elementSize: GRID_SIZE / 2 - mode,
      gridSize: GRID_SIZE,
    })
    // Offset tem que ser fora, calcVerticalHeight distribui igualmente o offset entre os elementos
    const customOffset = TABS_HEIGHT + TOOLBAR_HEIGHT + TABLE_HEIGHT + FOOTER_HEIGHT + GUTTER_HEIGHT
    const s = `calc(${h} - ${customOffset}rem)`

    return s
  }, [mode])

  return (
    // [ATENÇÃO] Não remover backgroundColor - Permite ver qual componente está vazando
    <div style={{ height: heightOuterScrollContainer, backgroundColor: 'orange' }}>
      {/* OuterScrollContainer não deve ser incluído */}
      <InnerScrollContainer style={{ height: heightOuterScrollContainer, backgroundColor: 'pink' }}>
        <Table
          aria-label='Table Paradas Aero Delete'
          variant='compact'
          isStriped
          isExpandable
          isStickyHeader
          style={{ height: heightOuterScrollContainer, backgroundColor: 'lightblue' }}
        >
          <Thead>
            <Tr>
              <Th style={{ width: '2rem' }}>Id</Th>
              <Th>WTG</Th>
              <Th>Usuário</Th>
              <Th>Início</Th>
              <Th>Fim</Th>
            </Tr>
          </Thead>
          <Tbody>
            {range(25).map(() => (
              <Tr>
                <Td dataLabel='Id'>EXEMPLE</Td>
                <Td dataLabel='Id'>EXEMPLE</Td>
                <Td dataLabel='Id'>EXEMPLE</Td>
                <Td dataLabel='Id'>EXEMPLE</Td>
                <Td dataLabel='Id'>EXEMPLE</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </InnerScrollContainer>
    </div>
  )
}

const PanelTimeline = () => {
  return (
    <Stack className='pf-v5-u-p-sm' style={{ backgroundColor: 'yellow' }}>
      <StackItem>RESTRIÇÕES</StackItem>
      <StackItem isFilled style={{ overflow: 'scroll' }}>
        <div style={{ height: '100vh' }}>smth</div>
      </StackItem>
      <StackItem>EIXO X DATAS</StackItem>
    </Stack>
  )
}

const PanelTabs = ({ mode }: { mode: number }) => {
  const [activeTabKey, setActiveTabKey] = useState()

  return (
    <Panel className='pf-v5-u-p-sm' style={{ backgroundColor: 'gray' }}>
      <Tabs id='paradas-restricoes-main-tabs' activeKey={activeTabKey} variant='default' isBox>
        <Tab eventKey={0} title={<TabTitleText>Paradas BOP</TabTitleText>}>
          <TableSample mode={mode} />
        </Tab>
        <Tab eventKey={1} title={<TabTitleText>Paradas Aero</TabTitleText>}>
          {/* <TableAero
            selectedDate={selectedDate}
            downtimeAero={downtimeAero}
            componentes={componentes}
            tipos={tipos}
            resize={resize}
            ResizeComponent={<ResizeButtons setResize={setResize} resize={resize} />}
          /> */}
        </Tab>
        <Tab eventKey={2} title={<TabTitleText>Restrições ONS</TabTitleText>}>
          {/* <TableRestricoes
                    selectedDate={selectedDate}
                    downtimeOns={downtimeOns}
                    resize={resize}
                    ResizeComponent={<ResizeButtons setResize={setResize} resize={resize} />}
                  /> */}
        </Tab>
        <Tab eventKey={3} title={<TabTitleText>Curva de Potência</TabTitleText>}>
          {/* <TablePCReclassification
                    pcReclassification={pcReclassification}
                    selectedDate={selectedDate}
                    resize={resize}
                    ResizeComponent={<ResizeButtons setResize={setResize} resize={resize} />}
                  /> */}
        </Tab>
      </Tabs>
    </Panel>
  )
}

const ComponentTest = () => {
  const { mast, allMasts, setActiveMast } = useContext(MastContext)
  const [isNavOpen, setNavOpen] = useState(true)
  const [mode, setMode] = useState(0)

  const heightTop = useMemo(() => {
    return calcVerticalHeight({ elementSize: GRID_SIZE / 2 + mode, gridSize: GRID_SIZE })
  }, [mode])
  const heightBottom = useMemo(() => {
    return calcVerticalHeight({ elementSize: GRID_SIZE / 2 - mode, gridSize: GRID_SIZE })
  }, [mode])

  return (
    <Page
      pageName='Component Test'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isContentLoading={false}
      isLoading={false}
      filters={[
        <Select<Masts>
          className='pf-u-v5-m-none'
          key='filter-mast'
          itemKeyName='id'
          itemValueName='name'
          selected={mast}
          items={allMasts}
          onChange={setActiveMast}
        />,
        <Button onClick={() => setMode(1)}>25</Button>,
        <Button onClick={() => setMode(0)}>50</Button>,
        <Button onClick={() => setMode(-1)}>75</Button>,
      ]}
    >
      <Stack
        hasGutter
        style={{
          backgroundColor: '#489ef4',
          height: calcVerticalHeight({ gridSize: GRID_SIZE, elementSize: GRID_SIZE }),
        }}
        className='pf-v5-u-box-shadow-md'
      >
        <StackItem isFilled style={{ backgroundColor: 'green', height: heightTop }}>
          <PanelTimeline />
        </StackItem>
        <StackItem isFilled style={{ backgroundColor: 'red', height: heightBottom }}>
          <PanelTabs mode={mode} />
        </StackItem>
      </Stack>
    </Page>
  )
}

const Wrapper = () => (
  <MastProvider>
    <ComponentTest />
  </MastProvider>
)

export default Wrapper
