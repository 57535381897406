import {
  Bullseye,
  Button,
  Divider,
  EmptyState,
  EmptyStateBody,
  EmptyStateFooter,
  EmptyStateHeader,
  EmptyStateIcon,
  EmptyStateVariant,
  Panel,
  PanelHeader,
  PanelMain,
} from '@patternfly/react-core'
import { CheckIcon, CloseIcon, EditIcon, SearchIcon } from '@patternfly/react-icons'
import { InnerScrollContainer, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { Table } from '@patternfly/react-table/deprecated'
import { useState } from 'react'
import { SettingsTurbineMap, Site, Turbines } from '../../client'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import ColorScale from '../../utils/colorScale'
import ModalTurbs from './ModalSettingsTurbs'

const DisabledIcon = () => <CloseIcon />
const EnabledIcon = () => <CheckIcon />

const panelStyle = {
  height: 'fit-content',
  minHeight: '15rem',
  background: ColorScale.branco,
}

const heightPage = calcVerticalHeight({
  gridSize: 1,
  elementSize: 1,
  pgHasFilter: true,
  customOffset: '2rem',
})

const styleInnerScroll = {
  width: '100%',
  maxHeight: heightPage,
  overflow: 'auto',
  minHeight: '10rem',
}

const TableTurbs = ({
  turbsMap,
  isLoading,
  setSettingTurb,
  filteredSettings,
  inputValue,
  setInputValue,
  settingTurb,
  updateTurb,
  site,
}: {
  turbsMap: Map<number, Turbines>
  isLoading: boolean
  setSettingTurb: React.Dispatch<React.SetStateAction<SettingsTurbineMap | undefined>>
  filteredSettings: SettingsTurbineMap[] | undefined
  inputValue: string
  settingTurb: SettingsTurbineMap | undefined
  setInputValue: React.Dispatch<React.SetStateAction<string>>
  updateTurb: (value: SettingsTurbineMap) => void
  site: Site
}) => {
  const [isModalUpdateOpen, setModalUpdateOpen] = useState(false)

  function closeModal() {
    setModalUpdateOpen(false)
  }

  const handleModalUpdateOpen = (item: SettingsTurbineMap) => {
    setSettingTurb(item)
    setModalUpdateOpen(!isModalUpdateOpen)
  }

  if (isLoading) return null

  return (
    <div className='pf-v5-u-box-shadow-md'>
      <Panel style={panelStyle}>
        <PanelHeader>
          <b>Mapa - Aerogeradores {site.site_name}</b>
        </PanelHeader>
        <Divider />
        <PanelMain className='container-table'>
          <InnerScrollContainer style={styleInnerScroll}>
            <Table
              aria-label='Expandable table'
              variant='compact'
              isStriped
              isExpandable
              isStickyHeader
            >
              <Thead>
                <Tr>
                  <Th className='pf-m-wrap'>Aerogerador</Th>
                  <Th className='pf-m-wrap'>Posição</Th>
                  <Th className='pf-m-wrap'>Visível</Th>
                  <Th className='pf-m-wrap'>Editar</Th>
                </Tr>
              </Thead>
              {filteredSettings?.length === 0 && (
                <Tbody>
                  <Tr>
                    <Td colSpan={12}>
                      <Bullseye>
                        <EmptyState variant={EmptyStateVariant.sm}>
                          <EmptyStateHeader
                            titleText='Nenhum registro encontrado.'
                            icon={<EmptyStateIcon icon={SearchIcon} />}
                            headingLevel='h3'
                          />
                          <EmptyStateFooter>
                            {inputValue && (
                              <>
                                <EmptyStateBody>Limpe o filtro e tente novamente.</EmptyStateBody>
                                <Button variant='link' onClick={() => setInputValue('')}>
                                  Limpar filtro
                                </Button>
                              </>
                            )}
                          </EmptyStateFooter>
                        </EmptyState>
                      </Bullseye>
                    </Td>
                  </Tr>
                </Tbody>
              )}
              {Number(filteredSettings?.length) > 0 &&
                filteredSettings?.map((el: SettingsTurbineMap) => {
                  const turbEl = turbsMap.get(el.turb_id)
                  return (
                    <Tbody key={el?.turb_id}>
                      <Tr>
                        <Td dataLabel='Aerogerador'>{turbEl?.name}</Td>
                        <Td dataLabel='Posição'>{el.position}</Td>
                        <Td dataLabel='Visível'>
                          {el.visible ? <EnabledIcon /> : <DisabledIcon />}
                        </Td>
                        <Td dataLabel='Editar'>
                          <div>
                            <EditIcon
                              onClick={() => handleModalUpdateOpen(el)}
                              style={{ cursor: 'pointer' }}
                            />{' '}
                          </div>
                        </Td>
                      </Tr>
                    </Tbody>
                  )
                })}
            </Table>
          </InnerScrollContainer>
        </PanelMain>
        <ModalTurbs
          isModalOpen={isModalUpdateOpen}
          turbsMap={turbsMap}
          isLoading={isLoading}
          settingTurb={settingTurb}
          updateTurb={updateTurb}
          onCloseModal={closeModal}
          onlyEditable={true}
        />
      </Panel>
    </div>
  )
}

export default TableTurbs
