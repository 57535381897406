import {
  Alert,
  Bullseye,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalVariant,
  Spinner,
  Split,
  SplitItem,
  TextArea,
  TextInput,
  ValidatedOptions,
} from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import { AvailabilityAPI, DowntimeAPI, DowntimeONS } from '../../client'
import { ActionState } from '../../components/actionState'
import DatetimeRangePicker from '../../components/calendar/DatetimeRangePicker'
import Select from '../../components/selects/Select'
import PermissionContext from '../../contexts/PermissionContext'
import SitesContext from '../../contexts/SitesContext'
import { queryClient } from '../../services/api'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import { toISOString } from '../../utils/formatDate'
import PlotExpectedEnergy from '../shared/PlotExpectedEnergy'
import { CACHE_KEY_ONS } from './constants'
import { keyFromDate } from './useData'

const REQUEST_STATE_MESSAGES = {
  idle: <></>,
  pending: <Alert variant='info' title='Processando' isInline />,
  success: <Alert variant='success' title='Salvo com sucesso' isInline />,
  failure: <Alert variant='danger' title='Ocorreu um erro ao processar o evento' isInline />,
}

interface TipoRestricao {
  tipo_restricao: string
  sigla: string
}
const tipos_restricao: TipoRestricao[] = [
  { tipo_restricao: 'Ativa', sigla: 'A' },
  { tipo_restricao: 'Reativa', sigla: 'R' },
]

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
}

interface PeriodStateInterface {
  startDate: Date
  endDate: Date
}

const PLOT_STYLE = { width: '100%', height: calcVerticalHeight({ customOffset: 3 }) }

const QUERY_KEY = 'expected_energy'

const formatDate = (date: Date) => date.toISOString().split('T')[0]

const get_expected_energy = ({
  siteId,
  startDate,
  endDate,
}: {
  siteId: number
  startDate: Date
  endDate: Date
}) => {
  const handleStartDay =
    endDate.getTime() - startDate.getTime() < 1000 * 60 * 60 * 24 ? 0 : 1000 * 60 * 60 * 24

  const removeDay = new Date(startDate.getTime() - handleStartDay)
  const addDay = new Date(endDate)
  addDay.setDate(endDate.getDate() + 1)

  return AvailabilityAPI.getEnergyExpected({
    siteId,
    tsIn: formatDate(removeDay),
    tsFin: formatDate(addDay),
  })
}

const ModalRestricao = ({
  isOpen,
  onClose,
  dataToUpdate,
  selectedDate,
  handleDeleteClick,
  modalConfirmation,
}: {
  isOpen: boolean
  onClose: () => void
  dataToUpdate: DowntimeONS | null
  selectedDate: Date
  handleDeleteClick?: (item: number) => void
  modalConfirmation: React.ReactNode
}) => {
  const modalType = dataToUpdate ? 'update' : 'create'
  const isModalUpdate = modalType === 'update'

  const { me } = useContext(PermissionContext)
  const { site, isLoading: sitesIsLoading } = useContext(SitesContext)

  const minDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)
  const maxDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0)

  const defaultPeriod = { tsIn: minDate, tsFin: maxDate }

  const plotTsIn = !dataToUpdate
    ? new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate())
    : new Date(dataToUpdate.ts_in)

  const plotTsFin = !dataToUpdate
    ? new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate())
    : new Date(dataToUpdate.ts_fin)

  const [period, setPeriod] = useState<PeriodStateInterface>({
    startDate: plotTsIn,
    endDate: plotTsFin,
  })

  const queryOptions = { ...defaultQueryOptions, enabled: !!site?.site_id }

  const { data, isLoading } = useQuery(
    [QUERY_KEY, site?.site_id, JSON.stringify(period)],
    async () => get_expected_energy({ siteId: site?.site_id, ...period }),
    queryOptions
  )

  const { site: expectedEnergySite } = data ?? {}

  const [tsIn, setTsIn] = useState<Date>(
    dataToUpdate?.ts_in ? new Date(dataToUpdate.ts_in) : defaultPeriod.tsIn
  )
  const [tsFin, setTsFin] = useState<Date>(
    dataToUpdate?.ts_fin ? new Date(dataToUpdate.ts_fin) : defaultPeriod.tsFin
  )
  const [restrictionType, setRestrictionType] = useState<TipoRestricao>(tipos_restricao[0])
  const [onsOperator, setOnsOperator] = useState(dataToUpdate?.operador_ons ?? '')
  const [demandedPower, setDemandedPower] = useState<number | null>(
    dataToUpdate?.potencia_solicitada ?? 0
  )
  const [reason, setReason] = useState(dataToUpdate?.motivo ?? '')

  const [isError406, setIsError406] = useState(false)
  const [isDemandedPowerValid, setIsDemandedPowerValid] = useState(true)
  const [alertActive, setAlertActive] = useState(false)
  const [requestState, setRequestState] = useState<ActionState>('idle')

  const handleSubmit = async () => {
    if (onsOperator === '' || !demandedPower || reason === '' || tsIn > tsFin) {
      setAlertActive(true)
      return
    }
    setRequestState('pending')
    const requestBody = {
      ts_in: toISOString(tsIn),
      ts_fin: toISOString(tsFin),
      site_id: site.site_id,
      username: me.current_user.username,
      id: dataToUpdate?.id,
      tipo_restricao: restrictionType.sigla,
      potencia_solicitada: demandedPower,
      operador_ons: onsOperator,
      motivo: reason,
    }
    const requestMethod = dataToUpdate
      ? DowntimeAPI.updateDowntimeOns
      : DowntimeAPI.createDowntimeOns

    try {
      await requestMethod({ siteId: site.site_id, requestBody })
    } catch (error: any) {
      console.log(error.status)
      setIsError406(error.status === 406)
      setRequestState('failure')
      setAlertActive(true)
      return
    }
    setRequestState('success')

    queryClient.invalidateQueries([CACHE_KEY_ONS, site.site_id, keyFromDate(selectedDate, 1)])
  }

  function handleDemandedPowerChange(e: any) {
    console.log(e)
    const input = e.target.value
    if (input.match(/^(\d+)(\.)?(\d+)?$/)) {
      setDemandedPower(input)
      setIsDemandedPowerValid(true)
    } else {
      setIsDemandedPowerValid(false)
    }
  }
  const closeModal = () => {
    setTsIn(defaultPeriod.tsIn)
    setTsFin(defaultPeriod.tsFin)
    setAlertActive(false)
    setIsError406(false)
    setRequestState('idle')
    setPeriod({ startDate: tsIn, endDate: tsFin })
    onClose()
  }

  useEffect(() => {
    if (requestState === 'success') {
      const timerCloseModalAero = setTimeout(() => {
        closeModal()
      }, 750)
      return () => clearTimeout(timerCloseModalAero)
    }
  }, [requestState])

  useEffect(() => {
    setAlertActive(false)
    setRequestState('idle')
  }, [tsIn, tsFin, restrictionType, onsOperator, demandedPower, reason])

  useEffect(() => {
    if (!dataToUpdate) {
      setRestrictionType(tipos_restricao[0])
      setOnsOperator('')
      setDemandedPower(null)
      setReason('')
      setTsIn(defaultPeriod.tsIn)
      setTsFin(defaultPeriod.tsFin)
      return
    }

    setRestrictionType(
      tipos_restricao.find(t => t.sigla === dataToUpdate?.tipo_restricao) ?? tipos_restricao[0]
    )
    setOnsOperator(dataToUpdate?.operador_ons ?? '')
    setDemandedPower(dataToUpdate?.potencia_solicitada ?? null)
    setReason(dataToUpdate?.motivo ?? '')
    setTsIn(new Date(dataToUpdate.ts_in))
    setTsFin(new Date(dataToUpdate.ts_fin))
    setPeriod({ startDate: new Date(dataToUpdate.ts_in), endDate: new Date(dataToUpdate.ts_fin) })
  }, [dataToUpdate, sitesIsLoading, isOpen])

  const [plotRevision, setPlotRevision] = useState(0)

  useEffect(() => {
    setPlotRevision(plotRevision + 1)
  }, [data])

  return (
    <Modal
      variant={ModalVariant.large}
      title='Inclusão de Restrição ONS'
      isOpen={isOpen}
      onClose={onClose}
      className='downtime-modal'
      id='modalRestricao'
      width='95%'
      actions={[
        <div style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
          <Split hasGutter>
            <SplitItem>
              <Button
                key='create'
                variant='primary'
                form='modal-with-form-form'
                onClick={handleSubmit}
                className='green'
              >
                {isModalUpdate ? 'Salvar' : 'Criar evento'}
              </Button>
            </SplitItem>
            <SplitItem>
              <Button key='cancel' variant='secondary' isDanger onClick={onClose}>
                Cancelar
              </Button>
            </SplitItem>
            <SplitItem isFilled>
              {REQUEST_STATE_MESSAGES[requestState]}
              {alertActive && (
                <GridItem lg={12}>
                  {tsIn > tsFin && (
                    <Alert
                      variant='danger'
                      className='pf-u-my-sm'
                      isInline
                      title='Data inicial deve ser menor que data final'
                    />
                  )}
                  {onsOperator === '' && (
                    <Alert
                      variant='danger'
                      className='pf-u-my-sm'
                      isInline
                      title='Preencha o campo de Operador ONS'
                    />
                  )}
                  {!demandedPower && (
                    <Alert
                      variant='danger'
                      className='pf-u-my-sm'
                      isInline
                      title='Preencha o campo de Potência Demandada'
                    />
                  )}
                  {reason === '' && (
                    <Alert
                      variant='danger'
                      className='pf-u-my-sm'
                      isInline
                      title='Preencha o campo de Motivo'
                    />
                  )}
                  {isError406 && (
                    <Alert
                      variant='danger'
                      isInline
                      className='pf-u-my-sm'
                      title='Já existe um evento com essas características'
                    />
                  )}
                </GridItem>
              )}
            </SplitItem>
          </Split>
          {handleDeleteClick && modalType === 'update' && (
            <Button
              variant='danger'
              isDanger
              onClick={() => {
                handleDeleteClick(dataToUpdate?.id as number)
              }}
            >
              Deletar
            </Button>
          )}
        </div>,
      ]}
    >
      {modalConfirmation}
      <Grid hasGutter>
        <GridItem md={12} lg={5}>
          <Grid hasGutter style={{ padding: '10px' }}>
            <GridItem lg={5}>
              <span>Tipo de restrição</span>
              <Select<TipoRestricao>
                items={tipos_restricao}
                itemValueName={'tipo_restricao'}
                itemKeyName={'sigla'}
                selected={restrictionType}
                selectProps={{ menuAppendTo: 'parent' }}
                onChange={setRestrictionType}
              />
            </GridItem>

            <GridItem lg={7}>
              <span>Período</span>
              <DatetimeRangePicker
                value={{ startDate: tsIn, endDate: tsFin }}
                onChange={(newStart: Date, newEnd: Date) => {
                  setTsIn(newStart)
                  setTsFin(newEnd)
                  setPeriod({ startDate: newStart, endDate: newEnd })
                }}
                variant='label'
                calendarVariant='datetime'
                minEnabled={minDate}
                maxEnabled={maxDate}
                allowEqualDates
              />
            </GridItem>

            <GridItem lg={12}>
              <span>Nome do operador ONS</span>
              <GridItem className='pf-v5-u-p-sm'>
                <TextInput
                  className='pf-u-m-sm'
                  placeholder='Operador ONS'
                  height={100}
                  type='text'
                  aria-label='ons operator input field'
                  value={onsOperator}
                  onChange={(_event, val) => setOnsOperator(val)}
                  validated={
                    onsOperator === '' && alertActive
                      ? ValidatedOptions.error
                      : ValidatedOptions.default
                  }
                />
              </GridItem>
            </GridItem>

            <GridItem lg={12}>
              <span>Potência Demandada [MW ou MVAr]</span>
              <GridItem className='pf-v5-u-m-sm'>
                <TextInput
                  className='pf-u-m-sm'
                  validated={isDemandedPowerValid ? undefined : ValidatedOptions.default}
                  placeholder='Potência Demandada [MW ou MVAr]'
                  height={100}
                  type='number'
                  aria-label='demanded power input field'
                  value={demandedPower ?? ''}
                  onChange={e => handleDemandedPowerChange(e)}
                />
              </GridItem>
            </GridItem>

            <GridItem lg={12}>
              <span>Motivo</span>
              <TextArea
                validated={reason === '' && alertActive ? ValidatedOptions.error : undefined}
                placeholder='Motivo'
                type='text'
                aria-label='reason input field'
                value={reason}
                onChange={(_event, val) => setReason(val)}
                style={{
                  maxHeight: '20rem',
                  height: calcVerticalHeight({ customOffset: 20, pgHasFilter: false }),
                }}
              />
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem md={12} lg={7}>
          {isLoading && (
            <Bullseye style={PLOT_STYLE}>
              <div>
                <Spinner />
              </div>
            </Bullseye>
          )}
          {!!expectedEnergySite && (
            <PlotExpectedEnergy
              expectedEnergySite={expectedEnergySite}
              revision={plotRevision}
              style={PLOT_STYLE}
              tsIn={period.startDate}
              tsFin={period.endDate}
            />
          )}
        </GridItem>
      </Grid>
    </Modal>
  )
}

export default ModalRestricao
