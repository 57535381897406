import { Button } from '@patternfly/react-core'
import { EditIcon, Remove2Icon } from '@patternfly/react-icons'
import {
  Caption,
  Table /* data-codemods */,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useContext, useState } from 'react'

import SitesContext from '../../contexts/SitesContext'
import api, { queryClient as qq } from '../../services/api'
import ModalConfirmationWarning from '../shared/DeprecatedModalConfirmationWarning'
import PageFrame from '../shared/Page'
import ModalPageMaintenance from './ModalPageMaintenance'

const queryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
}

const mudateOptions = {
  onSuccess: () => {
    qq.invalidateQueries('maintenancePages')
  },
}

function PagManutencao() {
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)
  const { sites, isLoading: sitesLoading } = useContext(SitesContext)

  const { isLoading: maintenanceIsLoading, data: maintenance } = useQuery(
    ['maintenancePages'],
    () => api.get('/admin/pages/maintenance').then(r => r.data),
    queryOptions
  )

  const { isLoading: pagesIsLoading, data: pages } = useQuery(
    ['pages'],
    () => api.get('/admin/pages').then(r => r.data),
    queryOptions
  )

  const isLoading = sitesLoading || maintenanceIsLoading || pagesIsLoading

  const [description, setDescription] = useState('')
  const [selectedSite, setSelectedSite] = useState(1)
  const [selectedPage, setSelectedPage] = useState('')
  const [selectedMainId, setSelectedMainId] = useState()
  const [isModalOpen, setModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [modalType, setModalType] = useState('create')

  const handleModalToggle = ({
    desc = '',
    selSite = 1,
    selPage = '',
    mdlType = 'create',
    selPM,
  }) => {
    setModalType(mdlType)
    setDescription(desc)
    setSelectedSite(selSite)
    setSelectedPage(selPage)
    setSelectedMainId(selPM)
    setModalOpen(!isModalOpen)
  }

  const handleEdit = useMutation(maint => api.put('/admin/pages/maintenance', maint), mudateOptions)
  const handleCreate = useMutation(
    newMaint => api.post('/admin/pages/maintenance', newMaint),
    mudateOptions
  )
  const handleDelete = useMutation(
    ({ pm_id }) => api.delete(`/admin/pages/maintenance/${pm_id}`),
    mudateOptions
  )

  const modalInfo = {
    edit: {
      title: 'Editar',
      callback: handleEdit,
      startSeleted: {
        page: pages?.find(p => p.page_id === selectedPage)?.page_id,
        site: selectedSite,
      },
    },
    create: {
      title: 'Criar',
      callback: handleCreate,
      startSeleted: { page: '', site: '' },
    },
  }[modalType]

  return (
    <PageFrame
      pageName='Páginas em Manutenção'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      filters={[
        <Button
          key='criar-manutencao'
          variant='control'
          className='pf-v5-u-m-sm'
          onClick={handleModalToggle}
        >
          Criar Manutenção
        </Button>,
      ]}
    >
      <Table variant='compact' aria-label='Page Maint' isStickyHeader>
        <Caption>Páginas</Caption>
        <Thead>
          <Tr>
            <Th>Página</Th>
            <Th>Site</Th>
            <Th>Descrição</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>

        <Tbody>
          {maintenance?.map((p, idx) => (
            <Tr key={idx}>
              <Td dataLabel='Página'>{p.page_name}</Td>
              <Td dataLabel='Site'>{p.site_name}</Td>
              <Td dataLabel='Descrição'>{p.description}</Td>
              <Td dataLabel='Ações'>
                <div className='column-icons'>
                  <span className='container-icon'>
                    <EditIcon
                      onClick={() => {
                        handleModalToggle({
                          desc: p.description,
                          selSite: p.site_id,
                          mdlType: 'edit',
                          selPage: p.page_id,
                          selPM: p.pm_id,
                        })
                      }}
                    />
                  </span>
                  <span className='container-icon'>
                    <Remove2Icon
                      onClick={() => {
                        setSelectedMainId(p.pm_id)
                        setIsDeleteModalOpen(true)
                      }}
                    />
                  </span>
                </div>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <ModalPageMaintenance
        isModalOpen={isModalOpen}
        modalInfo={modalInfo}
        pages={pages}
        sites={sites}
        handleModalToggle={handleModalToggle}
        description={description}
        setDescription={setDescription}
        selectedSite={selectedSite}
        setSelectedPage={setSelectedPage}
        selectedPage={selectedPage}
        setSelectedSite={setSelectedSite}
        handleEdit={handleEdit}
        handleCreate={handleEdit}
        selectedMainId={selectedMainId}
        setSelectedMainId={setSelectedMainId}
        setModalOpen={setModalOpen}
      />
      <ModalConfirmationWarning
        title='Remover Manutenção'
        isModalOpen={isDeleteModalOpen}
        handleModalToggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        handleSubmit={() => handleDelete.mutate({ pm_id: selectedMainId })}
      />
    </PageFrame>
  )
}

export default PagManutencao
