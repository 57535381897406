import { useContext, useEffect, useMemo, useState } from 'react'

import {
  Alert,
  Button,
  EmptyState,
  EmptyStateHeader,
  EmptyStateVariant,
  Flex,
  FlexItem,
  Grid,
  GridItem,
  Modal,
  ModalVariant,
  Split,
  SplitItem,
  Stack,
  StackItem,
  TextArea,
} from '@patternfly/react-core'
import { SelectVariant } from '@patternfly/react-core/deprecated'
import {
  InnerScrollContainer,
  OuterScrollContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { Table } from '@patternfly/react-table/deprecated'
import { subset } from 'd3-array'

import { useQuery } from '@tanstack/react-query'
import {
  Circuit,
  DowntimeAPI,
  DowntimeBop,
  DowntimeSetor,
  DowntimeTipos,
  SitesAPI,
  Transformer,
  Turbines,
} from '../../client'
import { ActionState } from '../../components/actionState'
import DatetimeRangePicker from '../../components/calendar/DatetimeRangePicker'
import MultiSelect from '../../components/selects/MultiSelect'
import Select from '../../components/selects/Select'
import PermissionContext from '../../contexts/PermissionContext'
import SitesContext from '../../contexts/SitesContext'
import { queryClient } from '../../services/api'
import { toISOString } from '../../utils/formatDate'
import { CACHE_KEY_BOP, QUERY_OPTIONS, Responsabilidade, RESPONSABILIDADES } from './constants'
import { keyFromDate } from './useData'

interface DowntimeTiposSelect extends DowntimeTipos {
  label_categoria: string
}

const REQUEST_STATE_MESSAGES = {
  idle: <></>,
  pending: <Alert variant='info' title='Processando' isInline />,
  success: <Alert variant='success' title='Salvo com sucesso' isInline />,
  failure: <Alert variant='danger' title='Ocorreu um erro ao processar o evento' isInline />,
}

type DowntimeAeroAfetado = {
  turb_id: number
  ts_in: Date
  ts_fin: Date
  name: string
  circuit: string
  trafo: string
}

const isCircuitSelected = (circuit: Circuit, selectedTurbs: DowntimeAeroAfetado[]) => {
  if (selectedTurbs.length === 0) return false
  const turbIds = selectedTurbs.map(turb => turb.turb_id)
  const circTurbs = circuit.turbs.map(turb => turb.turb_id)
  return subset(circTurbs, turbIds)
}
const isTransformerSelected = (transformer: Transformer, selectedTurbs: DowntimeAeroAfetado[]) => {
  if (selectedTurbs.length === 0) return false
  const turbIds = selectedTurbs.map(turb => turb.turb_id)
  const trafoTurbs = transformer.turbs.map(turb => turb.turb_id)
  return subset(trafoTurbs, turbIds)
}

const ModalBOP = ({
  isOpen,
  onClose,
  dataToUpdate,
  selectedDate,
  handleDeleteClick,
  modalConfirmation,
}: {
  isOpen: boolean
  onClose: () => void
  dataToUpdate: DowntimeBop | null
  selectedDate: Date
  handleDeleteClick?: (item: string) => void
  modalConfirmation: React.ReactNode
}) => {
  const modalType = dataToUpdate ? 'update' : 'create'
  const isModalUpdate = modalType === 'update'

  const { me, isLoading: meIsLoading } = useContext(PermissionContext)
  const { site, isLoading: sitesIsLoading, turbs } = useContext(SitesContext)
  const placeholderData = { classificacao: [], componentes: [], setor: [], tipos: [] }

  const { data: downtimeMetadata, isLoading: optionsIsLoading } = useQuery(
    ['downtime_opts', !!site?.site_id],
    async () => DowntimeAPI.getOptions(),
    { ...QUERY_OPTIONS, enabled: !!site?.site_id, placeholderData }
  )
  const { tipos, setor: setores } = useMemo(
    () => downtimeMetadata ?? placeholderData,
    [downtimeMetadata]
  )
  const { isLoading: transformersIsLoading, data: transformers } = useQuery(
    ['sites_transformers', site?.site_id],
    () => SitesAPI.getTransformers().then(r => r.filter(item => item.site_id === site.site_id)),
    { ...QUERY_OPTIONS, enabled: !!site?.site_id, placeholderData: [] }
  )
  const { isLoading: circuitsIsLoading, data: circuits } = useQuery(
    ['sites_circuits', site?.site_id],
    () => SitesAPI.getCircuits().then(c => c.filter(item => item.site_id === site.site_id)),
    { ...QUERY_OPTIONS, enabled: !!site?.site_id, placeholderData: [] }
  )
  const tiposSelect: DowntimeTiposSelect[] = useMemo(() => {
    return tipos
      .filter(tipo => tipo.tipo === 'BOP')
      .map(tipo => {
        return { ...tipo, label_categoria: tipo.categoria }
      })
  }, [tipos, optionsIsLoading])

  const defaultTsIn = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)
  const defaultTsFin = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0)

  const minStart = new Date(selectedDate.getFullYear(), 0, 1)
  const maxEnd = new Date(selectedDate.getFullYear(), 11, 30)

  const defaultPeriod = { tsIn: defaultTsIn, tsFin: defaultTsFin }
  const [tsIn, setTsIn] = useState<Date>(defaultPeriod.tsIn)
  const [tsFin, setTsFin] = useState<Date>(defaultPeriod.tsFin)
  const [selectedAeros, setSelectedAeros] = useState<DowntimeAeroAfetado[]>([])
  const [selectedTipo, setSelectedTipo] = useState(tiposSelect[0])
  const [setor, setSetor] = useState(setores[0])
  const [description, setDescription] = useState(dataToUpdate?.descricao ?? '')
  const [responsabilidade, setResponsabilidade] = useState<Responsabilidade>(RESPONSABILIDADES[0])

  const [isError406, setIsError406] = useState(false)
  const [alertActive, setAlertActive] = useState(false)
  const [requestState, setRequestState] = useState<ActionState>('idle')
  const handleSubmit = async () => {
    if (description === '' || tsIn > tsFin || selectedAeros.length === 0) {
      setAlertActive(true)
      return
    }
    setRequestState('pending')
    const requestBody = {
      id: dataToUpdate?.id,
      ts_in: toISOString(eventStartAll),
      ts_fin: toISOString(eventEndAll),
      username: me.current_user.username,
      tipo_id: selectedTipo.id,
      setor_id: setor.id,
      descricao: description,
      responsabilidade_re: responsabilidade.id,
      turbs: selectedAeros.map(aero => ({
        turb_id: aero.turb_id,
        ts_in: toISOString(aero.ts_in),
        ts_fin: toISOString(aero.ts_fin),
      })),
    }
    const requestMethod = dataToUpdate
      ? DowntimeAPI.updateDowntimeBop
      : DowntimeAPI.createDowntimeBop

    try {
      await requestMethod({ siteId: site.site_id, requestBody })
    } catch (error: any) {
      console.log(error.status)
      setIsError406(error.status === 406)
      setRequestState('failure')
      setAlertActive(true)
      return
    }
    setRequestState('success')

    queryClient.invalidateQueries([CACHE_KEY_BOP, site.site_id, keyFromDate(selectedDate, 1)])
  }

  const closeModal = () => {
    setTsIn(defaultPeriod.tsIn)
    setTsFin(defaultPeriod.tsFin)
    setAlertActive(false)
    setIsError406(false)
    setRequestState('idle')
    onClose()
  }

  useEffect(() => {
    if (requestState === 'success') {
      const timerCloseModalAero = setTimeout(() => {
        closeModal()
      }, 750)
      return () => clearTimeout(timerCloseModalAero)
    }
  }, [requestState])

  useEffect(() => {
    setAlertActive(false)
    setRequestState('idle')
  }, [tsIn, tsFin, selectedAeros, selectedTipo, setor, description])

  useEffect(() => {
    if (!dataToUpdate) {
      setTsIn(defaultPeriod.tsIn)
      setTsFin(defaultPeriod.tsFin)
      setSelectedAeros([])
      setSelectedTipo(tiposSelect[0])
      setSetor(setores[0])
      setDescription('')
      setSelectedAeros([])
      setResponsabilidade(RESPONSABILIDADES[0])
      return
    }
    setSelectedTipo(tiposSelect.find(t => t.id === dataToUpdate.tipo_id) ?? tiposSelect[0])
    setSetor(setores.find(s => s.id === dataToUpdate.setor_id) ?? setores[0])
    setResponsabilidade(
      RESPONSABILIDADES.find(r => dataToUpdate?.responsabilidade_re === r.id) ??
        RESPONSABILIDADES[0]
    )
    setDescription(dataToUpdate.descricao ?? '')
    const _selectedAeros = dataToUpdate.turbs.map(t => {
      const turb = turbs.find(turb => turb.turb_id === t.turb_id)
      return {
        turb_id: t.turb_id,
        name: turb?.name ?? '',
        ts_in: new Date(t.ts_in),
        ts_fin: new Date(t.ts_fin),
        circuit: turb?.circuit ?? '',
        trafo: turb?.trafo ?? '',
      }
    })
    setSelectedAeros(_selectedAeros)

    if (dataToUpdate.ts_in) setTsIn(new Date(dataToUpdate.ts_in))
    if (dataToUpdate.ts_fin) setTsFin(new Date(dataToUpdate.ts_fin))
  }, [dataToUpdate, optionsIsLoading, isOpen])

  const [eventStartAll, eventEndAll] = useMemo(() => {
    if (selectedAeros.length === 0) return [defaultPeriod.tsIn, defaultPeriod.tsFin]
    const minDate = selectedAeros.reduce((prev, curr) =>
      prev.ts_in < curr.ts_in ? prev : curr
    ).ts_in
    const maxDate = selectedAeros.reduce((prev, curr) =>
      prev.ts_fin > curr.ts_fin ? prev : curr
    ).ts_fin
    return [minDate, maxDate]
  }, [selectedAeros])

  const handleSelectAero = (newSelects: Turbines[]) => {
    setSelectedAeros(
      newSelects.map(t => ({
        turb_id: t.turb_id,
        name: t.name,
        ts_in: defaultPeriod.tsIn,
        ts_fin: defaultPeriod.tsFin,
        circuit: t.circuit,
        trafo: t.trafo,
      }))
    )
  }
  const isLoading =
    meIsLoading ||
    sitesIsLoading ||
    optionsIsLoading ||
    transformersIsLoading ||
    circuitsIsLoading ||
    !circuits ||
    !transformers

  if (isLoading) return null

  const handleSelectCircuit = (newSelects: Circuit[]) => {
    const newAerosIds = newSelects.flatMap(circuit => circuit.turbs.map(t => t.turb_id))
    const oldCircuits = circuits.filter(circuit => isCircuitSelected(circuit, selectedAeros))
    const removedCircuits = oldCircuits.filter(circuit => !newSelects.includes(circuit))
    const oldAeros = selectedAeros
      .filter(aero => !newAerosIds.includes(aero.turb_id))
      .filter(aero => {
        const isRemoved = removedCircuits.some(circuit =>
          circuit.turbs.some(t => t.turb_id === aero.turb_id)
        )
        return !isRemoved
      })
    const newAeros = newSelects.flatMap(circuit =>
      circuit.turbs.map(t => ({
        turb_id: t.turb_id,
        name: t.name,
        ts_in: defaultPeriod.tsIn,
        ts_fin: defaultPeriod.tsFin,
        circuit: t.circuit,
        trafo: t.trafo,
      }))
    )
    setSelectedAeros([...oldAeros, ...newAeros])
  }
  const handleSelectTransformer = (newSelects: Transformer[]) => {
    const newAerosIds = newSelects.flatMap(trafo => trafo.turbs.map(t => t.turb_id))
    const oldTransformers = transformers.filter(trafo =>
      isTransformerSelected(trafo, selectedAeros)
    )
    const removedTransformers = oldTransformers.filter(trafo => !newSelects.includes(trafo))
    const oldAeros = selectedAeros
      .filter(aero => !newAerosIds.includes(aero.turb_id))
      .filter(aero => {
        const isRemoved = removedTransformers.some(trafo =>
          trafo.turbs.some(t => t.turb_id === aero.turb_id)
        )
        return !isRemoved
      })
    const newAeros = newSelects.flatMap(trafo =>
      trafo.turbs.map(t => ({
        turb_id: t.turb_id,
        name: t.name,
        ts_in: defaultPeriod.tsIn,
        ts_fin: defaultPeriod.tsFin,
        circuit: t.circuit,
        trafo: t.trafo,
      }))
    )

    setSelectedAeros([...oldAeros, ...newAeros])
  }

  const handleOnChangePeriodAll = (ts_in: Date, ts_fin: Date) => {
    // condition to prevent "Maximum update depth exceeded"
    if (ts_in !== eventStartAll || ts_fin !== eventEndAll) {
      setSelectedAeros(selectedAeros.map(aero => ({ ...aero, ts_in, ts_fin })))
    }
  }
  const handleOnChangePeriod = (ts_in: Date, ts_fin: Date, turb_id: number) => {
    setSelectedAeros(
      selectedAeros.map(aero => {
        if (aero.turb_id !== turb_id) return aero
        return { ...aero, ts_in, ts_fin }
      })
    )
  }

  return (
    <Modal
      variant={ModalVariant.large}
      title='Inclusão de paradas do BOP'
      isOpen={isOpen}
      onClose={closeModal}
      aria-label='Inclusão de paradas do BOP'
      id='modalBop'
      actions={[
        <div style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
          <Flex key='container-flex-action'>
            <FlexItem align={{ default: 'alignRight' }}>
              <Button key='create' variant='primary' onClick={handleSubmit} className='green'>
                {isModalUpdate ? 'Salvar' : 'Criar evento'}
              </Button>
            </FlexItem>
            <FlexItem>
              <Button key='cancel' variant='secondary' isDanger onClick={closeModal}>
                Cancelar
              </Button>
            </FlexItem>
          </Flex>
          {handleDeleteClick && modalType === 'update' && (
            <Button
              variant='danger'
              isDanger
              onClick={() => {
                handleDeleteClick(dataToUpdate?.id as string)
              }}
            >
              Deletar
            </Button>
          )}
        </div>,
      ]}
    >
      {modalConfirmation}
      <Grid hasGutter>
        {REQUEST_STATE_MESSAGES[requestState]}
        {alertActive && (
          <GridItem lg={12}>
            {tsIn > tsFin && (
              <Alert
                variant='danger'
                className='pf-v5-u-my-sm'
                isInline
                title='Data inicial deve ser menor que data final'
              />
            )}
            {description === '' && (
              <Alert
                variant='danger'
                className='pf-v5-u-my-sm'
                isInline
                title='Preencha o campo de descrição'
              />
            )}
            {selectedAeros.length === 0 && (
              <Alert
                variant='danger'
                className='pf-v5-u-my-sm'
                isInline
                title='Selecione os aerogeradores afetados'
              />
            )}
            {isError406 && (
              <Alert
                variant='danger'
                isInline
                className='pf-v5-u-my-sm'
                title='Já existe um evento com essas características'
              />
            )}
          </GridItem>
        )}
        <GridItem lg={6}>
          <Stack>
            <StackItem>
              <span>Tipo</span>
              <Select<DowntimeTiposSelect>
                items={tiposSelect}
                itemValueName={'label_categoria'}
                itemKeyName={'id'}
                selected={selectedTipo}
                onChange={setSelectedTipo}
                selectProps={{ menuAppendTo: 'parent' }}
              />
            </StackItem>
            <StackItem>
              <span>Setor</span>
              <Select<DowntimeSetor>
                items={setores}
                itemValueName={'setor'}
                itemKeyName={'id'}
                selected={setor}
                onChange={setSetor}
                selectProps={{ menuAppendTo: 'parent' }}
              />
            </StackItem>
            <StackItem>
              <span>Responsabilidade</span>
              <Select<Responsabilidade>
                items={RESPONSABILIDADES}
                itemValueName={'nome'}
                itemKeyName={'id'}
                selected={responsabilidade}
                onChange={setResponsabilidade}
                selectProps={{ menuAppendTo: 'parent' }}
              />
            </StackItem>
            <StackItem>
              <span>Descrição</span>
              <TextArea
                isRequired
                placeholder='Descrição do evento'
                height={100}
                id='description-input'
                type='text'
                aria-label='description input field'
                value={description}
                onChange={(_, text) => setDescription(text)}
                style={{ minHeight: '10rem' }}
                resizeOrientation='vertical'
              />
            </StackItem>
          </Stack>
        </GridItem>
        <GridItem lg={6}>
          <span>Aerogeradores afetados</span>
          <Split isWrappable>
            <SplitItem>
              <MultiSelect<Turbines>
                items={turbs}
                itemValueName='name'
                itemKeyName='turb_id'
                setSelected={handleSelectAero}
                // eslint-disable-next-line
                selected={selectedAeros.map(aero => turbs.find(t => t.turb_id === aero.turb_id)!)}
                placeholderText='WTGs'
                minSelections={0}
                selectProps={{
                  variant: SelectVariant.checkbox,
                  menuAppendTo: 'parent',
                  footer: null,
                }}
              />
            </SplitItem>
            <SplitItem>
              <MultiSelect<Circuit>
                items={circuits}
                itemValueName='id'
                itemKeyName='id'
                setSelected={handleSelectCircuit}
                selected={circuits.filter(circuit => isCircuitSelected(circuit, selectedAeros))}
                placeholderText='Circuitos'
                minSelections={0}
                selectProps={{
                  variant: SelectVariant.checkbox,
                  menuAppendTo: 'parent',
                  footer: null,
                }}
              />
            </SplitItem>
            <SplitItem>
              <MultiSelect<Transformer>
                items={transformers}
                itemValueName='id'
                itemKeyName='id'
                setSelected={handleSelectTransformer}
                selected={transformers.filter(trafo => isTransformerSelected(trafo, selectedAeros))}
                placeholderText='Trafos'
                minSelections={0}
                selectProps={{
                  variant: SelectVariant.checkbox,
                  menuAppendTo: 'parent',
                  footer: null,
                }}
              />
            </SplitItem>
          </Split>
          <GridItem lg={6}>
            <div className='pf-v5-u-my-md'>
              <OuterScrollContainer style={{ maxHeight: '15rem', border: '1px solid #d2d2d2' }}>
                <InnerScrollContainer style={{ minHeight: '14.7rem' }}>
                  <Table
                    aria-label='Table Aeros Afetados'
                    variant='compact'
                    isExpandable
                    isStriped
                    isStickyHeader
                  >
                    <Thead>
                      <Tr>
                        <Th>Nome</Th>
                        <Th>Inicio</Th>
                        <Th>Fim</Th>
                      </Tr>
                    </Thead>
                    {selectedAeros.length === 0 && (
                      <Tbody style={{ height: '12.5rem' }}>
                        <Tr>
                          <Td colSpan={3}>
                            <EmptyState variant={EmptyStateVariant.lg}>
                              <EmptyStateHeader
                                titleText='Nenhum aerogerador escolhido'
                                headingLevel='h3'
                              />
                            </EmptyState>
                          </Td>
                        </Tr>
                      </Tbody>
                    )}
                    {selectedAeros.map(aero => (
                      <Tbody key={`${aero.name}_${aero.ts_fin}_${aero.ts_in}`}>
                        <Tr>
                          <Td className='pf-v5-u-py-0-on-sm' dataLabel='Aerogerador'>
                            {aero.name}
                          </Td>
                          <Td className='pf-v5-u-py-0-on-sm' dataLabel='Período' colSpan={2}>
                            <DatetimeRangePicker
                              value={{ startDate: aero.ts_in, endDate: aero.ts_fin }}
                              minEnabled={minStart}
                              maxEnabled={maxEnd}
                              onChange={(tsIn, tsFin) =>
                                handleOnChangePeriod(tsIn, tsFin, aero.turb_id)
                              }
                              variant='simple'
                              allowEqualDates
                              showCalendarIcon={false}
                            />
                          </Td>
                        </Tr>
                      </Tbody>
                    ))}
                  </Table>
                </InnerScrollContainer>
              </OuterScrollContainer>
            </div>
          </GridItem>
          <GridItem lg={6}>
            <span>Definir período para todos aerogeradores</span>
            <DatetimeRangePicker
              value={{ startDate: eventStartAll, endDate: eventEndAll }}
              minEnabled={minStart}
              maxEnabled={maxEnd}
              onChange={handleOnChangePeriodAll}
              variant='label'
              allowEqualDates
            />
          </GridItem>
        </GridItem>
      </Grid>
    </Modal>
  )
}
export default ModalBOP
