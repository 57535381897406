import {
  Bullseye,
  Button,
  EmptyState,
  EmptyStateHeader,
  EmptyStateIcon,
  EmptyStateVariant,
  Flex,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
} from '@patternfly/react-core'
import { CogIcon, EditIcon, Remove2Icon, SearchIcon, TimesIcon } from '@patternfly/react-icons'
import { InnerScrollContainer, Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { useContext, useMemo, useState } from 'react'
import ModalConfirmationWarning from '../../components/ModalConfirmationWarning'
import ModalPCReclassification from './ModalPCReclassification'

import { AvailabilityAPI, AvailabilityPowerCurveEvents } from '../../client'
import { ActionState } from '../../components/actionState'
import SitesContext from '../../contexts/SitesContext'
import useMobile from '../../hooks/useMobile'
import { queryClient } from '../../services/api'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import ColorScale from '../../utils/colorScale'
import { formatDateTimeBrl } from '../../utils/formatDate'
import { sortByKeyPosition } from '../../utils/sort'
import {
  CACHE_KEY_PC,
  GRID_SIZE,
  RESPONSABILIDADES,
  TABLE_HEADER_HEIGHT,
  TAB_HEADER_HEIGHT,
  TOOLBAR_HEIGHT,
} from './constants'
import ResizeButtons from './ResizeButtons'
import useData, { keyFromDate } from './useData'

const TablePCReclassification = ({
  selectedDate,
  pcReclassification,
  resizeMode,
  setResizeMode,
}: {
  selectedDate: Date
  pcReclassification: AvailabilityPowerCurveEvents[]
  resizeMode: number
  setResizeMode: (m: number) => void
}) => {
  const { site } = useContext(SitesContext)
  const isMobile = useMobile()
  const [toggleFilters, setToggleFilters] = useState<boolean>(false)

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [dataToDelete, setDataToDelete] = useState<string>('')

  const [reclassification, setReclassification] = useState<AvailabilityPowerCurveEvents>()
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)

  const [actionState, setActionState] = useState<ActionState>('idle')
  const handleDeleteClick = (item: string) => {
    setDataToDelete(item)
    handleModalConfirmationWarningToggle()
  }
  const handleModalConfirmationWarningToggle = () => {
    setActionState('idle')
    setDeleteModalOpen(!isDeleteModalOpen)
  }

  const deleteReclassification = async (id: string) => {
    setActionState('pending')
    try {
      await AvailabilityAPI.deletePcReclassification({
        eventId: id,
        siteId: site.site_id,
      })
    } catch (error) {
      console.log(error)
      setActionState('failure')
      return
    }
    setActionState('success')
    setIsEditModalOpen(false)
    queryClient.invalidateQueries([CACHE_KEY_PC, site?.site_id, keyFromDate(selectedDate, 1)])
  }

  const handleEditReclassification = (item: AvailabilityPowerCurveEvents) => {
    setReclassification(item)
    setIsEditModalOpen(true)
  }

  const [activeSortIndex, setActiveSortIndex] = useState(0)
  const [activeSortDirection, setActiveSortDirection] = useState<'asc' | 'desc'>('asc')

  const getSortParams = (columnIndex: number) => ({
    sortBy: {
      index: activeSortIndex,
      direction: activeSortDirection,
    },
    onSort: (_event: any, index: number, direction: 'asc' | 'desc') => {
      setActiveSortIndex(index)
      setActiveSortDirection(direction)
    },
    columnIndex,
  })

  const { componentes, tipos } = useData(selectedDate)

  const pcReclassificationData = useMemo(() => {
    function getTipo(id?: number) {
      const found = tipos.find(item => item.id === id)
      return found ? found.tipo : ''
    }

    function getComponent(id?: number | null) {
      const found = componentes.find(c => c.id === id)
      return found ? found.component : ''
    }

    function getResponsabilidade(id?: boolean) {
      const found = RESPONSABILIDADES.find(item => item.id === id)
      return found ? found.nome : ''
    }

    const mapDataItems = pcReclassification.map(item => ({
      inicio: item.ts_in,
      fim: item.ts_fin,
      usuario: item.username,
      tipo: getTipo(item.tipo_id),
      componente: getComponent(item.component_id),
      responsabilidade: getResponsabilidade(item.responsabilidade_re ?? false),
      ...item,
    }))

    return sortByKeyPosition(mapDataItems, activeSortIndex, activeSortDirection)
  }, [
    pcReclassification,
    activeSortIndex,
    activeSortDirection,
    tipos,
    componentes,
    RESPONSABILIDADES,
  ])

  const buttonGroupItems = <ResizeButtons setResizeMode={setResizeMode} resizeMode={resizeMode} />

  const toolbarItems = (
    <div
      style={{
        display: 'flex',
        ...(isMobile && { flexDirection: 'column' }),
        width: '100%',
      }}
    >
      <ToolbarGroup
        variant='button-group'
        style={{
          ...(isMobile && { display: 'flex', flexDirection: 'column' }),
          width: 'fit-content',
          margin: 0,
        }}
      >
        {buttonGroupItems}
      </ToolbarGroup>
    </div>
  )

  const tableAndContainerHeight = useMemo(() => {
    const h = calcVerticalHeight({
      elementSize: GRID_SIZE / 2 - resizeMode,
      gridSize: GRID_SIZE,
    })
    // Offset tem que ser fora, calcVerticalHeight distribui igualmente o offset entre os elementos
    const customOffset = TAB_HEADER_HEIGHT + TOOLBAR_HEIGHT
    const s = `calc(${h} - ${customOffset}rem)`

    return s
  }, [resizeMode])

  const heightBottom = useMemo(() => {
    const h = calcVerticalHeight({ elementSize: GRID_SIZE / 2 - resizeMode, gridSize: GRID_SIZE })
    // Offset tem que ser fora, calcVerticalHeight distribui igualmente o offset entre os elementos
    const customOffset = TABLE_HEADER_HEIGHT
    const s = `calc(${h} - ${customOffset}rem)`

    return s
  }, [resizeMode])

  return (
    <>
      <div style={{ height: heightBottom, backgroundColor: 'orange' }}>
        {isMobile && (
          <Flex
            style={{ justifyContent: 'flex-start', backgroundColor: 'white', position: 'relative' }}
          >
            <Button
              style={{
                backgroundColor: 'transparent',
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                justifyContent: 'center',
                height: '35px',
                color: 'black',
              }}
              onClick={() => setToggleFilters(!toggleFilters)}
            >
              {!toggleFilters ? (
                <>
                  Ações
                  <EmptyStateIcon icon={CogIcon} />
                </>
              ) : (
                <EmptyStateIcon icon={TimesIcon} />
              )}
            </Button>
          </Flex>
        )}
        {!isMobile || toggleFilters ? (
          <Flex
            style={{
              backgroundColor: 'white',
              position: 'relative',
            }}
          >
            <Toolbar
              id='toolbar-group-types'
              style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}
            >
              <ToolbarContent style={{ width: '100%' }}>{toolbarItems}</ToolbarContent>
            </Toolbar>
          </Flex>
        ) : null}

        {/* OuterScrollContainer não deve ser incluído */}
        <InnerScrollContainer style={{ height: tableAndContainerHeight, backgroundColor: 'pink' }}>
          <Table
            aria-label='Table Restricoes ONS'
            variant='compact'
            isStriped
            isExpandable
            isStickyHeader
            style={{ height: tableAndContainerHeight, backgroundColor: ColorScale.branco_clear }}
          >
            <Thead>
              <Tr style={{ height: `${TABLE_HEADER_HEIGHT}rem` }}>
                {pcReclassification.length > 0 && <Th />}
                <Th>Id</Th>
                <Th sort={getSortParams(1)}>Início</Th>
                <Th sort={getSortParams(2)}>Fim</Th>
                <Th className='pf-m-wrap'>Usuário</Th>
                <Th className='pf-m-wrap'>Tipo</Th>
                <Th>Componente</Th>
                <Th className='pf-m-wrap'>Responsabilidade</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>

            {pcReclassification.length === 0 && (
              <Tbody>
                <Tr>
                  <Td colSpan={8}>
                    <Bullseye>
                      <EmptyState variant={EmptyStateVariant.lg}>
                        <EmptyStateHeader
                          titleText='Nenhum registro encontrado.'
                          icon={<EmptyStateIcon icon={SearchIcon} />}
                          headingLevel='h3'
                        />
                      </EmptyState>
                    </Bullseye>
                  </Td>
                </Tr>
              </Tbody>
            )}

            {pcReclassificationData.length > 0 &&
              pcReclassificationData.map((evnt, rowIndex) => {
                return (
                  <Tbody key={evnt.id}>
                    <Tr>
                      <Td />
                      <Td dataLabel='Id'>{evnt?.id?.substring(0, 8)}</Td>
                      <Td dataLabel='Início'>{formatDateTimeBrl(new Date(evnt.inicio))}</Td>
                      <Td dataLabel='Fim'>{formatDateTimeBrl(new Date(evnt.fim))}</Td>
                      <Td dataLabel='Usuário'>{evnt.username}</Td>
                      <Td dataLabel='Tipo'>{evnt.tipo}</Td>
                      <Td dataLabel='Componente'>{evnt.componente}</Td>
                      <Td dataLabel='Responsabilidade'>{evnt.responsabilidade}</Td>

                      <Td dataLabel='Ações'>
                        <div>
                          <EditIcon
                            onClick={() => {
                              handleEditReclassification(evnt)
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                          <Remove2Icon
                            onClick={() => handleDeleteClick(evnt.id)}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </Td>
                    </Tr>
                  </Tbody>
                )
              })}
          </Table>
        </InnerScrollContainer>
      </div>

      <ModalConfirmationWarning<string>
        isOpen={isDeleteModalOpen}
        handleModalToggle={handleModalConfirmationWarningToggle}
        handleSubmit={id => deleteReclassification(id)}
        title='Remover Reclassificação'
        element={dataToDelete}
        actionState={actionState}
      />

      {reclassification ? (
        <ModalPCReclassification
          isOpen={isEditModalOpen}
          handleModalToggle={() => {
            setIsEditModalOpen(!isEditModalOpen)
          }}
          actionState={'idle'}
          selectedDate={selectedDate}
          dataToUpdate={reclassification}
          handleDeleteClick={handleDeleteClick}
          modalConfirmation={
            <ModalConfirmationWarning<string>
              isOpen={isDeleteModalOpen}
              handleModalToggle={handleModalConfirmationWarningToggle}
              handleSubmit={id => deleteReclassification(id)}
              title='Remover Reclassificação'
              element={dataToDelete}
              actionState={actionState}
            />
          }
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default TablePCReclassification
