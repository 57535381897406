import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { AvailabilityAPI, Turbines } from '../../client'
import DatetimeRangePicker from '../../components/calendar/DatetimeRangePicker'
import Select from '../../components/selects/Select'
import SitesContext from '../../contexts/SitesContext'
import useVertical from '../../hooks/useVertical'
import { queryClient } from '../../services/api'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import { toISOString } from '../../utils/formatDate'
import { getFilterParams } from '../../utils/url'
import PageFrame from '../shared/Page'
import SelectSite from '../shared/selects/SelectSite'
import PlotExpectedEnergy from './PlotExpectedEnergy'

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
  placeHolderData: [],
}
const heightStyle = { height: calcVerticalHeight({ gridSize: 1, elementSize: 1, customOffset: 0 }) }
const QUERY_KEY = 'expected_energy_turb'

const prefetch = ({
  siteId,
  turbId,
  startDate,
  endDate,
}: {
  siteId: number
  turbId: number
  startDate: Date
  endDate: Date
}) => {
  queryClient.prefetchQuery(
    [QUERY_KEY, siteId, turbId, JSON.stringify({ startDate, endDate })],
    () => get_timeseries({ siteId, turbId, startDate, endDate }),
    defaultQueryOptions
  )
}

const get_timeseries = ({
  siteId,
  turbId,
  startDate,
  endDate,
}: {
  siteId: number
  turbId?: number
  startDate: Date
  endDate: Date
}) => {
  if (!turbId) return []
  return AvailabilityAPI.getTimeseries({
    siteId,
    dateIn: toISOString(startDate),
    dateFin: toISOString(new Date(endDate.getTime() + 1000 * 60 * 60 * 24)),
    turbIds: [turbId],
  })
}

const ExpectedEnergyTurb = () => {
  const {
    site,
    sites,
    setActiveSite,
    isLoading: siteIsLoading,
    turbs,
    allTurbs,
  } = useContext(SitesContext)

  const location = useLocation()
  const { siteName } = useParams()

  const isVertical = useVertical()
  const [isNavOpen, setNavOpen] = useState(!isVertical)
  const minDate = new Date(site.commissioning_date)
  const dtNow = new Date()
  const maxDate = new Date(dtNow.getFullYear(), dtNow.getMonth(), dtNow.getDate() - 1)
  const startDate = new Date(dtNow.getFullYear(), dtNow.getMonth(), dtNow.getDate() - 7)
  const [period, setPeriod] = useState({ startDate, endDate: maxDate })
  const [plotRevision, setPlotRevision] = useState(0)
  const [selectedTurbine, setSelectedTurbine] = useState<Turbines>()

  useEffect(() => {
    const _siteName = siteName ?? site.site
    const _site = sites.find(e => e.site.toLowerCase() === _siteName.toLowerCase())
    const _period = getFilterParams('period')

    let startDataParams: Date = startDate
    let endDateParams: Date = maxDate
    if (_period.length >= 2) {
      startDataParams = new Date(_period[0])
      endDateParams = new Date(_period[1])
    }
    if (
      startDataParams > endDateParams ||
      startDataParams > new Date() ||
      endDateParams > new Date()
    ) {
      startDataParams = startDate
      endDateParams = maxDate
    }
    setPeriod({ startDate: startDataParams, endDate: endDateParams })

    if (_site) setActiveSite(_site.site_id)
  }, [location])

  const queryOptions = { ...defaultQueryOptions, enabled: !!site?.site_id }

  const { isLoading: timeseriesIsLoading, data: timeseries } = useQuery(
    [QUERY_KEY, site?.site_id, selectedTurbine?.turb_id, JSON.stringify(period)],
    async () =>
      get_timeseries({ siteId: site?.site_id, turbId: selectedTurbine?.turb_id, ...period }),
    queryOptions
  )

  const isLoading = siteIsLoading || timeseriesIsLoading

  useEffect(() => {
    setPlotRevision(plotRevision + 1)
  }, [timeseries, isNavOpen, isLoading])

  useEffect(() => {
    if (isLoading || !selectedTurbine?.turb_id) return
    sites
      .filter(s => s.site_id !== site.site_id)
      .forEach(s => prefetch({ siteId: s.site_id, turbId: selectedTurbine.turb_id, ...period }))
  }, [isLoading])

  useEffect(() => {
    setSelectedTurbine(allTurbs.find(at => at.site_id === site.site_id))
  }, [turbs])

  return (
    <PageFrame
      pageName='Expected Energy'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      filters={[
        <SelectSite
          enableUrlParams
          onChange={site =>
            setSelectedTurbine(allTurbs.find(at => at.site_id === site.site_id) ?? turbs[0])
          }
          key='s1'
        />,
        <DatetimeRangePicker
          key='dt_range'
          value={period}
          onChange={(startDate, endDate) => setPeriod({ startDate, endDate })}
          calendarVariant='date'
          maxEnabled={maxDate}
          minEnabled={minDate}
          allowEqualDates
          variant='simple'
        />,
        <Select<Turbines>
          key={'turbines' + site?.site_id + isLoading}
          items={turbs}
          itemValueName='name'
          itemKeyName='turb_id'
          selected={selectedTurbine ?? ({} as Turbines)}
          onChange={setSelectedTurbine}
        />,
      ]}
    >
      {!!timeseries && (
        <PlotExpectedEnergy
          timeseries={timeseries}
          revision={plotRevision}
          style={{ width: '100%', ...heightStyle }}
        />
      )}
    </PageFrame>
  )
}

export default ExpectedEnergyTurb
