import { SearchInput } from '@patternfly/react-core'

type FilterGroupProps = {
  filterGroupValue: string
  setFilterGroupValue: React.Dispatch<React.SetStateAction<string>>
  activeTabKey: number
  onChangeFilterGroup: (filterValue: string) => void
}

const FilterGroup = ({
  filterGroupValue,
  setFilterGroupValue,
  activeTabKey,
  onChangeFilterGroup,
}: FilterGroupProps) => {
  if (activeTabKey === 1) {
    return (
      <div className='pf-v5-u-p-sm'>
        <SearchInput
          placeholder='Filtrar Grupos'
          value={filterGroupValue}
          onChange={(_event, value) => onChangeFilterGroup(value)}
          onClear={() => setFilterGroupValue('')}
        />
      </div>
    )
  } else {
    return null
  }
}

export default FilterGroup
