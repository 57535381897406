import {
  Alert,
  Button,
  Form,
  FormGroup,
  Modal,
  ModalVariant,
  Panel,
  PanelHeader,
  Split,
  SplitItem,
  TextArea,
} from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import '../../assets/css/styles.css'
import { BatchRemoveResponse, News, NewsAPI } from '../../client'
import { ActionState } from '../../components/actionState'
import EntityTable from '../../components/EntityTable'
import ModalConfirmationWarning from '../../components/ModalConfirmationWarning'
import { queryClient } from '../../services/api'
import { getFirstSegment } from '../../utils/getFirstSegment'
import PageFrame from '../shared/Page'

const REQUEST_STATE_MESSAGES = {
  idle: <></>,
  pending: <Alert variant='info' title='Processando' isInline />,
  success: <Alert variant='success' title='Salvo com sucesso' isInline />,
  failure: <Alert variant='danger' title='Ocorreu um erro ao processar o evento' isInline />,
}

const NewsModal = ({
  handleModalToggle,
  isModalOpen,
  dataToUpdate,
  setSelectedNews,
}: {
  handleModalToggle: () => void
  isModalOpen: boolean
  dataToUpdate: undefined | News
  setSelectedNews: React.Dispatch<React.SetStateAction<undefined | News>>
}) => {
  const [actionState, setActionState] = useState<ActionState>('idle')
  const [newsText, setNewsText] = useState(dataToUpdate?.news_text || '')
  const isCreateModal = !dataToUpdate

  const action = dataToUpdate !== undefined && dataToUpdate?.news_id ? 'Editar' : 'Criar'

  const handleMutation = async () => {
    setActionState('pending')
    const requestMethod = dataToUpdate?.news_id ? NewsAPI.update : NewsAPI.create
    const requestBody: News = {
      ...(dataToUpdate && dataToUpdate),
      news_date: new Date().toISOString().split('T')[0],
      news_text: newsText,
    }
    try {
      await requestMethod({ requestBody })
      setActionState('success')
      await queryClient.invalidateQueries({ queryKey: ['news'] })
    } catch (error) {
      setActionState('failure')
    }
  }

  useEffect(() => {
    if (actionState === 'success') {
      const timerCloseModal = setTimeout(() => {
        handleModalToggle()
        setSelectedNews(undefined)
        setNewsText('')
        setActionState('idle')
      }, 750)
      return () => clearTimeout(timerCloseModal)
    }
  }, [actionState])

  return (
    <Modal
      aria-label='create novidade'
      variant={ModalVariant.large}
      title={`${action} Novidade`}
      isOpen={isModalOpen}
      onClose={() => {
        setNewsText('')
        handleModalToggle()
      }}
      actions={[
        <Split key='modal-calibration-actions' hasGutter style={{ width: '100%' }}>
          <SplitItem>
            <Button
              variant='primary'
              onClick={handleMutation}
              key='create'
              isDisabled={actionState === 'pending' || newsText?.length === 0}
            >
              {isCreateModal ? 'Cadastrar' : 'Alterar'}
            </Button>
          </SplitItem>
          <SplitItem>
            <Button
              key='cancelar'
              variant='secondary'
              isDanger
              onClick={handleModalToggle}
              isDisabled={actionState === 'pending'}
            >
              Cancelar
            </Button>
          </SplitItem>
        </Split>,
      ]}
    >
      {REQUEST_STATE_MESSAGES[actionState]}
      <Form id='modal-with-form-form'>
        <FormGroup>
          <TextArea
            style={{ height: 300 }}
            resizeOrientation='vertical'
            required
            value={newsText}
            onChange={(_, text) => setNewsText(text)}
            aria-label='text area'
          />
        </FormGroup>
      </Form>
    </Modal>
  )
}

const formatDate = (str: string) => {
  return str.split('-').reverse().join('/')
}

const Novidades = () => {
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)
  const [isModalOpen, setModalOpen] = useState(false)
  const [dataToUpdate, setDataToUpdate] = useState<News>()

  const { isLoading, data: news } = useQuery(['news'], () => NewsAPI.getAll({ limit: 0 }), {
    initialData: [],
  })

  const handleModalToggle = () => {
    setDataToUpdate(undefined)
    setModalOpen(!isModalOpen)
  }

  const handleEditUserClick = (n: News) => {
    setDataToUpdate(n)
    setModalOpen(prev => !prev)
  }

  const [checkboxSelected, setCheckboxSelected] = useState<News[]>([])

  const filters = [
    <Button
      key='criar-novidade'
      variant='control'
      className='pf-v5-u-m-sm'
      onClick={handleModalToggle}
    >
      Criar Novidade
    </Button>,
    <Button
      variant='danger'
      onClick={() => setModalConfirmationWarningSelectedNews(prev => !prev)}
      isDisabled={!checkboxSelected[0]}
      className='pf-v5-u-m-sm'
    >
      Remover
    </Button>,
  ]

  const [actionState, setActionState] = useState<ActionState>('idle')
  const [errorsResponse, setErrorsResponse] = useState<string[]>([])
  const [isModalConfirmationWarningSelectedNews, setModalConfirmationWarningSelectedNews] =
    useState(false)

  return (
    <PageFrame
      pageName='Novidades'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      filters={filters}
      isLoading={isLoading}
    >
      <Panel className='pf-v5-u-box-shadow-sm'>
        <PanelHeader style={{ width: '100%' }}>
          <b>Novidades</b>
        </PanelHeader>
        <EntityTable<News>
          items={news}
          isLoading={isLoading}
          itemKeyName='news_id'
          isCompact
          columnConfig={[
            { key: 'news_text', description: 'Conteúdo' },
            {
              key: 'news_date',
              description: 'Data',
              formatter: formatDate,
            },
          ]}
          onClickEdit={handleEditUserClick}
          onClickDelete={instance => {
            setCheckboxSelected([instance])
            setModalConfirmationWarningSelectedNews(prev => !prev)
          }}
          selectedMode='checkbox'
          checkboxSelected={checkboxSelected}
          setCheckboxSelected={setCheckboxSelected}
        />
      </Panel>
      {isModalOpen && (
        <NewsModal
          handleModalToggle={handleModalToggle}
          isModalOpen={isModalOpen}
          dataToUpdate={dataToUpdate}
          setSelectedNews={setDataToUpdate}
        />
      )}
      {isModalConfirmationWarningSelectedNews && (
        <ModalConfirmationWarning<News[]>
          isOpen={isModalConfirmationWarningSelectedNews}
          handleModalToggle={() => {
            setActionState('idle')
            setModalConfirmationWarningSelectedNews(prev => !prev)
            setErrorsResponse([])
          }}
          handleSubmit={async data => {
            setActionState('pending')
            try {
              await NewsAPI.batchDelete({
                newsIds: data.map(d => d.news_id as string),
              })
              await queryClient.invalidateQueries({ queryKey: ['news'] })
              setActionState('success')
              setModalConfirmationWarningSelectedNews(prev => !prev)
              setCheckboxSelected([])
              setActionState('idle')
            } catch (error: any) {
              try {
                const details = JSON.parse(error?.body?.detail) as BatchRemoveResponse
                const _errosResponse: string[] = []
                if (details.failed_ids.length > 0) {
                  details.failed_ids.forEach(failed => {
                    let error = getFirstSegment(failed.id as string)
                    if (failed.details) error += ` - ${failed.details}`
                    _errosResponse.push(error)
                  })
                  setErrorsResponse(_errosResponse)
                }
              } catch (error) {
                setErrorsResponse(['Erro ao remover novidades.'])
              }
              setActionState('failure')
              return
            }
          }}
          title='Remover Novidades'
          element={checkboxSelected}
          actionState={actionState}
          alertMessage='Tem certeza que deseja remover estes itens? Essa ação não poderá ser desfeita.'
          errorsResponse={errorsResponse}
          additionalInformation={
            checkboxSelected.length > 0 && (
              <>
                <EntityTable<News>
                  items={checkboxSelected}
                  itemKeyName='news_id'
                  isCompact
                  columnConfig={[
                    { key: 'news_text', description: 'Conteúdo' },
                    {
                      key: 'news_date',
                      description: 'Data',
                      formatter: formatDate,
                    },
                  ]}
                />
              </>
            )
          }
        />
      )}
    </PageFrame>
  )
}

export default Novidades
