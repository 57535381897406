import { ExpandableSection } from '@patternfly/react-core'
import CheckCircleIcon from '@patternfly/react-icons/dist/esm/icons/check-circle-icon'
import InProgressIcon from '@patternfly/react-icons/dist/esm/icons/in-progress-icon'
import TimesCircleIcon from '@patternfly/react-icons/dist/esm/icons/times-circle-icon'
import { css } from '@patternfly/react-styles'
import styles from '@patternfly/react-styles/css/components/MultipleFileUpload/multiple-file-upload'
import React, { useState } from 'react'

interface IMultipleFileUploadStatusProps extends React.PropsWithChildren {
  statusToggleText: string
  statusToggleIcon: string
  className?: string
  isExpanded?: any
}

export const MultipleFileUploadStatus = (props: IMultipleFileUploadStatusProps) => {
  const { children, className, statusToggleText, statusToggleIcon } = props

  const [icon, setIcon] = useState<React.ReactElement | string>(<></>)
  const [isOpen, setIsOpen] = useState(props?.isExpanded)

  React.useEffect(() => {
    switch (statusToggleIcon) {
      case 'danger':
        setIcon(<TimesCircleIcon />)
        break
      case 'success':
        setIcon(<CheckCircleIcon />)
        break
      case 'inProgress':
        setIcon(<InProgressIcon />)
        break
      default:
        setIcon(statusToggleIcon)
    }
  }, [statusToggleIcon])

  const toggle = (
    <div className={styles.multipleFileUploadStatusProgress}>
      <div className={styles.multipleFileUploadStatusProgressIcon}>{icon}</div>
      <div className={styles.multipleFileUploadStatusItemProgressText}>{statusToggleText}</div>
    </div>
  )

  const toggleExpandableSection = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={css(styles.multipleFileUploadStatus, className)} {...props}>
      <ExpandableSection
        toggleContent={toggle}
        isExpanded={isOpen}
        onToggle={toggleExpandableSection}
      >
        <ul className='pf-c-multiple-file-upload__status-list'>{children}</ul>
      </ExpandableSection>
    </div>
  )
}
