import {
  Alert,
  Button,
  Flex,
  FlexItem,
  Modal,
  ModalVariant,
  Progress,
  ProgressMeasureLocation,
  Text,
} from '@patternfly/react-core'
import FormData from 'form-data'
import React, { useMemo, useState } from 'react'
import { StatusResponseType } from '.'
import { Site } from '../../client'
import api, { queryClient } from '../../services/api'
import { MultiUploadImagens } from './MultiUploadImagens'
import { UploadXlsx } from './UploadXlsx'

interface IModalAdicionarOcorrencias {
  isOpenModelAdicionarOcorrencia: boolean
  setIsOpenModelAdicionarOcorrencia: React.Dispatch<React.SetStateAction<boolean>>
  site: Site
}

export const ModalAdicionarOcorrencias = (props: IModalAdicionarOcorrencias) => {
  const { isOpenModelAdicionarOcorrencia, setIsOpenModelAdicionarOcorrencia, site } = props

  const [currentFiles, setCurrentFiles] = useState<File[]>([])

  const [planilhaFile, setPlanilhaFile] = useState<File | null>(null)
  const [isRejectedXlsx, setIsRejectedXlsx] = useState(false)
  const [isRejectedImagens, setIsRejectedImagens] = useState(false)
  const [mensagemImagensError, setMensagemImagensError] = useState('')
  const [isSalvarLoading, setIsSalvarLoading] = useState(false)
  const [progressSaveOcorrencias, setProgressSaveOcorrencias] = useState(0)
  const [statusResponseSave, setStatusResponseSave] = useState<StatusResponseType>('')

  const primaryLoadingProps = useMemo(() => {
    return {
      spinnerAriaValueText: 'Loading',
      spinnerAriaLabelledBy: 'salvar-loading-button',
      isLoading: isSalvarLoading,
    }
  }, [isSalvarLoading])

  const handleCloseModal = () => {
    setIsOpenModelAdicionarOcorrencia(false)
    setCurrentFiles([])
    setPlanilhaFile(null)
    setIsRejectedXlsx(false)
    setIsRejectedImagens(false)
    setIsSalvarLoading(false)
    setStatusResponseSave('')
    queryClient.invalidateQueries(['occurrences', site.site_id])
  }

  const handleSubmit = async () => {
    setStatusResponseSave('')
    setIsSalvarLoading(true)
    let isError = false
    if (!planilhaFile) {
      setIsRejectedXlsx(true)
      isError = true
    }

    if (currentFiles.length < 1) {
      setIsRejectedImagens(true)
      isError = true
    }

    if (isError) return setIsSalvarLoading(false)

    const formData = new FormData()
    formData.append('xlsx', planilhaFile || '')
    for (const i in currentFiles) {
      formData.append('images', currentFiles[i])
    }

    try {
      await api.post(`/sites/${site.site_id}/occurrences/workbook_upload`, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': `multipart/form-data; boundary=${formData?.getBoundary()}`,
        },
        onUploadProgress: event => {
          if (event.total) {
            const progress = Math.round((event.loaded * 100) / event.total)
            setProgressSaveOcorrencias(Number(progress))
          }
        },
      })

      setCurrentFiles([])
      setPlanilhaFile(null)
      setIsSalvarLoading(false)
      setStatusResponseSave('success')
    } catch (error) {
      console.error(error)
      setIsSalvarLoading(false)
      setStatusResponseSave('error')
    }
  }

  return (
    <Modal
      isOpen={isOpenModelAdicionarOcorrencia}
      onClose={handleCloseModal}
      variant={ModalVariant.small}
      title='Adicionar ocorrências'
      actions={[
        <Flex key='container-flex-action' style={{ width: '100%' }}>
          <FlexItem flex={{ default: 'flex_1' }} alignSelf={{ default: 'alignSelfCenter' }}>
            {isSalvarLoading && (
              <Progress
                value={progressSaveOcorrencias}
                measureLocation={ProgressMeasureLocation.inside}
              />
            )}

            {statusResponseSave === 'success' && (
              <Alert
                variant='success'
                isInline
                title='Upload feito com sucesso!'
                style={{ padding: 6 }}
              />
            )}

            {statusResponseSave === 'error' && (
              <Alert
                variant='danger'
                isInline
                style={{ padding: 6 }}
                title='Houve um erro ao adicionar ocorrências!'
              />
            )}
          </FlexItem>
          <FlexItem align={{ default: 'alignRight' }}>
            <Button
              id='salvar-loading-button'
              key='create'
              variant='primary'
              form='modal-with-form-form'
              onClick={handleSubmit}
              className='green'
              {...primaryLoadingProps}
              isDisabled={!!isSalvarLoading}
            >
              {isSalvarLoading ? 'Salvando' : 'Salvar'}
            </Button>
          </FlexItem>
          <FlexItem>
            <Button key='cancel' variant='secondary' isDanger onClick={handleCloseModal}>
              Cancelar
            </Button>
          </FlexItem>
        </Flex>,
      ]}
    >
      <Flex direction={{ default: 'column' }}>
        <FlexItem style={{ margin: 0, padding: 0 }}>
          <Text>
            Planilha Excel <span style={{ color: 'red' }}>*</span>
          </Text>
        </FlexItem>
        <FlexItem flex={{ default: 'flex_1' }}>
          <UploadXlsx
            planilhaFile={planilhaFile}
            setPlanilhaFile={setPlanilhaFile}
            isRejectedXlsx={isRejectedXlsx}
            setIsRejectedXlsx={setIsRejectedXlsx}
          />
        </FlexItem>
      </Flex>
      <Flex direction={{ default: 'column' }} style={{ marginTop: 15 }}>
        <FlexItem style={{ margin: 0, padding: 0 }}>
          <Text>
            Fotos <span style={{ color: 'red' }}>*</span>
          </Text>
        </FlexItem>
        <FlexItem flex={{ default: 'flex_1' }}>
          <MultiUploadImagens
            currentFiles={currentFiles}
            setCurrentFiles={setCurrentFiles}
            isRejectedImagens={isRejectedImagens}
            setIsRejectedImagens={setIsRejectedImagens}
            mensagemImagensError={mensagemImagensError}
            setMensagemImagensError={setMensagemImagensError}
          />
        </FlexItem>
      </Flex>
    </Modal>
  )
}
