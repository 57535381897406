import { Tab, Tabs, TabTitleText } from '@patternfly/react-core'
import { CheckIcon, CloseIcon, EditIcon, Remove2Icon } from '@patternfly/react-icons'
import {
  InnerScrollContainer,
  OuterScrollContainer,
  Table /* data-codemods */,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { useMutation } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'

import { GroupIn, UserIn } from '../../client'
import { GroupOut } from '../../client/models/GroupOut'
import { Pages } from '../../client/models/Pages'
import { Site } from '../../client/models/Site'
import { UserOut } from '../../client/models/UserOut'
import PermissionContext from '../../contexts/PermissionContext'
import SitesContext from '../../contexts/SitesContext'
import api, { queryClient as qq } from '../../services/api'
import ModalConfirmationWarning from '../shared/DeprecatedModalConfirmationWarning'
import PageFrame from '../shared/Page'
import BarUsersGroups from './BarUsersGroups'
import FilterGroup from './FilterGroup'
import FilterUser from './FilterUser'
import ModalCreateGroup from './ModalCreateGroup'
import ModalEditUser from './ModalEditUser'

type AllOptions = UserOut | Pages | Site | GroupOut
type KeyAvailableOptions = 'name' | 'site_name' | 'username'
type KeyIdOptions = 'id' | 'page_id' | 'site_id'
type ChosenOptions = string | undefined

const getAvailableOptions = (
  allOptions: AllOptions[],
  chosenOptions: ChosenOptions[],
  key: KeyAvailableOptions
): string[] => {
  return (
    allOptions
      .filter(o => !chosenOptions.includes(o[key as keyof AllOptions]))
      .map(o => o[key as keyof AllOptions]) || []
  )
}

const getIdList = (
  allOpts: AllOptions[],
  chosenOpts: ChosenOptions[],
  key: KeyAvailableOptions,
  keyId: KeyIdOptions
): string[] => {
  return (
    allOpts
      .filter(co => chosenOpts.includes(co[key as keyof AllOptions]))
      .map(co => co[keyId as keyof AllOptions]) || []
  )
}

const mutateOptions = {
  onSuccess: () => {
    qq.invalidateQueries(['users'])
    qq.invalidateQueries(['groups'])
  },
}

type ModalInfoType = {
  modalTitle: string
  modalElement: UserOut | GroupOut
}

type DataToDeleteType = {
  id: string
  type: 'USER' | 'GROUP'
}

type DefaultOptions = {
  availableOptions: string[]
  chosenOptions: string[]
}

function UsuariosGrupos() {
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)
  const { sites } = useContext(SitesContext)
  const { pages, users, groups, isLoading, permissionTypes } = useContext(PermissionContext)
  const [modalInfo, setModalInfo] = useState<ModalInfoType>()
  const [isModalConfirmationWarningOpen, setModalConfirmationWarningOpen] = useState(false)
  const [dataToDelete, setDataToDelete] = useState<DataToDeleteType>()
  const [isUserModalOpen, setUserModalOpen] = useState(false)
  const [userNameValue, setUserNameValue] = useState('')
  const [userEmailValue, setUserEmailValue] = useState('')
  const [passwordValue, setPasswordValue] = useState('')
  const [isGroupModalOpen, setGroupModalOpen] = useState(false)
  const [editGroup, setEditGroup] = useState<GroupOut>()
  const [groupNameValue, setGroupNameValue] = useState('')
  const [groupDescriptionValue, setGroupDescriptionValue] = useState('')
  const defaultOptions: DefaultOptions = { availableOptions: [], chosenOptions: [] }
  const [groupsOptions, setGroupsOptions] = useState({ ...defaultOptions })
  const [membersOptions, setMembersOptions] = useState({ ...defaultOptions })
  const [pagesOptions, setPagesOptions] = useState({ ...defaultOptions })
  const [sitesOptions, setSitesOptions] = useState({ ...defaultOptions })
  const [editUser, setEditUser] = useState<UserOut>()
  const [activeTabKey, setActiveTabKey] = useState(0)
  const [permissionTypeSelected, setPermissionTypeSelected] = useState<string | undefined>()
  const [usersFiltered, setUsersFiltered] = useState<UserOut[]>()
  const [groupsFiltered, setGroupsFiltered] = useState<GroupOut[]>()

  // filter states
  const [filterUserValue, setFilterUserValue] = useState('')
  const [filterGroupValue, setFilterGroupValue] = useState('')

  useEffect(() => {
    setUsersFiltered(users)
  }, [users])

  useEffect(() => {
    setGroupsFiltered(groups)
  }, [groups])

  useEffect(() => {
    setPermissionTypeSelected(isLoading ? '' : permissionTypes[0].perm_type_id)
  }, [isLoading])

  const handleModalConfirmationWarningToggle = () =>
    setModalConfirmationWarningOpen(!isModalConfirmationWarningOpen)
  const handleCreateUserModalToggle = () => setUserModalOpen(!isUserModalOpen)
  const handleEditGroupModalToggle = () => setGroupModalOpen(!isGroupModalOpen)

  const { mutate: deleteUser } = useMutation(
    (id: string) => api.delete(`/admin/users/${id}`),
    mutateOptions
  )
  const { mutate: deleteGroup } = useMutation(
    (id: string) => api.delete(`/admin/groups/${id}`),
    mutateOptions
  )
  const { mutate: updateUser } = useMutation(
    (body: UserOut | UserIn) => api.put('/admin/users', body),
    mutateOptions
  )
  const { mutate: updateGroup } = useMutation(
    (body: GroupOut | GroupIn) => api.put('/admin/groups', body),
    mutateOptions
  )
  const { mutate: createUser } = useMutation(
    (body: UserIn) => api.post('/admin/users', body),
    mutateOptions
  )
  const { mutate: createGroup } = useMutation(
    (body: GroupIn) => api.post('/admin/groups', body),
    mutateOptions
  )

  const handleDeleteClick = (data: UserOut | GroupOut) => {
    const id = data.id
    const type = 'user_ids' in data ? 'GROUP' : 'USER'

    setModalInfo({
      modalTitle: type === 'USER' ? 'Remover usuário' : 'Remover grupo',
      modalElement: data,
    })

    setDataToDelete({ id, type })
    handleModalConfirmationWarningToggle()
  }

  const handleEditUserClick = (user: UserOut) => {
    const sitesNames = user.site_ids.map(el => {
      const found = sites.find(a => a.site_id === el)
      return found?.site_name
    })
    setGroupsOptions({
      availableOptions: getAvailableOptions(groups, user.group_names, 'name'),
      chosenOptions: user.group_names,
    })
    setSitesOptions({
      availableOptions: getAvailableOptions(sites, sitesNames, 'site_name'),
      chosenOptions: sitesNames as string[],
    })
    setEditUser(user)
    handleCreateUserModalToggle()
  }

  const handleEditGroupClick = (group: GroupOut) => {
    getAvailableOptions(pages, group.page_names, 'name')
    setMembersOptions({
      availableOptions: getAvailableOptions(users, group.user_names, 'username'),
      chosenOptions: group.user_names,
    })
    setPagesOptions({
      availableOptions: getAvailableOptions(pages, group.page_names, 'name'),
      chosenOptions: group.page_names,
    })
    setEditGroup(group)
    setGroupDescriptionValue(group.description)
    setPermissionTypeSelected(group.perm_type_id)
    handleEditGroupModalToggle()
  }

  const onMembersListChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) => {
    setMembersOptions({
      availableOptions: newAvailableOptions.sort(),
      chosenOptions: newChosenOptions.sort(),
    })
  }

  const onPagesListChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) => {
    setPagesOptions({
      availableOptions: newAvailableOptions.sort(),
      chosenOptions: newChosenOptions.sort(),
    })
  }

  const onGroupsListChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) => {
    setGroupsOptions({
      availableOptions: newAvailableOptions.sort(),
      chosenOptions: newChosenOptions.sort(),
    })
  }

  const onSitesListChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAvailableOptions: string[],
    newChosenOptions: string[]
  ) => {
    setSitesOptions({
      availableOptions: newAvailableOptions.sort(),
      chosenOptions: newChosenOptions.sort(),
    })
  }

  async function handleEditUserSubmit() {
    const data: UserOut | UserIn = {
      id: editUser ? editUser.id : '',
      disabled: editUser ? editUser.disabled : false,
      username: userNameValue.length === 0 && editUser ? editUser.username : userNameValue,
      email: userEmailValue.length === 0 && editUser ? editUser.email : userEmailValue,
      group_ids: getIdList(groups, groupsOptions.chosenOptions, 'name', 'id'),
      site_ids: getIdList(
        sites,
        sitesOptions.chosenOptions,
        'site_name',
        'site_id'
      ) as unknown as number[],
      password: passwordValue.length > 0 ? passwordValue : '',
    }

    try {
      updateUser(data)
      handleCreateUserModalToggle()
      setUserNameValue('')
      setPasswordValue('')
      setUserEmailValue('')
      setGroupsOptions({ ...defaultOptions })
      setSitesOptions({ ...defaultOptions })
    } catch (error) {
      console.log(error)
    }
  }

  async function handleEditGroupSubmit() {
    const data: GroupOut | GroupIn = {
      id: editGroup ? editGroup.id : '',
      name: groupNameValue.length === 0 && editGroup ? editGroup.name : groupNameValue,
      description:
        groupDescriptionValue.length === 0 && editGroup
          ? editGroup.description
          : groupDescriptionValue,
      user_ids: getIdList(users, membersOptions.chosenOptions, 'username', 'id'),
      page_ids: getIdList(pages, pagesOptions.chosenOptions, 'name', 'page_id'),
      perm_type_id: permissionTypeSelected as string,
    }

    try {
      updateGroup(data)
      handleEditGroupModalToggle()
      setGroupNameValue('')
      setGroupDescriptionValue('')
      setPermissionTypeSelected(isLoading ? '' : permissionTypes[0].perm_type_id)
      setMembersOptions({ ...defaultOptions })
      setPagesOptions({ ...defaultOptions })
    } catch (error) {
      console.log(error)
    }
  }

  async function handleDeleteSubmit() {
    if (dataToDelete && dataToDelete.type === 'USER') {
      deleteUser(dataToDelete.id)
      return
    }

    if (dataToDelete && dataToDelete.type === 'GROUP') {
      deleteGroup(dataToDelete.id)
      return
    }
  }

  const rowMaker = (u: UserOut, idx: number) => (
    <Tr key={idx}>
      <Td key={`${idx}_Nome`} dataLabel='Nome'>
        {u.username}
      </Td>
      <Td key={`${idx}_Grupos`} dataLabel='Grupos'>
        {u.group_names?.map((g, i) => (
          <div key={`kg-${i}`}>{g}</div>
        ))}
      </Td>
      <Td key={`${idx}_Email`} dataLabel='Email'>
        {u.email}
      </Td>
      <Td key={`${idx}_Habilitado`} dataLabel='Habilitado'>
        {u.disabled ? <CloseIcon /> : <CheckIcon />}
      </Td>
      <Td key={`${idx}_Ações`} dataLabel='Ações'>
        <div className='column-icons'>
          <span className='container-icon'>
            <EditIcon onClick={() => handleEditUserClick(u)} />
          </span>
          <span className='container-icon'>
            <Remove2Icon onClick={() => handleDeleteClick(u)} />
          </span>
        </div>
      </Td>
    </Tr>
  )

  const handleChangeFilterUsers = (filterValue: string) => {
    if (filterValue === '') return setUsersFiltered(users)
    const filteredUsers = users.filter(user =>
      user.username.toLowerCase().includes(filterValue.toLowerCase())
    )
    setUsersFiltered(filteredUsers)
  }

  const handleChangeFilterGroups = (filterValue: string) => {
    if (filterValue === '') return setGroupsFiltered(groups)
    const filteredGroups = groups.filter(group =>
      group.name.toLowerCase().includes(filterValue.toLowerCase())
    )
    setGroupsFiltered(filteredGroups)
  }

  return (
    <PageFrame
      pageName='Usuários e Grupos'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      filters={[
        <FilterUser
          key='FilterUser'
          filterUserValue={filterUserValue}
          setFilterUserValue={setFilterUserValue}
          handleChangeFilterUsers={handleChangeFilterUsers}
          activeTabKey={activeTabKey}
        />,
        <FilterGroup
          key='FilterGroup'
          filterGroupValue={filterGroupValue}
          setFilterGroupValue={setFilterGroupValue}
          onChangeFilterGroup={handleChangeFilterGroups}
          activeTabKey={activeTabKey}
        />,
        <BarUsersGroups
          key='BarUsersGroups'
          setPermissionTypeSelected={setPermissionTypeSelected}
          permissionTypeSelected={permissionTypeSelected}
          createUser={createUser}
          createGroup={createGroup}
        />,
      ]}
    >
      <Tabs
        activeKey={activeTabKey}
        onSelect={(_, tabIndex: number | string) => {
          setActiveTabKey(Number(tabIndex))
        }}
        isBox
        aria-label='Users and groups'
      >
        <Tab eventKey={0} title={<TabTitleText>Usuários</TabTitleText>}>
          <div style={{ overflowY: 'visible', height: '71vh' }}>
            <OuterScrollContainer>
              <InnerScrollContainer>
                <Table variant='compact' aria-label='Simple table' isStickyHeader>
                  <Thead>
                    <Tr>
                      <Th key='Nome'>Nome</Th>
                      <Th key='Grupos'>Grupos</Th>
                      <Th key='Email'>Email</Th>
                      <Th key='Habilitado'>Habilitado</Th>
                      <Th key='Ações'>Ações</Th>
                    </Tr>
                  </Thead>

                  <Tbody>{usersFiltered?.map(rowMaker)}</Tbody>
                </Table>
              </InnerScrollContainer>
            </OuterScrollContainer>
          </div>
        </Tab>
        <Tab eventKey={1} title={<TabTitleText>Grupos</TabTitleText>}>
          <div style={{ overflowY: 'visible', height: '71vh' }}>
            <OuterScrollContainer>
              <InnerScrollContainer>
                <Table variant='compact' aria-label='Simple table' isStickyHeader>
                  <Thead>
                    <Tr>
                      <Th modifier='truncate'>Nome</Th>
                      <Th modifier='truncate'>Descrição</Th>
                      <Th modifier='truncate'>Membros</Th>
                      <Th modifier='truncate'>Ações</Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {groupsFiltered?.map((g, idx) => (
                      <Tr key={idx}>
                        <Td modifier='nowrap' dataLabel='Nome'>
                          {g.name}
                        </Td>
                        <Td modifier='nowrap' dataLabel='Descrição'>
                          {g.description}
                        </Td>
                        <Td modifier='nowrap' dataLabel='Grupos'>
                          {g.user_names?.map((u, i) => (
                            <div key={`k-${i}`}>{u}</div>
                          ))}
                        </Td>
                        <Td modifier='nowrap' dataLabel='Ações'>
                          <div className='column-icons'>
                            <span className='container-icon'>
                              <EditIcon onClick={() => handleEditGroupClick(g)} />
                            </span>
                            <span className='container-icon'>
                              <Remove2Icon onClick={() => handleDeleteClick(g)} />
                            </span>
                          </div>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </InnerScrollContainer>
            </OuterScrollContainer>
          </div>
        </Tab>
      </Tabs>

      <ModalConfirmationWarning
        isModalOpen={isModalConfirmationWarningOpen}
        handleModalToggle={handleModalConfirmationWarningToggle}
        handleSubmit={handleDeleteSubmit}
        title={modalInfo?.modalTitle}
        element={modalInfo?.modalElement}
      />

      <ModalEditUser
        isModalOpen={isUserModalOpen}
        handleModalToggle={handleCreateUserModalToggle}
        user={editUser}
        handleNameInputChange={setUserNameValue}
        handlePasswordInputChange={setPasswordValue}
        handleEmailInputChange={setUserEmailValue}
        availableGroupsOptions={groupsOptions.availableOptions.sort()}
        chosenGroupsOptions={groupsOptions.chosenOptions.sort()}
        availableSitesOptions={sitesOptions.availableOptions.sort()}
        chosenSitesOptions={sitesOptions.chosenOptions.sort()}
        onGroupsListChange={onGroupsListChange}
        onSitesListChange={onSitesListChange}
        handleSubmit={handleEditUserSubmit}
      />
      <ModalCreateGroup
        modalTitle='Editar grupo'
        isModalOpen={isGroupModalOpen}
        handleModalToggle={handleEditGroupModalToggle}
        groupNameValue={groupNameValue || (editGroup?.name as string)}
        groupDescriptionValue={groupDescriptionValue}
        handleGroupDescriptionInputChange={setGroupDescriptionValue}
        availableMembersOptions={membersOptions.availableOptions.sort()}
        chosenMembersOptions={membersOptions.chosenOptions.sort()}
        onMembersListChange={onMembersListChange}
        availablePagesOptions={pagesOptions.availableOptions.sort()}
        chosenPagesOptions={pagesOptions.chosenOptions.sort()}
        onPagesListChange={onPagesListChange}
        handleSubmit={handleEditGroupSubmit}
        permissionTypeSelected={permissionTypeSelected}
        setPermissionTypeSelected={setPermissionTypeSelected}
        handleGroupNameInputChange={setGroupNameValue}
      />
    </PageFrame>
  )
}

export default UsuariosGrupos
