import { Bullseye, Spinner, Split, SplitItem, Stack, StackItem } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useMemo, useState } from 'react'
import { AvailabilityAPI, AvailabilityPowerCurveEvents, Site, Turbines } from '../../client'
import Arrow from '../../components/Arrow'
import Select from '../../components/selects/Select'
import SitesContext from '../../contexts/SitesContext'
import { queryClient } from '../../services/api'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import ColorScale from '../../utils/colorScale'
import { formatDateWithoutTime } from '../../utils/formatDate'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'
import { CACHE_KEY_PC_POINTS, CACHE_KEY_POWER_CURVE } from './constants'

const layout = {
  template: layoutTemplate,
  margin: { b: 40, t: 15, l: 50, r: 35 },
  yaxis: {
    title: 'Velocidade do Vento (m/s)',
    ...defaultAxis,
  },
  xaxis: {
    title: 'Potência (kW)',
    ...defaultAxis,
  },
}

const DEFAULT_QUERY_OPTIONS = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
}

const getPointsPcReclassification = async (
  eventPC: AvailabilityPowerCurveEvents,
  siteId: number,
  turbId: number
) =>
  AvailabilityAPI.getPointsPcReclassification({
    siteId: siteId,
    eventId: eventPC.id,
    turbId: turbId,
    tsIn: formatDateWithoutTime(new Date(eventPC.ts_in)),
    tsFin: formatDateWithoutTime(new Date(eventPC.ts_fin)),
  })

const prefetchPoints = async (
  eventPC: AvailabilityPowerCurveEvents,
  siteId: number,
  turbId: number
) => {
  await queryClient.prefetchQuery(
    [CACHE_KEY_PC_POINTS, eventPC.id, siteId, turbId],
    async () => getPointsPcReclassification(eventPC, siteId, turbId),
    { ...DEFAULT_QUERY_OPTIONS }
  )
}

const findNext = (turb: Turbines, eventTurbs: Turbines[]) => {
  const currentTurbIndex = eventTurbs.findIndex(t => t.turb_id === turb.turb_id)
  if (currentTurbIndex + 1 === eventTurbs.length) return eventTurbs.at(0)

  return eventTurbs.at(currentTurbIndex + 1)
}

const findPrevious = (turb: Turbines, eventTurbs: Turbines[]) => {
  const currentTurbIndex = eventTurbs.findIndex(t => t.turb_id === turb.turb_id)
  if (currentTurbIndex === 0) return eventTurbs.at(-1)

  return eventTurbs.at(currentTurbIndex - 1)
}

const PlotPowerCurveReclassification = ({ eventPC }: { eventPC: AvailabilityPowerCurveEvents }) => {
  const { site, turbs, turbsMap, isLoading } = useContext(SitesContext)
  const [turb, setTurb] = useState<Turbines>(
    turbsMap.get(eventPC.turbs[0]) ?? ({ turb_id: 0 } as Turbines)
  )
  const [eventTurbs, setEventTurbs] = useState<Turbines[]>(
    turbs.filter(t => eventPC.turbs.includes(t.turb_id))
  )
  const { data: dataPowerCurve, isLoading: powerCurveIsLoading } = useQuery({
    queryKey: [CACHE_KEY_POWER_CURVE, site?.site_id],
    queryFn: async () => AvailabilityAPI.getPowerCurve({ siteId: site?.site_id ?? 99, year: 2021 }),
    enabled: !!eventPC.id,
    ...DEFAULT_QUERY_OPTIONS,
  })

  const { data, isLoading: dataIsLoading } = useQuery({
    queryKey: [CACHE_KEY_PC_POINTS, eventPC.id, site?.site_id, turb.turb_id],
    queryFn: async () => getPointsPcReclassification(eventPC, site.site_id, turb.turb_id),
    enabled: !!eventPC.id && turb.turb_id !== 0,
    ...DEFAULT_QUERY_OPTIONS,
  })

  useEffect(() => {
    if (!eventPC || !turbs || isLoading) return

    const defaultEventTurbs = turbs.filter(t => eventPC.turbs.includes(t.turb_id))
    setEventTurbs(defaultEventTurbs)

    const defaultTurb = turbsMap.get(eventPC.turbs[0])
    if (!defaultTurb) return
    setTurb(defaultTurb)
  }, [eventPC, turbs, isLoading])

  useEffect(() => {
    if (!eventPC || !turb || isLoading) return

    const nextTurb = findNext(turb, eventTurbs)
    if (nextTurb) prefetchPoints(eventPC, site.site_id, nextTurb.turb_id)
  }, [eventPC, eventTurbs, turb, isLoading])

  const traces = useMemo(() => {
    if (!data || !dataPowerCurve) return {}

    return [
      {
        x: dataPowerCurve.filter(av => av.turb_id === turb.turb_id).map(av => av.nws),
        y: dataPowerCurve.filter(av => av.turb_id === turb.turb_id).map(av => av.pwr),
        type: 'scatter',
        mode: 'markers',
        name: 'Curva de potência',
        marker: { color: ColorScale.verde },
      },
      {
        x: data.map(av => av.nws),
        y: data.map(av => av.pwr),
        type: 'scatter',
        mode: 'markers',
        name: 'Pontos marcados',
        marker: { color: ColorScale.azul_escuro },
      },
    ]
  }, [data, dataIsLoading, turb, dataPowerCurve, powerCurveIsLoading])
  const plotStyle = {
    width: '100%',
    height: calcVerticalHeight({ gridSize: 1, elementSize: 1, customOffset: '10rem' }),
    minHeight: '13rem',
  }

  const handleArrow = (side: string) => {
    if (!eventTurbs) return

    const nextTurb = side === 'right' ? findNext(turb, eventTurbs) : findPrevious(turb, eventTurbs)
    if (nextTurb) setTurb(nextTurb)
  }

  return (
    <Stack hasGutter>
      <StackItem isFilled>
        <span>Aerogerador</span>
        <Split>
          <SplitItem>
            <Arrow direction='left' variant='control' onClick={() => handleArrow('left')} />
          </SplitItem>
          <SplitItem isFilled>
            <Select<Turbines>
              items={eventTurbs}
              itemValueName={'name'}
              itemKeyName={'turb_id'}
              onChange={setTurb}
              selected={turb ?? ({} as Turbines)}
              enableFilter
              maxHeight={250}
            />
          </SplitItem>
          <SplitItem>
            <Arrow direction='right' variant='control' onClick={() => handleArrow('right')} />
          </SplitItem>
        </Split>
      </StackItem>
      <StackItem>
        <div className='pf-v5-u-p-sm'>
          {dataIsLoading ? (
            <Bullseye style={plotStyle}>
              <div>
                <Spinner />
              </div>
            </Bullseye>
          ) : (
            <Plot
              id='PLOT.SCATTER.PC.RECLASSIFICATION'
              data={traces}
              layout={layout}
              config={defaultConfig}
              useResizeHandler
              revision={turb.turb_id ?? 99}
              style={plotStyle}
            />
          )}
        </div>
      </StackItem>
    </Stack>
  )
}
export default PlotPowerCurveReclassification
