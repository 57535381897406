import {
  Bullseye,
  Button,
  Divider,
  EmptyState,
  EmptyStateFooter,
  EmptyStateHeader,
  EmptyStateIcon,
  EmptyStateVariant,
  Flex,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
} from '@patternfly/react-core'
import { CogIcon, EditIcon, Remove2Icon, SearchIcon, TimesIcon } from '@patternfly/react-icons'
import {
  InnerScrollContainer,
  OuterScrollContainer,
  Table,
  TableText,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { useContext, useEffect, useMemo, useState } from 'react'
import ModalConfirmationWarning from '../../components/ModalConfirmationWarning'

import { DowntimeAPI, DowntimeONS } from '../../client'
import { ActionState } from '../../components/actionState'
import SitesContext from '../../contexts/SitesContext'
import useMobile from '../../hooks/useMobile'
import { queryClient } from '../../services/api'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import ColorScale from '../../utils/colorScale'
import { formatDateTimeBrl } from '../../utils/formatDate'
import { sortByKeyPosition } from '../../utils/sort'
import {
  BOTTOM_BUTTON_HEIGHT,
  BOTTOM_BUTTON_MARGIN,
  CACHE_KEY_ONS,
  GRID_SIZE,
  PAGE_GRID_SIZE,
  TABLE_HEADER_HEIGHT,
  TABLE_MIN_HEIGHT,
  TAB_HEADER_HEIGHT,
  TOOLBAR_HEIGHT,
} from './constants'
import ModalRestricao from './ModalRestricao'
import ModalUploadONSSheet from './ModalUploadONSSheet'
import ResizeButtons from './ResizeButtons'
import { keyFromDate } from './useData'

const TABLE_HEIGHT_STYLE = {
  height: calcVerticalHeight({
    pgHasFilter: true,
    gridSize: PAGE_GRID_SIZE,
    elementSize: 1,
    customOffset: TAB_HEADER_HEIGHT + BOTTOM_BUTTON_HEIGHT + BOTTOM_BUTTON_MARGIN + 2.45,
  }),
  minHeight: TABLE_MIN_HEIGHT,
}

function getDuration(init: string, fin: string) {
  const difMin = (Date.parse(fin) - Date.parse(init)) / (60 * 1000)

  if (Number.isInteger(difMin)) return `${difMin}`
  return `${difMin.toFixed(2)}`
}

const TableRestricoes = ({
  selectedDate,
  downtimeOns,
  resizeMode,
  setResizeMode,
}: {
  selectedDate: Date
  downtimeOns: DowntimeONS[]
  resizeMode: number
  setResizeMode: (m: number) => void
}) => {
  const { site } = useContext(SitesContext)

  const [isModalConfirmationWarningOpen, setModalConfirmationWarningOpen] = useState(false)
  const [
    isModalConfirmationWarningSelectedDatasOpen,
    setModalConfirmationWarningSelectedDatasOpen,
  ] = useState(false)
  const [dataToDelete, setDataToDelete] = useState<number[]>([])
  const [dataToUpdate, setDataToUpdate] = useState<DowntimeONS | null>(null)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [selectedData, setSelectedData] = useState<DowntimeONS[]>([])
  const [recentSelectedRowIndex, setRecentSelectedRowIndex] = useState<number | null>(null)
  const [shifting, setShifting] = useState(false)

  const [activeSortIndex, setActiveSortIndex] = useState(0)
  const [activeSortDirection, setActiveSortDirection] = useState<'asc' | 'desc'>('asc')
  const [actionState, setActionState] = useState<ActionState>('idle')
  const [isModalUpdateOpen, setModalUploadOpen] = useState(false)

  const deleteDowntimeOns = async (restricaoId: number[]) => {
    setActionState('pending')
    try {
      await Promise.all(
        restricaoId.map(async data => {
          await DowntimeAPI.deleteDowntimeOns({ siteId: site.site_id, restricaoId: data })
        })
      )
    } catch (error) {
      console.log(error)
      setActionState('failure')
      return
    }
    setActionState('success')
    setSelectedData([])
    onCloseModal()
    await queryClient.invalidateQueries([CACHE_KEY_ONS, site.site_id, keyFromDate(selectedDate, 1)])
  }

  const onCloseModal = () => {
    setDataToUpdate(null)
    setIsModalOpen(false)
  }

  const handleModalUpdateOpen = (item: DowntimeONS) => {
    if (!isModalOpen && !!downtimeOns) {
      const found = downtimeOns.find(el => el.id === item.id)
      if (found) setDataToUpdate(found)
    }

    setIsModalOpen(!isModalOpen)
  }

  const handleClickCreateModal = () => {
    setIsModalOpen(true)
    setDataToUpdate(null)
  }

  const handleModalConfirmationWarningToggle = () => {
    setActionState('idle')
    setModalConfirmationWarningOpen(!isModalConfirmationWarningOpen)
  }

  const handleDeleteClick = (item: number) => {
    setDataToDelete([item])
    handleModalConfirmationWarningToggle()
  }

  const handleDeleteManyClick = (item: number[]) => {
    setDataToDelete(item)
    handleModalConfirmationWarningSelectedDatasToggle()
  }

  const handleModalConfirmationWarningSelectedDatasToggle = () => {
    setActionState('idle')
    setModalConfirmationWarningSelectedDatasOpen(!isModalConfirmationWarningSelectedDatasOpen)
  }

  const onsDowntimeData = useMemo(() => {
    if (!downtimeOns) return []
    setSelectedData([])

    const _data = downtimeOns.map(el => ({
      id: el.id,
      ts_in: el.ts_in,
      ts_fin: el.ts_fin,
      motivo: el.motivo,
      username: el.username,
      tipo_restricao: el.tipo_restricao,
      potencia_solicitada: el.potencia_solicitada,
      operador_ons: el.operador_ons,
      duracao: Number(getDuration(el.ts_in, el.ts_fin)),
      site_id: el.site_id,
    }))

    return sortByKeyPosition(_data, activeSortIndex, activeSortDirection)
  }, [downtimeOns, activeSortIndex, activeSortDirection])

  const getSortParams = (columnIndex: number) => ({
    sortBy: {
      index: activeSortIndex,
      direction: activeSortDirection,
    },
    onSort: (_event: any, index: number, direction: 'asc' | 'desc') => {
      setActiveSortIndex(index)
      setActiveSortDirection(direction)
    },
    columnIndex,
  })

  const selectableRepos = onsDowntimeData.filter(id => id)
  const setRepoSelected = (repo: DowntimeONS, isSelecting = true) =>
    setSelectedData(prevSelected => {
      const otherSelectedData = prevSelected.filter(r => r !== repo)
      return isSelecting ? [...otherSelectedData, repo] : otherSelectedData
    })
  const selectAllRepos = (isSelecting = true) =>
    setSelectedData(isSelecting ? selectableRepos.map(r => r) : [])
  const areAllReposSelected = selectedData.length === onsDowntimeData.length
  const isRepoSelected = (repo: DowntimeONS) => selectedData.includes(repo)

  const onSelectRepo = (repo: DowntimeONS, rowIndex: number, isSelecting: boolean) => {
    // If the user is shift + selecting the checkboxes, then all intermediate checkboxes should be selected
    if (shifting && recentSelectedRowIndex !== null) {
      const numberSelected = rowIndex - recentSelectedRowIndex
      const intermediateIndexes =
        numberSelected > 0
          ? Array.from(new Array(numberSelected + 1), (_x, i) => i + recentSelectedRowIndex)
          : Array.from(new Array(Math.abs(numberSelected) + 1), (_x, i) => i + rowIndex)
      intermediateIndexes.forEach(index => setRepoSelected(onsDowntimeData[index], isSelecting))
    } else {
      setRepoSelected(repo, isSelecting)
    }
    setRecentSelectedRowIndex(rowIndex)
  }

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setShifting(true)
      }
    }
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setShifting(false)
      }
    }

    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('keyup', onKeyUp)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [])

  const isMobile = useMobile()
  const [toggleFilters, setToggleFilters] = useState<boolean>(false)

  const buttonGroupItems = (
    <>
      <ToolbarItem style={{ padding: `4px ${isMobile ? 0 : '0.35rem'}`, margin: 0 }}>
        <Button
          onClick={handleClickCreateModal}
          style={{
            height: '36px',
            margin: 0,
            ...(isMobile && { width: '88vw' }),
          }}
        >
          Adicionar
        </Button>
      </ToolbarItem>
      <ToolbarItem style={{ padding: `4px ${isMobile ? 0 : '0.35rem'}`, margin: 0 }}>
        <Button
          variant='secondary'
          onClick={() => setModalUploadOpen(true)}
          style={{
            height: '36px',
            margin: 0,
            ...(isMobile && { width: '88vw' }),
          }}
        >
          Enviar
        </Button>
      </ToolbarItem>
      <ToolbarItem style={{ padding: `4px ${isMobile ? 0 : '0.35rem'}`, margin: 0 }}>
        <Button
          variant='danger'
          onClick={() => handleDeleteManyClick(selectedData.map(i => i.id as number))}
          isDisabled={!selectedData[0]}
          style={{
            height: '36px',
            margin: 0,
            ...(isMobile && { width: '88vw' }),
          }}
        >
          Remover
        </Button>
      </ToolbarItem>
      <Divider
        orientation={{ default: isMobile ? 'horizontal' : 'vertical' }}
        style={{
          ...(isMobile ? { width: '89vw' } : { height: '3vh', alignSelf: 'center' }),
          margin: 0,
        }}
      />
      <ResizeButtons setResizeMode={setResizeMode} resizeMode={resizeMode} />
    </>
  )

  const items = (
    <>
      <ToolbarGroup
        variant='button-group'
        style={{ ...(isMobile && { display: 'flex', flexDirection: 'column' }) }}
      >
        {buttonGroupItems}
      </ToolbarGroup>
    </>
  )

  const tableAndContainerHeight = useMemo(() => {
    const h = calcVerticalHeight({
      elementSize: GRID_SIZE / 2 - resizeMode,
      gridSize: GRID_SIZE,
    })
    // Offset tem que ser fora, calcVerticalHeight distribui igualmente o offset entre os elementos
    const customOffset = TAB_HEADER_HEIGHT + TOOLBAR_HEIGHT
    const s = `calc(${h} - ${customOffset}rem)`

    return s
  }, [resizeMode])

  const heightBottom = useMemo(() => {
    const h = calcVerticalHeight({ elementSize: GRID_SIZE / 2 - resizeMode, gridSize: GRID_SIZE })
    // Offset tem que ser fora, calcVerticalHeight distribui igualmente o offset entre os elementos
    const customOffset = TABLE_HEADER_HEIGHT
    const s = `calc(${h} - ${customOffset}rem)`

    return s
  }, [resizeMode])

  return (
    <div style={{ height: heightBottom, backgroundColor: 'orange' }}>
      {isMobile && (
        <Flex
          style={{ justifyContent: 'flex-start', backgroundColor: 'white', position: 'relative' }}
        >
          <Button
            style={{
              backgroundColor: 'transparent',
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
              justifyContent: 'center',
              height: '35px',
              color: 'black',
            }}
            onClick={() => setToggleFilters(!toggleFilters)}
          >
            {!toggleFilters ? (
              <>
                Ações
                <EmptyStateIcon icon={CogIcon} />
              </>
            ) : (
              <EmptyStateIcon icon={TimesIcon} />
            )}
          </Button>
        </Flex>
      )}

      {!isMobile || toggleFilters ? (
        <Flex
          style={{
            backgroundColor: 'white',
            position: 'relative',
          }}
        >
          <Toolbar id='toolbar-group-types' style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ToolbarContent>{items}</ToolbarContent>
          </Toolbar>
        </Flex>
      ) : null}

      <InnerScrollContainer style={{ height: tableAndContainerHeight, backgroundColor: 'pink' }}>
        <Table
          aria-label='Table Restricoes ONS'
          variant='compact'
          isStriped
          isExpandable
          isStickyHeader
          style={{ height: tableAndContainerHeight, backgroundColor: ColorScale.branco_clear }}
        >
          <Thead>
            <Tr style={{ height: `${TABLE_HEADER_HEIGHT}rem` }}>
              {onsDowntimeData.length > 0 && (
                <Th
                  select={{
                    onSelect: (_event, isSelecting) => selectAllRepos(isSelecting),
                    isSelected: areAllReposSelected,
                  }}
                />
              )}
              <Th sort={getSortParams(0)} style={{ width: '2rem' }}>
                Id
              </Th>
              <Th sort={getSortParams(1)}>Início</Th>
              <Th sort={getSortParams(2)}>Fim</Th>
              <Th className='pf-m-wrap' style={{ maxWidth: '4rem' }}>
                Tipo de Restrição
              </Th>
              <Th className='pf-m-wrap'>Operador ONS</Th>
              <Th className='pf-m-wrap' style={{ maxWidth: '7rem' }}>
                <span>Potência Demandada</span>{' '}
                <span style={{ whiteSpace: 'nowrap' }}>[MW ou MVAr]</span>
              </Th>
              <Th style={{ minWidth: '7.5rem' }}>Motivo</Th>
              <Th sort={getSortParams(3)} className='pf-m-wrap' style={{ maxWidth: '5rem' }}>
                Duração [min]
              </Th>
              <Th>Ações</Th>
            </Tr>
          </Thead>

          {onsDowntimeData.length === 0 && (
            <Tbody>
              <Tr style={{ height: '100%' }}>
                <Td colSpan={12}>
                  <Bullseye>
                    <EmptyState variant={EmptyStateVariant.lg}>
                      <EmptyStateHeader
                        titleText='Nenhum registro encontrado.'
                        icon={<EmptyStateIcon icon={SearchIcon} />}
                        headingLevel='h3'
                      />
                      <EmptyStateFooter />
                    </EmptyState>
                  </Bullseye>
                </Td>
              </Tr>
            </Tbody>
          )}

          {onsDowntimeData.length > 0 &&
            onsDowntimeData.map((el, rowIndex) => {
              return (
                <Tbody key={el.id}>
                  <Tr>
                    <Td
                      select={{
                        rowIndex,
                        onSelect: (_event, isSelecting) => onSelectRepo(el, rowIndex, isSelecting),
                        isSelected: isRepoSelected(el),
                      }}
                    />
                    <Td dataLabel='Id'>{el.id}</Td>
                    <Td dataLabel='Início'>{formatDateTimeBrl(new Date(el.ts_in))}</Td>
                    <Td dataLabel='Fim'>{formatDateTimeBrl(new Date(el.ts_fin))}</Td>
                    <Td dataLabel='Tipo Restrição'>{el.tipo_restricao}</Td>
                    <Td dataLabel='Operador ONS'>{el.operador_ons}</Td>
                    <Td dataLabel='Potência Solicitada'>{el.potencia_solicitada}</Td>
                    <Td dataLabel='Motivo'>
                      <TableText
                        tooltipProps={{ className: 'pf-v5-u-display-none' }}
                        wrapModifier='truncate'
                      >
                        {el.motivo}
                      </TableText>
                    </Td>
                    <Td dataLabel='Duração [min]'>{el.duracao}</Td>
                    <Td dataLabel='Ações'>
                      <div>
                        <EditIcon
                          onClick={() => {
                            handleModalUpdateOpen(el)
                          }}
                          style={{ cursor: 'pointer' }}
                        />{' '}
                        <Remove2Icon
                          onClick={() => {
                            handleDeleteClick(el?.id ?? 0)
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </Td>
                  </Tr>
                </Tbody>
              )
            })}
        </Table>
      </InnerScrollContainer>

      <ModalRestricao
        isOpen={isModalOpen}
        onClose={onCloseModal}
        dataToUpdate={dataToUpdate}
        selectedDate={selectedDate}
        handleDeleteClick={handleDeleteClick}
        modalConfirmation={
          <ModalConfirmationWarning<number>
            title='Remover restrição ONS'
            isOpen={isModalConfirmationWarningOpen}
            handleModalToggle={handleModalConfirmationWarningToggle}
            handleSubmit={id => deleteDowntimeOns([id])}
            element={dataToDelete[0]}
            actionState={actionState}
          />
        }
      />
      <ModalConfirmationWarning<number>
        title='Remover restrição ONS'
        isOpen={isModalConfirmationWarningOpen}
        handleModalToggle={handleModalConfirmationWarningToggle}
        handleSubmit={id => deleteDowntimeOns([id])}
        element={dataToDelete[0]}
        actionState={actionState}
      />
      <ModalUploadONSSheet isOpen={isModalUpdateOpen} setOpen={setModalUploadOpen} site={site} />
      <ModalConfirmationWarning<DowntimeONS[]>
        isOpen={isModalConfirmationWarningSelectedDatasOpen}
        handleModalToggle={handleModalConfirmationWarningSelectedDatasToggle}
        handleSubmit={data => deleteDowntimeOns(data.map(i => i.id as number))}
        title='Remover restrições ONS'
        element={selectedData}
        actionState={actionState}
        alertMessage='Tem certeza que deseja remover estes itens? Essa ação não poderá ser desfeita.'
        additionalInformation={
          selectedData.length > 0 && (
            <OuterScrollContainer
              style={{ ...TABLE_HEIGHT_STYLE, borderBottom: '1px solid #d2d2d2' }}
            >
              <InnerScrollContainer>
                <Table
                  aria-label='Table Restricoes ONS'
                  variant='compact'
                  isStriped
                  isExpandable
                  isStickyHeader
                >
                  <Thead>
                    <Tr style={{ height: `${TABLE_HEADER_HEIGHT}rem` }}>
                      <Th style={{ width: '2rem' }}>Id</Th>
                      <Th>Início</Th>
                      <Th>Fim</Th>
                      <Th className='pf-m-wrap' style={{ maxWidth: '4rem' }}>
                        Tipo de Restrição
                      </Th>
                      <Th className='pf-m-wrap'>Operador ONS</Th>
                    </Tr>
                  </Thead>

                  {selectedData.map(el => {
                    return (
                      <Tbody key={el.id}>
                        <Tr>
                          <Td dataLabel='Id'>{el.id}</Td>
                          <Td dataLabel='Início'>{formatDateTimeBrl(new Date(el.ts_in))}</Td>
                          <Td dataLabel='Fim'>{formatDateTimeBrl(new Date(el.ts_fin))}</Td>
                          <Td dataLabel='Tipo Restrição'>{el.tipo_restricao}</Td>
                          <Td dataLabel='Operador ONS'>{el.operador_ons}</Td>
                        </Tr>
                      </Tbody>
                    )
                  })}
                </Table>
              </InnerScrollContainer>
            </OuterScrollContainer>
          )
        }
      />
    </div>
  )
}

export default TableRestricoes
