import {
  Alert,
  Button,
  FileUpload,
  Hint,
  HintBody,
  Modal,
  ModalVariant,
  Stack,
  StackItem,
} from '@patternfly/react-core'
import FormData from 'form-data'
import React, { useState } from 'react'
import { DowntimeAPI, Site } from '../../client'
import { ActionState } from '../../components/actionState'
import { queryClient } from '../../services/api'
import { CACHE_KEY_ONS } from './constants'

const REQUEST_STATE_MESSAGES = {
  idle: <></>,
  pending: <Alert variant='info' title='Processando' isInline />,
  success: <Alert variant='success' title='Salvo com sucesso' isInline />,
  failure: <Alert variant='danger' title='Ocorreu um erro ao processar a planilha' isInline />,
}
const SAVE_BUTTON_LABEL = {
  idle: 'Salvar',
  pending: 'Salvando',
  failure: 'Não Salvo',
  success: 'Salvo',
}

const ModalUploadONSSheet = ({
  isOpen,
  setOpen,
  site,
}: {
  isOpen: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  site: Site
}) => {
  const [planilhaFile, setPlanilhaFile] = useState<File | null>(null)
  const [statusResponse, setStatusResponse] = useState<string>('')
  const [actionState, setActionState] = useState<ActionState>('idle')

  const handleFileRejected = (_rejectedFiles: any, _event: any) => {
    setPlanilhaFile(null)
  }
  const handleFileReadStarted = (_fileHandle: File): void => {
    setActionState('pending')
  }
  const handleFileReadFinished = (_fileHandle: File): void => {
    setActionState('success')
  }
  const handleFileInputChange = (_event: any, file: File) => {
    setPlanilhaFile(file)
  }
  const handleClear = (_event: any) => {
    setPlanilhaFile(null)
    setStatusResponse('')
    setActionState('idle')
  }
  const handleCloseModal = () => {
    setOpen(false)
    setPlanilhaFile(null)
    setStatusResponse('')
    setActionState('idle')
  }
  const handleSubmit = async () => {
    setStatusResponse('')
    if (!planilhaFile) {
      return
    }

    const formData = new FormData()
    formData.append('xlsx', planilhaFile)

    try {
      const response = await DowntimeAPI.workbookUpload({
        siteId: site.site_id,
        formData: { xlsx: planilhaFile },
      })
      console.log(response)
      handleCloseModal()
      await queryClient.invalidateQueries([CACHE_KEY_ONS, site.site_id])
    } catch (error: any) {
      setStatusResponse(error?.body?.detail ?? 'Unable to find error message')
      setActionState('failure')
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      variant={ModalVariant.medium}
      title={`${site.site_name.toUpperCase()} Cadastrar Restrições`}
      actions={[
        <Button
          id='salvar-button'
          key='create'
          variant='primary'
          onClick={handleSubmit}
          isDisabled={actionState !== 'idle'}
        >
          {SAVE_BUTTON_LABEL[actionState]}
        </Button>,
        <Button key='cancel' variant='secondary' isDanger onClick={handleCloseModal}>
          Cancelar
        </Button>,
      ]}
    >
      <Stack hasGutter>
        {actionState === 'failure' ? (
          <>
            <StackItem>{REQUEST_STATE_MESSAGES[actionState]}</StackItem>
            <StackItem>
              <Hint>
                <HintBody>{statusResponse}</HintBody>
              </Hint>
            </StackItem>
          </>
        ) : (
          <StackItem>
            <Hint>
              <HintBody>
                Importação das restrições da ONS de {site.site_name.toLocaleUpperCase()}
              </HintBody>
            </Hint>
          </StackItem>
        )}
        <StackItem>
          <FileUpload
            id='paradas-ons-file-upload'
            filename={planilhaFile?.name}
            filenamePlaceholder='Arraste e solte ou carregue um a planilha aqui'
            onFileInputChange={handleFileInputChange}
            onReadFinished={(_event, _fileHandle: File) => handleFileReadFinished(_fileHandle)}
            onReadStarted={(_event, _fileHandle: File) => handleFileReadStarted(_fileHandle)}
            onClearClick={handleClear}
            hideDefaultPreview
            browseButtonText='Upload'
            isLoading={actionState === 'pending'}
            dropzoneProps={{
              accept: {
                'application/excel': ['.xlsx', '.csv', '.xls'],
              },
              onDropRejected: handleFileRejected,
            }}
          />
        </StackItem>
      </Stack>
    </Modal>
  )
}

export default ModalUploadONSSheet
