import { useContext } from 'react'
import SelectList from '../DeprecatedSelectList'

import PermissionContext from '../../../../contexts/PermissionContext'

const SelectListPermissions = ({ permissionTypeSelected, setPermissionTypeSelected }) => {
  const { permissionTypes, isLoading } = useContext(PermissionContext)

  return (
    <SelectList
      isLoading={isLoading}
      startSelected={permissionTypeSelected}
      onChange={setPermissionTypeSelected}
      list={permissionTypes?.map(perm => {
        return {
          value: perm.perm_type_id,
          label: perm.description,
        }
      })}
    />
  )
}

export default SelectListPermissions
