import { Panel, PanelMain, PanelMainBody, Stack, StackItem } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import { AvailabilityAPI } from '../../client'
import DatetimeRangePicker from '../../components/calendar/DatetimeRangePicker'
import SitesContext from '../../contexts/SitesContext'
import useVertical from '../../hooks/useVertical'
import { queryClient } from '../../services/api'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import DownloadButton from '../shared/DownloadButton'
import PageFrame from '../shared/Page'
import SelectSite from '../shared/selects/SelectSite'
import PlotExpectedEnergy from './PlotExpectedEnergy'
import PlotExpectedEnergyWindFarms from './PlotExpectedEnergyWindFarms'

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
}
const heightStyle = { height: calcVerticalHeight({ gridSize: 2, elementSize: 1, customOffset: 5 }) }
const QUERY_KEY = 'expected_energy'

const formatDate = (date: Date) => date.toISOString().split('T')[0]

const get_expected_energy = ({
  siteId,
  startDate,
  endDate,
}: {
  siteId: number
  startDate: Date
  endDate: Date
}) => {
  return AvailabilityAPI.getEnergyExpected({
    siteId,
    tsIn: formatDate(startDate),
    tsFin: formatDate(endDate),
  })
}

const prefetch = ({
  siteId,
  startDate,
  endDate,
}: {
  siteId: number
  startDate: Date
  endDate: Date
}) => {
  queryClient.prefetchQuery(
    [QUERY_KEY, siteId, JSON.stringify({ startDate, endDate })],
    () => get_expected_energy({ siteId, startDate, endDate }),
    defaultQueryOptions
  )
}

const ExpectedEnergy = () => {
  const { site, sites, isLoading: siteIsLoading } = useContext(SitesContext)

  const isVertical = useVertical()
  const [isNavOpen, setNavOpen] = useState(!isVertical)
  const minDate = new Date(site.commissioning_date)
  const dtNow = new Date()
  const maxDate = new Date(dtNow.getFullYear(), dtNow.getMonth(), dtNow.getDate() - 1)
  const startDate = new Date(dtNow.getFullYear(), dtNow.getMonth(), dtNow.getDate() - 7)
  const [period, setPeriod] = useState({ startDate, endDate: maxDate })
  const [plotRevision, setPlotRevision] = useState(0)

  const queryOptions = { ...defaultQueryOptions, enabled: !!site?.site_id }

  const { isLoading: expectedIsLoading, data } = useQuery(
    [QUERY_KEY, site?.site_id, JSON.stringify(period)],
    async () => get_expected_energy({ siteId: site?.site_id, ...period }),
    queryOptions
  )

  const isLoading = siteIsLoading || expectedIsLoading

  const { site: expectedEnergySite, wind_farms: expectedEnergyWindFarm } = data ?? {}

  useEffect(() => {
    setPlotRevision(plotRevision + 1)
  }, [data, isNavOpen, isLoading])

  useEffect(() => {
    if (isLoading) return
    sites
      .filter(s => s.site_id !== site.site_id)
      .forEach(s => prefetch({ siteId: s.site_id, ...period }))
  }, [isLoading])

  return (
    <PageFrame
      pageName='Expected Energy'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      filters={[
        <SelectSite key='s1' enableUrlParams />,
        <DatetimeRangePicker
          key='dt_range'
          value={period}
          onChange={(startDate, endDate) => setPeriod({ startDate, endDate })}
          calendarVariant='date'
          maxEnabled={maxDate}
          minEnabled={minDate}
          allowEqualDates
          variant='simple'
        />,
        <DownloadButton
          key={`dl_btn_${site?.site_name}`}
          label='Exportar'
          url={`/sites/${site.site_id}/availability/energy-expected/report?${new URLSearchParams({
            date_in: formatDate(period.startDate),
            date_fin: formatDate(period.endDate),
          })}`}
          filename={`report_expected_energy_${site.site_name}_${formatDate(period.startDate)}.xlsx`}
        />,
      ]}
    >
      <Panel className='pf-v5-u-box-shadow-sm'>
        <PanelMain>
          <PanelMainBody>
            <Stack hasGutter>
              <StackItem>
                {!!expectedEnergySite && (
                  <PlotExpectedEnergy
                    expectedEnergySite={expectedEnergySite}
                    revision={plotRevision}
                    style={{ width: '100%', ...heightStyle }}
                  />
                )}
              </StackItem>
              <StackItem>
                {!!expectedEnergyWindFarm && (
                  <PlotExpectedEnergyWindFarms
                    expectedEnergyWindFarm={expectedEnergyWindFarm}
                    revision={plotRevision}
                    style={{ width: '100%', ...heightStyle }}
                  />
                )}
              </StackItem>
            </Stack>
          </PanelMainBody>
        </PanelMain>
      </Panel>
    </PageFrame>
  )
}

export default ExpectedEnergy
